import React from 'react'
import { styled } from '@mui/material/styles'
import LinearProgress from '@mui/material/LinearProgress'
import { Typography } from '@mui/material'

const StyledBorderLinearProgress = styled(LinearProgress)((
  {
    theme,
  }
) => ({
  height: 25,
  borderRadius: 25,
  '& .MuiLinearProgress-barColorPrimary': {
    backgroundColor: theme.palette.grey[theme.palette.mode === 'light' ? 200 : 700],

  },
}))

const MIN = 30
const MAX = 0
// Function to normalise the values (MIN / MAX could be integrated)
const normalise = (value) => (value - MIN) * 100 / (MAX - MIN)

export default function AlertDialog(props) {
  if (props.loading === true) {
    return (
      <div style={{
        position: 'relative', margin: '20px', paddingBottom: '10px', textAlign: 'center',
      }}
      >
        <div style={{ position: 'relative', margin: '20px' }}>
          <StyledBorderLinearProgress
            variant="determinate"
            value={100}
            style={{ backgroundColor: '#ffffff' }}
          />
        </div>
      </div>
    )
  }
  const now = new Date()
  const daysLeft = Math.round((props.rebuildDate.getTime() - now.getTime()) / (1000 * 3600 * 24))

  const colour = daysLeft < 5 ? '#c61400' : daysLeft < 10 ? '#ff9933' : '#187900'

  return (
    <div style={{
      position: 'relative', margin: '20px', paddingBottom: '10px', textAlign: 'center',
    }}
    >

      {daysLeft < 4
            && <i className="fas fa-exclamation-circle" style={{ color: '#c61400', margin: '5px', fontSize: '2em' }} />}
      <div style={{ position: 'relative', margin: '20px' }}>

        <Typography
          variant="caption"
          component="p"
          style={{
            position: 'absolute', zIndex: '9', left: '50%', top: '50%', transform: 'translateX(-50%) translateY(-50%)', backgroundColor: '#FFFFFF99', color: daysLeft < 4 ? '#c61400' : 'black', borderRadius: '25px', paddingLeft: '5px', paddingRight: '5px',
          }}
        >
          {daysLeft === 0
            ? 'VM will be rebuilt tonight!'
            : `${daysLeft} Day(s) until VM Rebuild`}
        </Typography>
        <StyledBorderLinearProgress
          variant="determinate"
          value={normalise(daysLeft)}
          sx={{ backgroundColor: colour }}
        />
      </div>
    </div>
  )
}
