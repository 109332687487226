import React from 'react'
import { styled } from '@mui/material/styles'
import { Alert } from 'reactstrap'
import flow from 'lodash.flow'
import {
  Grid,
  Button,
  IconButton,
  Typography,
  ButtonGroup,
  TextField,
  Paper,
} from '@mui/material'
import HelpIcon from '@mui/icons-material/Help'
import Autocomplete from '@mui/material/Autocomplete'
import ReplayIcon from '@mui/icons-material/Replay'
import asBaseScreen from '../../screenWrappers/BaseScreen'
import { withVm, withAllVmOptionsConfig } from '../../screenWrappers/DataProviders'
import { withSnackbarsFeature, withConfirmFeature } from '../../screenWrappers/Features'
import ComplexAttributeRow from '../../components/ComplexAttributeRow.js'
import RebuildProgress from '../../components/RebuildProgress.js'
import { hourInLocalTime } from '../../util/StringUtils'
import HelpDialogListed from '../../components/HelpDialogListed'
import HelpDialog from '../../components/HelpDialog'
import SkeletonArrayRow from '../../components/SkeletonArrayRow'
import { Role } from '../../enums/Role'
import RequiredRoleBoundary from '../../components/RequiredRoleBoundary'

const SectionHeader = styled('div')({
  padding: '10px',
  '& h3': {
    display: 'inline',
    margin: '5px',
  },
})

const UnscrollablePaper = styled(Paper)({
  margin: '20px',
  backgroundColor: '#fafafa',
})

const DaySelectorButton = styled(Button)({
  '&:focus': {
    outline: '0px',
  },
})

const HelpIconButton = styled(IconButton)({
  verticalAlign: 'text-bottom',
  padding: '0px',
})

const SaveButtonContainer = styled('div')({
  padding: '20px',
  '& button': {
    margin: '10px',
  },
  width: '100%',
  textAlign: 'center',
})

const FormLabel = styled(TextField)({
  '& .MuiFormLabel-root': {
    marginTop: '3px',
  },
  '& .MuiInputBase-root': {
    height: 60,
  },

})

const AutocompleteBox = styled('div')({
  display: 'flex',
  flex: 'auto',
})

// Screen requires the following data providers and features injected
const wrap = flow([
  withAllVmOptionsConfig,
  withVm,
  withConfirmFeature,
  withSnackbarsFeature,
  asBaseScreen,
])

/**
 * Displays VM details in simple list view
 */
class VmDetailsScreen extends React.Component {
  constructor(props) {
    super(props)

    this.datalabFacade = props.datalabFacade

    this.state = {
      loading: true,
      vm: undefined,
      vmOptions: undefined,
      powerState: undefined,
      shutdownBypassUntil: undefined,
      redeploymentDueDate: undefined,
      settingShutdownBypass: false,
      selectedBypassDays: undefined,
      selectedVmSizeOption: undefined,
      selectedVmImageType: undefined,
      selectedVmVersion: undefined,
      settingVmSize: false,
      settingVmType: false,
      settingVmDiskSize: false,
      currentVmImageType: undefined,
      selectableVmImageTypes: undefined,
      currentVmSizeOption: undefined,
      currentVmVersionOption: undefined,
      selectableVmSizeOptions: undefined,
      selectableVmDiskOptions: undefined,
      selectableVmVersions: undefined,
      selectedVmDiskSize: undefined,
      vmSizeOptions: undefined,
      vmTypeOption: undefined,
      vmVersionOptions: undefined,
      vmDiskOptions: undefined,
    }
  }

  componentDidUpdate(prevProps) {
    if (prevProps.vmData !== this.props.vmData) {
      // update this to get the basic details faster
      this.setState({
        vm: this.props.vmData,
        loading: true,
      })
    }

    if (this.state.loading && this.props.vmOptionsConfig && this.props.vmData) {
      const vm = this.props.vmData

      // Separate the different config options
      const vmSizeOptions = this.props.vmOptionsConfig.filter((x) => x.configType === 'vmSize')
      const vmTypeOptions = this.props.vmOptionsConfig.filter((x) => x.configType === 'vmType')
      const vmVersionOptions = this.props.vmOptionsConfig.filter((x) => x.configType === 'vmVersion')
      const vmDiskOptions = this.props.vmOptionsConfig.filter((x) => x.configType === 'vmDiskSize')

      // Find the vms current config options
      const currentVmImageType = vmTypeOptions.find((vmTypeOption) => vmTypeOption.type === vm.type)
      const currentVmSizeOption = vmSizeOptions.find((vmSizeOption) => vmSizeOption.type === vm.size)
      const currentVmVersionOption = vmVersionOptions.find((vmVersionOption) => vmVersionOption.version === vm.version)

      // Calculate the config options the vm could change to
      const selectableVmSizeOptions = vmSizeOptions.filter((vmSizeOption) => vmSizeOption.type !== currentVmSizeOption.type)
      const selectableVmImageTypes = vmTypeOptions
      const selectableVmVersions = vmVersionOptions.filter((vmVersionOption) => vmVersionOption.availableForRebuild)

      const selectableVmDiskOptions = vmDiskOptions.filter((vmDiskOption) => vmDiskOption.size !== vm.localDiskSize).filter((vmDiskOption) => {
        if (vm.localDiskSize) {
          return vm.localDiskSize < vmDiskOption.size
        }
        return true
      })

      this.setState({
        vm,
        currentVmImageType,
        currentVmSizeOption,
        currentVmVersionOption,
        selectedVmImageType: currentVmImageType,
        selectedVmVersion: currentVmVersionOption.availableForRebuild ? currentVmVersionOption : selectableVmVersions.find((e) => e.latest),
        selectableVmImageTypes,
        selectableVmVersions,
        selectableVmSizeOptions,
        selectableVmDiskOptions,
        vmSizeOptions,
        vmTypeOptions,
        vmVersionOptions,
        vmDiskOptions,
        loading: false,
      })
    }
  }

  handleStartVM = () => {
    this.props.askForConfirmationListener('Are you sure you want to start this VM?', async () => {
      await this.datalabFacade.startVM(this.state.vm.VMname, this.props.user.pod)
      this.props.showSnackbarInProgress(`Start Virtual Machine (${this.state.vm.VMname}) in progress...`)
    })
  }

  handleStopVM = () => {
    this.props.askForConfirmationListener('Are you sure you want to stop this VM?', async () => {
      await this.datalabFacade.stopVM(this.state.vm.VMname, this.props.user.pod)
      this.props.showSnackbarInProgress(`Stop Virtual Machine (${this.state.vm.VMname}) in progress...`)
    })
  }

  handleRestartVm = () => {
    this.props.askForConfirmationListener('Are you sure you want to restart this VM?', async () => {
      await this.datalabFacade.restartVm(this.state.vm.VMname, this.props.user.pod)
      this.props.showSnackbarInProgress(`Restart Virtual Machine (${this.state.vm.VMname}) in progress...`)
    })
  }

  handleSetVmBypassShutdown = () => {
    this.props.askForConfirmationListener(
      this.state.selectedBypassDays === 0
        ? 'Are you sure you want to reset the bypass to default? The machine will be automatically shutdown tonight'
        : `Are you sure you want to bypass the automatic shutdown of this VM for ${this.state.selectedBypassDays} day(s)?`,
      async () => {
        try {
          const result = await this.datalabFacade.setVmBypassShutdown(this.state.vm.VMname, this.state.selectedBypassDays)
          this.props.showSnackbarSuccess('Bypass VM shutdown succeeded')
          this.setState({ shutdownBypassUntil: result.shutdownBypassUntil, settingShutdownBypass: false })
        } catch (error) {
          this.props.showSnackbarFail(error.response && error.response.data && error.response.data.message ? error.response.data.message : 'An unknown error occurred, please contact technical support', false)
        }
      }
    )
  }

  handleResizeVm = () => {
    this.props.askForConfirmationListener(`Are you sure you want to resize this VM to ${this.state.selectedVmSizeOption.displayName}?\n\nIf the VM is running, this will restart the VM.`, async () => {
      const project = await this.datalabFacade.getProject(this.state.vm.project)
      await this.datalabFacade.resizeVm(project.resourceGroupName, this.state.vm.VMname, this.state.selectedVmSizeOption.type)
      this.props.showSnackbarInProgress(`Resize Virtual Machine ${this.state.vm.VMname} to ${this.state.selectedVmSizeOption.displayName} in progress...`)
      this.setState({ settingVmSize: false })
    })
  }

  // callback if the VM is being reset while it's in "READY" status
  handleRebuild = () => {
    this.props.askForConfirmationListener(`Are you sure you want to rebuild this VM?\n\nThe VM will be destroyed and rebuilt from scratch, please ensure that any work needing to be saved has been added to the Project or Output drives. The process generally takes 45 minutes to complete. ${(!this.state.currentVmVersionOption.availableForRebuild) ? 'WARNING: The current VM version is no longer available, the VM will be rebuilt from the latest version.' : ''}`, async () => {
      await this.datalabFacade.rebuildVm(this.state.vm.VMname, this.props.user.pod)
      this.props.showSnackbarInProgress(`Rebuild Virtual Machine (${this.state.vm.VMname}) in progress...`)
    })
  }

  // callback if the VM is being reset while it's in "DORMANT" or "ERROR" status
  handleRehydrate = () => {
    this.props.askForConfirmationListener('Are you sure you want to rebuild this VM?\n\nThe VM will be destroyed and rebuilt from scratch. The process generally takes 45 minutes to complete.', async () => {
      await this.datalabFacade.rebuildVm(this.state.vm.VMname, this.props.user.pod)
      this.props.showSnackbarInProgress(`Rebuild Virtual Machine (${this.state.vm.VMname}) in progress...`)
    })
  }

  handleChangeVmType = () => {
    this.props.askForConfirmationListener(`Are you sure want to change the VM type/version to ${this.state.selectedVmImageType.displayName} (${this.state.selectedVmVersion.version})?\n\nThe VM will be destroyed and rebuilt, please ensure any work needing to be saved has been added to the Project or Output drives.`, async () => {
      await this.datalabFacade.changeVmType(this.state.vm.VMname, this.state.selectedVmImageType.type, this.state.selectedVmVersion.version, this.props.user.pod)
      this.props.showSnackbarInProgress(`Change Virtual Machine ${this.state.vm.VMname} type to ${this.state.selectedVmImageType.displayName} in progress...`)
      this.setState({ settingVmType: false })
    })
  }

  handleChangeVmDiskSize = () => {
    this.props.askForConfirmationListener(`Are you sure want to ${this.state.vm.localDiskSize ? 'resize the' : 'attach a'} local disk for this machine?\n\nSelected size: ${this.state.selectedVmDiskSize.displayName}. Warning: the machine will be rebooted to apply the changes`, async () => {
      await this.datalabFacade.configureVmLocalDisk(this.state.vm.VMname, this.state.selectedVmDiskSize.size, this.props.user.pod)
      this.props.showSnackbarInProgress(`${this.state.vm.localDiskSize ? 'Resize' : 'Attach'} local disk for ${this.state.vm.VMname} in progress...`)
      this.setState({ settingVmDiskSize: false })
    })
  }

  handleRemoveVmDisk = () => {
    this.props.askForConfirmationListener('Are you sure want to delete the local disk for this machine? Warning: the machine will be rebooted to apply the changes', async () => {
      await this.datalabFacade.configureVmLocalDisk(this.state.vm.VMname, null, this.props.user.pod)
      this.props.showSnackbarInProgress(`Delete local disk for ${this.state.vm.VMname} in progress...`)
    })
  }

  powerStateCallback = (powerState) => {
    this.setState({ powerState })
  }

  shutdownBypassUntilCallback = (shutdownBypassUntil) => {
    this.setState({ shutdownBypassUntil })
  }

  redeploymentDueDateCallback = (redeploymentDueDate) => {
    this.setState({ redeploymentDueDate })
  }

  render() {
    return (
      <RequiredRoleBoundary
        allowedRoles={[Role.ADMIN]}
        functionProps={[
          'onClick',
        ]}
      >
        {this.state.vm && this.state.vm.status === 'DORMANT'
                    && (
                    <div className="action-button-container">
                      <Typography variant="subtitle2" component="p">
                        <i style={{ color: '#d6a30b' }} className="fas fa-exclamation-triangle" />
&nbsp;&nbsp;The VM is currently dormant. Some options may not be available until the machine is rebuilt.
                      </Typography>
                    </div>
                    )}
        {this.state.vm && this.state.vm.status === 'ERROR'
                    && (
                    <div className="action-button-container">
                      <Typography variant="subtitle2" component="p">
                        <i style={{ color: '#c61400' }} className="fas fa-exclamation-triangle" />
&nbsp;&nbsp;The VM is currently in an error state. Some options may not be available until the machine is rebuilt. Rebuild the machine to attempt to resolve the error.
                      </Typography>
                    </div>
                    )}
        {this.state.vm && this.state.vm.status === 'BUILDING'
                    && (
                    <div className="action-button-container">
                      <Typography variant="subtitle2" component="p">
                        <i style={{ color: '#00698f' }} className="fas fa-exclamation-triangle" />
&nbsp;&nbsp;The VM is currently building. Please wait for the build process to complete.
                      </Typography>
                    </div>
                    )}
        {this.state.vm && (this.state.vm.status === 'ERROR' || this.state.vm.status === 'DORMANT')
                    && (
                    <div className="action-button-container">
                      <Button variant="contained" color="primary" onClick={this.handleRehydrate.bind(this)}>
                        Rebuild Now&nbsp;&nbsp;
                        <i className="fas fa-hammer" />
                      </Button>

                    </div>
                    )}
        {this.state.currentVmVersionOption && !this.state.currentVmVersionOption.latest
                    && (
                    <Alert color="warning">
                      {
                            this.state.currentVmVersionOption.availableForRebuild
                              ? `Warning: The currently selected VM version (${this.state.currentVmVersionOption.version}) is on a deprecation path. See Desktop Version settings below.`
                              : `Warning: The currently selected VM version (${this.state.currentVmVersionOption.version}) is no longer available. The VM will automatically upgrade to the latest version during next rebuild.`
                        }
                    </Alert>
                    )}
        <Grid container direction="row" alignItems="stretch" className="details-grid">

          <Grid item xs={5}>
            {!this.state.vm ? <SkeletonArrayRow />
              : (
                <UnscrollablePaper variant="outlined">
                  <SectionHeader>
                    <Typography variant="h6" component="h3">
                      Basic Attributes
                    </Typography>
                  </SectionHeader>
                  <ComplexAttributeRow keyValuePairs={[
                    { header: 'Name', value: this.state.vm.VMname },
                    { header: 'User', value: this.state.vm.assignedTo, linkTo: 'users' },
                    { header: 'Project', value: this.state.vm.project, linkTo: 'projects' },
                    { header: 'Status', value: this.state.vm.status, status: true },
                    { header: 'Date Registered', value: this.state.vm.buildDate, date: true },
                    { header: 'Date Destroyed / Rebuilt', value: this.state.vm.destroyDate, date: true },
                    { header: 'Type', value: this.state.vm.type },
                    { header: 'Size', value: this.state.vm.size },
                    { header: 'Version', value: this.state.vm.version },
                  ]}
                  />
                </UnscrollablePaper>
              )}
          </Grid>

          {this.state.loading ? <Grid item xs={7}><SkeletonArrayRow /></Grid>
            : (
              <Grid item xs={7}>
                {this.state.vm.status === 'READY'
                                && (
                                <UnscrollablePaper variant="outlined">
                                  <SectionHeader>

                                    <Typography variant="h6" component="h3">
                                      Power State
                                    </Typography>

                                    <div className="sub-action-button-container">

                                      <Button variant="outlined" color="primary" onClick={this.handleStopVM.bind(this)} disabled={this.state.powerState !== 'RUNNING'}>
                                        Stop VM&nbsp;&nbsp;
                                        <i className="fas fa-stop" />
                                      </Button>
                                      <Button variant="outlined" color="primary" onClick={this.handleStartVM.bind(this)} disabled={this.state.powerState !== 'DEALLOCATED'}>
                                        Start VM&nbsp;&nbsp;
                                        <i className="fas fa-play" />
                                      </Button>
                                      <Button variant="outlined" color="primary" onClick={this.handleRestartVm.bind(this)} disabled={this.state.powerState !== 'RUNNING'}>
                                        Restart VM&nbsp;&nbsp;
                                        <ReplayIcon />
                                      </Button>
                                      <HelpIconButton
                                        color="primary"
                                        onClick={() => this.setState({ showingPowerHelp: true })}
                                        aria-label="virtual machine power help"
                                        size="large"
                                      >
                                        <HelpIcon />
                                      </HelpIconButton>
                                    </div>
                                  </SectionHeader>
                                  {this.state.showingPowerHelp
                                        && (
                                        <HelpDialog
                                          open={this.state.showingPowerHelp}
                                          callback={() => this.setState({ showingPowerHelp: false })}
                                          title="Power"
                                          message="Power state refers to whether the machine is physically turned on or off. It must be turned on to successfully launch a session."
                                        />
                                        )}

                                  <ComplexAttributeRow
                                    refreshTimestamp={this.props.timestamp}
                                    keyValuePairs={[
                                      {
                                        header: 'Power State',
                                        lazy: () => this.datalabFacade.getVmState(this.state.vm.VMname),
                                        lazyKey: 'status',
                                        status: true,
                                        dataCallback: this.powerStateCallback.bind(this),
                                        value: this.state.powerState,
                                      },
                                    ]}
                                  />
                                </UnscrollablePaper>
                                )}
                {this.state.vm.status === 'READY'
                                && (
                                <UnscrollablePaper variant="outlined">
                                  <SectionHeader>

                                    <Typography variant="h6" component="h3">
                                      Scheduled Shutdown
                                    </Typography>

                                    <div className="sub-action-button-container">

                                      {this.state.settingShutdownBypass
                                        ? (
                                          <Button variant="outlined" color="primary" onClick={() => this.setState({ settingShutdownBypass: false })}>
                                            Cancel&nbsp;&nbsp;
                                            <i className="fas fa-times" />
                                          </Button>
                                        )
                                        : (
                                          <Button variant="outlined" color="primary" onClick={() => this.setState({ settingShutdownBypass: true })} disabled={this.state.shutdownBypassUntil === undefined || this.state.vm.status !== 'READY'}>
                                            Bypass Shutdown&nbsp;&nbsp;
                                            <i className="far fa-clock" />
                                          </Button>
                                        )}
                                      <HelpIconButton
                                        color="primary"
                                        onClick={() => this.setState({ showingShutdownHelp: true })}
                                        aria-label="virtual machine shutdown help"
                                        size="large"
                                      >
                                        <HelpIcon />
                                      </HelpIconButton>
                                    </div>
                                  </SectionHeader>
                                  {this.state.showingShutdownHelp
                                        && (
                                        <HelpDialog
                                          open={this.state.showingShutdownHelp}
                                          callback={() => this.setState({ showingShutdownHelp: false })}
                                          title="Scheduled Shutdown"
                                          message={`All machines are automatically shut down at ${hourInLocalTime(22)} every night. You can choose to bypass the shutdown for up to 3 nights at a time. You cannot bypass a shutdown the night a rebuild is scheduled.`}
                                        />
                                        )}

                                  {this.state.settingShutdownBypass
                                    ? (
                                      <>
                                        <div className="margin20">
                                          <Typography variant="caption" component="p">
                                            Automatic shutdown of this Virtual Machine can be bypassed for up to 3 days. It will be bypassed until
                                            {' '}
                                            {hourInLocalTime(22)}
                                            {' '}
                                            of the selected day.
                                          </Typography>
                                          <ButtonGroup
                                            sx={{
                                              marginTop: '30px',
                                            }}
                                            color="primary"
                                            aria-label="bypass shutdown days button group"
                                          >
                                            <DaySelectorButton onClick={() => this.setState({ selectedBypassDays: 0 })} variant={this.state.selectedBypassDays === 0 ? 'contained' : 'outlined'}>
                                              Default (Tonight)
                                            </DaySelectorButton>
                                            <DaySelectorButton onClick={() => this.setState({ selectedBypassDays: 1 })} variant={this.state.selectedBypassDays === 1 ? 'contained' : 'outlined'}>
                                              {`${new Date(new Date().getTime() + 86400000).toLocaleDateString('default', { weekday: 'long' })} (1 Night)`}
                                            </DaySelectorButton>
                                            <DaySelectorButton onClick={() => this.setState({ selectedBypassDays: 2 })} variant={this.state.selectedBypassDays === 2 ? 'contained' : 'outlined'}>
                                              {`${new Date(new Date().getTime() + 86400000 * 2).toLocaleDateString('default', { weekday: 'long' })} (2 Nights)`}
                                            </DaySelectorButton>
                                            <DaySelectorButton onClick={() => this.setState({ selectedBypassDays: 3 })} variant={this.state.selectedBypassDays === 3 ? 'contained' : 'outlined'}>
                                              {`${new Date(new Date().getTime() + 86400000 * 3).toLocaleDateString('default', { weekday: 'long' })} (3 Nights)`}
                                            </DaySelectorButton>
                                          </ButtonGroup>

                                        </div>
                                        <SaveButtonContainer>
                                          <Button variant="contained" color="primary" onClick={this.handleSetVmBypassShutdown.bind(this)} disabled={(this.state.selectedBypassDays === undefined)}>
                                            Submit
                                          </Button>
                                        </SaveButtonContainer>
                                      </>
                                    )
                                    : (
                                      <ComplexAttributeRow
                                        refreshTimestamp={this.props.timestamp}
                                        keyValuePairs={[
                                          {
                                            header: 'Next Due',
                                            lazy: () => this.datalabFacade.getVmBypassShutdown(this.state.vm.VMname),
                                            lazyKey: 'shutdownBypassUntil',
                                            dateFutureDayOrTonight: true,
                                            dataCallback: this.shutdownBypassUntilCallback.bind(this),
                                            value: this.state.shutdownBypassUntil,
                                          },
                                        ]}
                                      />
                                    )}
                                </UnscrollablePaper>
                                )}
                {this.state.vm.status === 'READY'
                                && (
                                <UnscrollablePaper variant="outlined">
                                  <>
                                    <SectionHeader>

                                      <Typography variant="h6" component="h3">
                                        Scheduled Rebuild
                                      </Typography>

                                      <div className="sub-action-button-container">

                                        <Button variant="outlined" color="primary" onClick={this.handleRebuild.bind(this)} disabled={this.state.vm.status !== 'READY'}>
                                          Rebuild Now&nbsp;&nbsp;
                                          <i className="fas fa-hammer" />
                                        </Button>
                                        <HelpIconButton
                                          color="primary"
                                          onClick={() => this.setState({ showingRebuildHelp: true })}
                                          aria-label="virtual machine rebuild help"
                                          size="large"
                                        >
                                          <HelpIcon />
                                        </HelpIconButton>
                                      </div>
                                    </SectionHeader>
                                    {this.state.showingRebuildHelp
                                            && (
                                            <HelpDialog
                                              open={this.state.showingRebuildHelp}
                                              callback={() => this.setState({ showingRebuildHelp: false })}
                                              title="Scheduled Rebuild"
                                              message='All machines are automatically rebuilt every 30 days for security and maintenance purposes. You cannot delay the rebuild but you can choose to rebuild the machine earlier than the scheduled date, resetting the schedule to 30 days from now. If the machine has not been used in the last 21 days when the automated rebuild occurs the machine will be marked as "dormant". This means the machine is destroyed, but not rebuilt. However you can build the machine again at any time.'
                                            />
                                            )}

                                    <ComplexAttributeRow
                                      refreshTimestamp={this.props.timestamp}
                                      keyValuePairs={[
                                        {
                                          header: 'Next Due',
                                          lazy: () => this.datalabFacade.getVmBypassShutdown(this.state.vm.VMname),
                                          lazyKey: 'redeploymentDueDate',
                                          dateTime: true,
                                          dataCallback: this.redeploymentDueDateCallback.bind(this),
                                          value: this.state.redeploymentDueDate,
                                        },
                                      ]}
                                    />

                                    {this.state.redeploymentDueDate
                                      ? <RebuildProgress loading={false} rebuildDate={new Date(this.state.redeploymentDueDate)} />
                                      : <RebuildProgress loading />}

                                  </>
                                </UnscrollablePaper>
                                )}
                {(this.state.vm.status === 'READY' || this.state.vm.status === 'DORMANT')
                                && (
                                <UnscrollablePaper variant="outlined">
                                  <SectionHeader>

                                    <Typography variant="h6" component="h3">
                                      Size
                                    </Typography>

                                    <div className="sub-action-button-container">

                                      {this.state.settingVmSize
                                        ? (
                                          <Button variant="outlined" color="primary" onClick={() => this.setState({ settingVmSize: false })}>
                                            Cancel&nbsp;&nbsp;
                                            <i className="fas fa-times" />
                                          </Button>
                                        )
                                        : (
                                          <Button variant="outlined" color="primary" onClick={() => this.setState({ settingVmSize: true })} disabled={!(this.state.vm.status === 'READY' || this.state.vm.status === 'DORMANT')}>
                                            Resize
                                          </Button>
                                        )}
                                      <HelpIconButton
                                        color="primary"
                                        onClick={() => this.setState({ showingVmSizeHelp: true })}
                                        aria-label="virtual machine size help"
                                        size="large"
                                      >
                                        <HelpIcon />
                                      </HelpIconButton>
                                    </div>
                                  </SectionHeader>
                                  {this.state.showingVmSizeHelp
                                        && (
                                        <HelpDialogListed
                                          open={this.state.showingVmSizeHelp}
                                          callback={() => this.setState({ showingVmSizeHelp: false })}
                                          title="Available Virtual Machine Size Options"
                                          headers={[
                                            { formatted: 'Type', key: 'displayName' },
                                            { formatted: 'Description', key: 'description' },
                                          ]}
                                          rows={this.state.vmSizeOptions}
                                          rowKey="type"
                                          message="All machines are allocated a certain amount of CPU and RAM. The machine can be resized to suit the needs of the researcher."
                                        />
                                        )}
                                  {this.state.settingVmSize

                                    ? (
                                      <div className="margin20">

                                        <Typography style={{ padding: '10px 0px 10px 0px', fontSize: '0.8em' }} variant="caption1" component="p">
                                          * Some options may be disabled for instant resize due to incompatibilities, in this case remove the User from the Project and reassign them with the required size
                                        </Typography>

                                        <AutocompleteBox>
                                          <Autocomplete
                                            id="autocomplete-vmSizeOptions"
                                            options={this.state.selectableVmSizeOptions}
                                            getOptionDisabled={(option) => this.state.currentVmSizeOption.compatibilitySet !== option.compatibilitySet}
                                            getOptionLabel={(option) => option.displayName}
                                            style={{ width: 600 }}
                                            defaultValue={null}
                                            onChange={(event, values) => { this.setState({ selectedVmSizeOption: values || null }) }}
                                            renderInput={(params) => (
                                              <FormLabel {...params} label="VM Size" variant="outlined" fullWidth />
                                            )}
                                          />
                                        </AutocompleteBox>

                                        <SaveButtonContainer>
                                          <Button className="margin20" variant="contained" color="primary" onClick={this.handleResizeVm.bind(this)} disabled={this.state.selectedVmSizeOption === null}>
                                            Submit
                                          </Button>
                                        </SaveButtonContainer>
                                      </div>
                                    )
                                    : (
                                      <ComplexAttributeRow
                                        refreshTimestamp={this.props.timestamp}
                                        keyValuePairs={[
                                          {
                                            header: 'Size',
                                            lazy: null,
                                            lazyKey: 'type',
                                            dataCallback: null,
                                            value: this.state.currentVmSizeOption.displayName,
                                          },
                                          {
                                            header: 'Description',
                                            lazy: null,
                                            lazyKey: 'description',
                                            dataCallback: null,
                                            value: this.state.currentVmSizeOption.description,
                                          },
                                        ]}
                                      />
                                    )}
                                </UnscrollablePaper>
                                )}
                {(this.state.vm.status === 'READY' || this.state.vm.status === 'DORMANT')
                                && (
                                <UnscrollablePaper variant="outlined">
                                  <SectionHeader>

                                    <Typography variant="h6" component="h3">
                                      Type and Version

                                    </Typography>

                                    {!this.state.currentVmVersionOption.latest
                                            && <Alert color="info">{this.state.currentVmVersionOption.deprecationText}</Alert>}

                                    <div className="sub-action-button-container">

                                      {this.state.settingVmType
                                        ? (
                                          <Button variant="outlined" color="primary" onClick={() => this.setState({ settingVmType: false })}>
                                            Cancel&nbsp;&nbsp;
                                            <i className="fas fa-times" />
                                          </Button>
                                        )
                                        : (
                                          <Button variant="outlined" color="primary" onClick={() => this.setState({ settingVmType: true })} disabled={!(this.state.vm.status === 'READY' || this.state.vm.status === 'DORMANT')}>
                                            Change VM Type/Version
                                          </Button>
                                        )}
                                      <Button
                                        variant="text"
                                        onClick={() => this.setState({ showingVmTypeHelp: true })}
                                        aria-label="virtual machine type help"
                                        size="large"
                                        endIcon={<HelpIcon />}
                                      >
                                        Types
                                      </Button>
                                      <Button
                                        variant="text"
                                        onClick={() => this.setState({ showingVmVersionHelp: true })}
                                        aria-label="virtual machine version help"
                                        size="large"
                                        endIcon={<HelpIcon />}
                                      >
                                        Versions
                                      </Button>
                                    </div>
                                  </SectionHeader>
                                  {this.state.showingVmTypeHelp
                                        && (
                                        <HelpDialogListed
                                          open={this.state.showingVmTypeHelp}
                                          callback={() => this.setState({ showingVmTypeHelp: false })}
                                          title="Available Virtual Machine Types"
                                          headers={[
                                            { formatted: 'Type', key: 'displayName' },
                                            { formatted: 'Description', key: 'description' },
                                          ]}
                                          rows={this.state.vmTypeOptions}
                                          rowKey="type"
                                          message="All machines are built provided with different software installed. The machine type can be changed to include or exclude particular statistical software."
                                        />
                                        )}
                                  {this.state.showingVmVersionHelp
                                        && (
                                        <HelpDialogListed
                                          open={this.state.showingVmVersionHelp}
                                          callback={() => this.setState({ showingVmVersionHelp: false })}
                                          title="Available Virtual Machine Desktop Versions"
                                          headers={[
                                            { formatted: 'Version', key: 'version' },
                                            { formatted: 'Included Software', key: 'softwareText' },
                                            { formatted: 'Notes', key: 'deprecationText' },
                                          ]}
                                          rows={this.state.vmVersionOptions}
                                          rowKey="type"
                                          message="Desktop versions are released annually and include the most up to date software (security patches are always applied to all desktop versions), plan your migration to the newest version: Previous year versions are no longer available shortly after a new version is released. New major software versions can introduce breaking changes, issues should be reported prior to the previous years version being removed, so give yourself enough time to try out the new version."
                                        />
                                        )}
                                  {this.state.settingVmType
                                    ? (
                                      <div>
                                        <div className="margin20">
                                          <Typography style={{ padding: '10px 0px 10px 0px', fontSize: '0.8em' }} variant="caption1" component="p">
                                            * Changing the type and/or version requires a full rebuild of the VM
                                          </Typography>
                                          <AutocompleteBox>
                                            <Autocomplete
                                              id="autocomplete-vmTypeOptions"
                                              options={this.state.selectableVmImageTypes}
                                              getOptionLabel={(option) => option.displayName}
                                              style={{ width: 600 }}
                                              defaultValue={this.state.currentVmImageType}
                                              onChange={(event, values) => { this.setState({ selectedVmImageType: values || null }) }}
                                              renderInput={(params) => (
                                                <FormLabel {...params} label="VM Type" variant="outlined" fullWidth />

                                              )}
                                            />
                                          </AutocompleteBox>
                                        </div>
                                        <div className="margin20">
                                          {!this.state.currentVmVersionOption.availableForRebuild
                                                    && (
                                                    <Alert color="danger">
                                                      The current VM version (
                                                      {this.state.currentVmVersionOption.version}
                                                      ) is no longer available to be selected
                                                    </Alert>
                                                    )}
                                          <AutocompleteBox>
                                            <Autocomplete
                                              id="autocomplete-vmVersionOptions"
                                              options={this.state.selectableVmVersions}
                                              getOptionLabel={(option) => `${option.version}${option.latest ? ' (Latest)' : ''}`}
                                              style={{ width: 600 }}
                                              defaultValue={this.state.currentVmVersionOption.availableForRebuild ? this.state.currentVmVersionOption : this.state.selectableVmVersions.find((e) => e.latest)}
                                              onChange={(event, values) => { this.setState({ selectedVmVersion: values || null }) }}
                                              renderInput={(params) => (
                                                <FormLabel {...params} label="VM Version" variant="outlined" fullWidth />

                                              )}
                                            />
                                          </AutocompleteBox>
                                        </div>
                                        <div className="margin20">
                                          <SaveButtonContainer>
                                            <Button
                                              className="margin20"
                                              variant="contained"
                                              color="primary"
                                              onClick={this.handleChangeVmType.bind(this)}
                                              disabled={
                                                            (this.state.selectedVmImageType === this.state.currentVmImageType && this.state.selectedVmVersion === this.state.currentVmVersionOption)
                                                            || (this.state.selectedVmImageType === null || this.state.selectedVmVersion === null)
}
                                            >
                                              Submit
                                            </Button>
                                          </SaveButtonContainer>

                                        </div>
                                      </div>
                                    )
                                    : (
                                      <ComplexAttributeRow
                                        refreshTimestamp={this.props.timestamp}
                                        keyValuePairs={[
                                          {
                                            header: 'Type',
                                            lazy: null,
                                            lazyKey: 'type',
                                            dataCallback: null,
                                            value: this.state.currentVmImageType.displayName,
                                          },
                                          {
                                            header: 'Description',
                                            lazy: null,
                                            lazyKey: 'description',
                                            dataCallback: null,
                                            value: this.state.currentVmImageType.description,
                                          },
                                          {
                                            header: 'Version',
                                            lazy: null,
                                            lazyKey: 'type',
                                            dataCallback: null,
                                            value: this.state.currentVmVersionOption.version,
                                          },
                                          {
                                            header: 'Included Software',
                                            lazy: null,
                                            lazyKey: 'softwareText',
                                            dataCallback: null,
                                            value: this.state.currentVmVersionOption.softwareText,
                                          },
                                          {
                                            header: 'Notes',
                                            lazy: null,
                                            lazyKey: 'deprecationText',
                                            dataCallback: null,
                                            value: this.state.currentVmVersionOption.deprecationText,
                                          },
                                        ]}
                                      />
                                    )}
                                </UnscrollablePaper>
                                )}
                {this.state.vm.status === 'READY'
                                && (
                                <UnscrollablePaper variant="outlined">
                                  <SectionHeader>

                                    <Typography variant="h6" component="h3">
                                      Local Disk
                                    </Typography>

                                    <div className="sub-action-button-container">

                                      {this.state.settingVmDiskSize
                                        ? (
                                          <Button variant="outlined" color="primary" onClick={() => this.setState({ settingVmDiskSize: false })}>
                                            Cancel&nbsp;&nbsp;
                                            <i className="fas fa-times" />
                                          </Button>
                                        )
                                        : (
                                          <Button variant="outlined" color="primary" onClick={() => this.setState({ settingVmDiskSize: true })} disabled={this.state.vm.status !== 'READY'}>
                                            {this.state.vm.localDiskSize ? 'Resize Disk' : 'Attach Disk'}
                                          </Button>
                                        )}
                                      <Button variant="outlined" color="primary" onClick={this.handleRemoveVmDisk.bind(this)} disabled={!this.state.vm.localDiskSize}>
                                        Delete Disk&nbsp;&nbsp;
                                        <i className="fas fa-trash" />
                                      </Button>
                                      <HelpIconButton
                                        color="primary"
                                        onClick={() => this.setState({ showingDataDiskHelp: true })}
                                        aria-label="virtual machine disks help"
                                        size="large"
                                      >
                                        <HelpIcon />
                                      </HelpIconButton>
                                    </div>
                                  </SectionHeader>
                                  {this.state.showingDataDiskHelp
                                        && (
                                        <HelpDialogListed
                                          open={this.state.showingDataDiskHelp}
                                          callback={() => this.setState({ showingDataDiskHelp: false })}
                                          title="Available disk sizes"
                                          headers={[
                                            { formatted: 'Size', key: 'displayName' },
                                            { formatted: 'Description', key: 'description' },
                                          ]}
                                          rows={this.state.vmDiskOptions}
                                          rowKey="type"
                                          message="Machines can have SSD disks attached to enable processing of data on the local machine. This can be more efficient than reading/writing from the remote network drives, and also enable analysts to continue running a job even if they switch to another machine. They cannot be used to share data with team members like the Project network drive, they are only accessible to the user on the machine. Local disks can only be increased in size. If you need to shrink the disk consider deleting and recreating the disk"
                                        />
                                        )}
                                  {this.state.settingVmDiskSize

                                    ? (
                                      <div className="margin20">
                                        <AutocompleteBox>
                                          <Autocomplete
                                            id="autocomplete-vmDiskOptions"
                                            options={this.state.selectableVmDiskOptions}
                                            getOptionLabel={(option) => option.displayName}
                                            style={{ width: 600 }}
                                            defaultValue={null}
                                            onChange={(event, values) => { this.setState({ selectedVmDiskSize: values || null }) }}
                                            renderInput={(params) => (
                                              <FormLabel {...params} label="Disk Size" variant="outlined" fullWidth />
                                            )}
                                          />
                                        </AutocompleteBox>

                                        <SaveButtonContainer>
                                          <Button className="margin20" variant="contained" color="primary" onClick={this.handleChangeVmDiskSize.bind(this)} disabled={this.state.selectedVmDiskSize === null}>
                                            Submit
                                          </Button>
                                        </SaveButtonContainer>

                                      </div>
                                    )
                                    : (
                                      <ComplexAttributeRow
                                        refreshTimestamp={this.props.timestamp}
                                        keyValuePairs={[
                                          {
                                            header: 'Disk Size',
                                            value: this.state.vm.localDiskSize ? `${this.state.vm.localDiskSize} GB` : 'Not attached',
                                          },
                                        ]}
                                      />
                                    )}
                                </UnscrollablePaper>
                                )}
              </Grid>
            )}
        </Grid>

      </RequiredRoleBoundary>
    )
  }
}

export default wrap((VmDetailsScreen))

