import EventsScreen from '../screens/admin/EventsScreen';

/**
 * Paths applicable to all users
 */
const routes = [
    {
        path: "/events",
        name: "Action Log",
        Component: EventsScreen
    }
];

export default routes