"use strict";
Object.defineProperty(exports, "__esModule", { value: true });
exports.vmChangeTypeDtoSchema = exports.vmRebuildDtoSchema = exports.vmActionDtoSchema = exports.vmDeleteBatchByUserNameDtoSchema = exports.vmDeleteBatchDtoSchema = exports.vmDeleteDtoSchema = exports.vmPostBatchDtoSchema = exports.vmPostDtoSchema = exports.vmPostDtoSchemaBase = exports.projectDtoSchemaBase = void 0;
const zod_1 = require("zod");
const base_1 = require("../base");
const fields_1 = require("./fields");
/**
 * Base Schema for posting new VMs (assigning a user to a project)
 */
exports.projectDtoSchemaBase = base_1.asyncOperationDtoSchema.extend({
    /** The id of the project */
    projectId: zod_1.z.string(),
});
/**
 * Base Schema for posting new VMs (assigning a user to a project)
 */
exports.vmPostDtoSchemaBase = exports.projectDtoSchemaBase.merge(fields_1.vmInputFields);
/**
 * Schema for posting a new VM (assigning a user to a project)
 */
exports.vmPostDtoSchema = exports.vmPostDtoSchemaBase.extend({
    /** The UPN of the user to assign */
    userName: zod_1.z.string(),
});
/**
 * Schema for posting multiple VMs (assigning multiple users to a project)
 */
exports.vmPostBatchDtoSchema = exports.vmPostDtoSchemaBase.extend({
    /** The UPNs of the users to assign */
    userNames: zod_1.z.array(zod_1.z.string()),
});
/**
 * Schema for deleting a VM (unassigning a user from a project)
 */
exports.vmDeleteDtoSchema = exports.projectDtoSchemaBase.extend({
    /** The upn of the user */
    userName: zod_1.z.string(),
});
/**
 * Schema for deleting multiple VMs (unassigning multiple users from a project)
 */
exports.vmDeleteBatchDtoSchema = exports.projectDtoSchemaBase.extend({
    /** The UPNs of the users to delete VMs for in the project */
    userNames: zod_1.z.array(zod_1.z.string()),
});
/**
 * Schema for deleting multiple VMs by userName (unassigning a user from all their projects)
 */
exports.vmDeleteBatchByUserNameDtoSchema = base_1.asyncOperationDtoSchema.extend({
    /** The upn of the user */
    userName: zod_1.z.string(),
    /** The ids of the projects to delete the users vms for */
    projectIds: zod_1.z.array(zod_1.z.string()),
});
/**
 * Base schema used for apis that perform an action on a VM
 */
exports.vmActionDtoSchema = base_1.asyncOperationDtoSchema.extend({
    /** The name of the vm */
    vmName: zod_1.z.string(),
});
/**
 * Schema for rebuildVm api
 */
exports.vmRebuildDtoSchema = exports.vmActionDtoSchema.extend({
    /**
     * Indicates if the vm has been touched recently ie. a user has connected to it. If true the vm
     * will be destroyed and then redeployed, if false the vm will be destroyed and marked as dormant
     * in the database but won't be redeployed
     */
    touched: zod_1.z.boolean(),
});
/**
 * Schema for changeVmType api
 */
exports.vmChangeTypeDtoSchema = exports.vmActionDtoSchema.extend({
    /** The vm type identifier to use for deploying the vm */
    type: zod_1.z.string(),
    /** The vm version identifier to use for deploying the vm */
    version: zod_1.z.string(),
});
