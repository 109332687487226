import React from 'react';
import flow from 'lodash.flow';
import NewTagScreen from './NewTagScreen';
import { withAllTags, withTag } from '../../screenWrappers/DataProviders';
import { withConfirmFeature, withSnackbarsFeature } from '../../screenWrappers/Features';

const wrap = flow([
    withTag,
    withAllTags,
    withConfirmFeature,
    withSnackbarsFeature
])

export default wrap((props) => <NewTagScreen updating={true} {...props} />);