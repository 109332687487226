"use strict";
Object.defineProperty(exports, "__esModule", { value: true });
exports.tagDtoSchema = void 0;
const zod_1 = require("zod");
/** Database fields set by user input that can be queried via the api */
const tagInputFields = zod_1.z.object({
    tagKey: zod_1.z.string(),
    tagValues: zod_1.z.array(zod_1.z.string()),
});
/** Generated database fields that can be queried via the api */
const generatedFields = zod_1.z.object({});
/** All database fields that are exposed via API */
const tagDtoSchema = tagInputFields.merge(generatedFields);
exports.tagDtoSchema = tagDtoSchema;
