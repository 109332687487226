"use strict";
Object.defineProperty(exports, "__esModule", { value: true });
exports.asyncOperationResponse = exports.asyncOperationDtoSchema = void 0;
const zod_1 = require("zod");
/**
 * A base schema for use with the AsyncHttpTrigger durable trigger function
 */
exports.asyncOperationDtoSchema = zod_1.z.object({
    /**
     * The trigger is responsible for creating the async operation event in the
     * cosmosdb so requires a podId to create the event in the correct pod
     */
    podId: zod_1.z.string(),
});
exports.asyncOperationResponse = zod_1.z.object({
    /**
     * It returns the event (action log) ID for tracking. Query the getEvent
     * endpoint with the ID to check the progress.
     */
    eventId: zod_1.z.string(),
});
