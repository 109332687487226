import React from 'react'
import PropTypes from 'prop-types'
import { styled } from '@mui/material/styles'
import flow from 'lodash.flow'
import moment from 'moment'
import { AdapterDateFns } from '@mui/x-date-pickers/AdapterDateFns'
import { LocalizationProvider } from '@mui/x-date-pickers/LocalizationProvider'
import {
  Grid, Typography, Paper, IconButton, Box,
} from '@mui/material'
import TableCell from '@mui/material/TableCell'
import TableContainer from '@mui/material/TableContainer'
import TableRow from '@mui/material/TableRow'
import Table from '@mui/material/Table'
import TableBody from '@mui/material/TableBody'
import Tab from '@mui/material/Tab'
import Tabs from '@mui/material/Tabs'
import format from 'date-fns/format'
import HelpIcon from '@mui/icons-material/Help'
import asBaseScreen from '../../screenWrappers/BaseScreen'
import ArrayAttributeRow from '../../components/ArrayAttributeRow'
import {
  withSoftwareLicenseSummaries,
  withCurrentActiveLicenseData,
  withAllDatabricksWorkspaces,
  withAllAadUsers,
  withMonthlyActiveDesktops,
  withMonthlyActiveUsers,
} from '../../screenWrappers/DataProviders'
import SkeletonArrayRow from '../../components/SkeletonArrayRow'
import HelpDialog from '../../components/HelpDialog'
import SasGraphs from '../../components/SasGraphs'
import PastSasGraph from '../../components/PastSasGraph'
import SasSummary from '../../components/SasSummary'
import SpinnerIndeterminate from '../../components/SpinnerIndeterminate'
import DatalabFacade from '../../dataService/DatalabFacade'
import {
  currentLicenseDataShape,
  aadUsersShape,
  databricksWorkspacesShape,
  monthlyActiveDesktopsShape,
  monthlyActiveUsersShape,
} from '../../propTypeShapes'

const UnscrollablePaper = styled(Paper)({
  margin: '20px',
  minHeight: '50vh',
  backgroundColor: '#fafafa',
})

const ScrollablePaper = styled(Paper)({
  margin: '20px',
  backgroundColor: '#fafafa',
  height: '70vh',
  overflowY: 'auto',
})

const ChildUnscrollablePaper = styled(Paper)({
  margin: '20px',
  backgroundColor: '#fafafa',
})

const SectionHeader = styled('div')({
  padding: '10px',
  display: 'flex',
  alignItems: 'center',
  '& h3': {
    margin: '5px',
  },

})

const HelpIconButton = styled(IconButton)({
  verticalAlign: 'text-bottom',
  padding: '0px',
  marginRight: 'auto',
})

const wrap = flow([
  withCurrentActiveLicenseData,
  withAllDatabricksWorkspaces,
  withAllAadUsers,
  withMonthlyActiveDesktops,
  withMonthlyActiveUsers,
  withSoftwareLicenseSummaries,
  asBaseScreen,
])

const endDate = moment().startOf('day')
const timeRanges = [
  { label: 'Last Week', startDate: moment().startOf('day').subtract(6, 'days'), endDate },
  { label: 'Last Month', startDate: moment().subtract(1, 'month'), endDate },
  { label: 'Last 3 Months', startDate: moment().subtract(3, 'months'), endDate },
]

function getTableMonths() {
  const months = []
  const date = new Date()
  for (let i = 11; i >= 0; i -= 1) {
    months.push({
      label: new Date(
        date.getFullYear(),
        date.getMonth() - i,
        1
      ).toLocaleString('default', { month: 'short', year: '2-digit' }),
      startDate: new Date(date.getFullYear(), date.getMonth() - i, 1),
      endDate: new Date(date.getFullYear(), date.getMonth() - i + 1, 0),
    })
  }
  return months
}

const allMonths = getTableMonths()
const initialMonth = allMonths.find((m) => moment(m.startDate).isSame(moment(), 'month'))

const propTypes = {
  datalabFacade: PropTypes.instanceOf(DatalabFacade).isRequired,
  licenseSummariesGraph: PropTypes.func.isRequired,
  licenseSummariesTable: PropTypes.func.isRequired,
  softwareLicenses: currentLicenseDataShape.isRequired,
  aadUsers: aadUsersShape.isRequired,
  databricksWorkspaces: databricksWorkspacesShape.isRequired,
  monthlyActiveDesktopsSummary: monthlyActiveDesktopsShape.isRequired,
  monthlyActiveUsersSummary: monthlyActiveUsersShape.isRequired,
}

class LicensedSoftwareScreen extends React.Component {
  constructor(props) {
    super(props)
    this.datalabFacade = props.datalabFacade
    this.state = {
      monthlyActiveDesktops: undefined,
      monthlyActiveUsers: undefined,
      licenses: undefined,
      aadUsers: undefined,
      licenseSummariesGraph: undefined,
      licenseSummariesTable: undefined,
      showingLicenseCountHelp: false,
      loading: false,
      showCurrentLicenses: true,
      showPastLicenses: false,
      showSummary: false,
      timeRange: timeRanges[0],
      selectedMonth: initialMonth,
      value: 'one',
    }
  }

  async componentDidMount() {
    const { timeRange } = this.state
    const { selectedMonth } = this.state
    this.loadLicenseSummariesGraph(timeRange)
    this.loadLicenseSummariesTable(selectedMonth)
  }

  async componentDidUpdate(prevProps) {
    const { loading } = this.state
    const {
      licenseSummariesGraph,
      licenseSummariesTable,
      softwareLicenses,
      aadUsers,
      databricksWorkspaces,
      monthlyActiveDesktopsSummary,
      monthlyActiveUsersSummary,
    } = this.props
    if (loading && (licenseSummariesGraph || licenseSummariesTable) && softwareLicenses) {
      this.setState({
        loading: false,
        podSasCount: softwareLicenses.sasCount,
        licenses: softwareLicenses.data,
      })
    }

    if (prevProps.softwareLicenses !== softwareLicenses) {
      this.setState({
        licenses: softwareLicenses.data,
        podSasCount: softwareLicenses.sasCount,
      })
    }

    if (prevProps.aadUsers !== aadUsers) {
      this.setState({ aadUsers })
    }

    if (prevProps.databricksWorkspaces !== databricksWorkspaces) {
      const services = [{
        serviceName: 'Databricks',
        maxAllocations: databricksWorkspaces.length,
        allocated: databricksWorkspaces.filter((d) => d.allocatedProject).length,
      }]
      this.setState({ services })
    }

    if (prevProps.monthlyActiveDesktopsSummary !== monthlyActiveDesktopsSummary) {
      const monthlyActiveDesktops = [{
        totalMonthlyActiveDesktops: monthlyActiveDesktopsSummary.totalMonthlyActiveDesktops,
        month: format(new Date(monthlyActiveDesktopsSummary.date), 'MM/yyyy'),
        lastUpdated: format(new Date(monthlyActiveDesktopsSummary.lastUpdated), 'dd/MM HH:mm'),
      }]
      this.setState({ monthlyActiveDesktops })
    }

    if (prevProps.monthlyActiveUsersSummary !== monthlyActiveUsersSummary) {
      const monthlyActiveUsers = [{
        totalMonthlyActiveUsers: monthlyActiveUsersSummary.totalMonthlyActiveUsers,
        periodStart: format(new Date(monthlyActiveUsersSummary.periodStartDate), 'dd/MM HH:mm'),
        periodTo: format(new Date(monthlyActiveUsersSummary.lastUpdated), 'dd/MM HH:mm'),
      }]
      this.setState({ monthlyActiveUsers })
    }
  }

  showSummary = () => {
    this.setState({
      showPastLicenses: false,
      showCurrentLicenses: false,
      showSummary: true,
    })
  }

  setTimeRange = (timeRange) => {
    this.setState({ timeRange })
    this.loadLicenseSummariesGraph(timeRange)
  }

  setTableTimeRange = (selectedMonth) => {
    this.setState({ selectedMonth })
    this.loadLicenseSummariesTable(selectedMonth)
  }

  showCurrentLicenses = () => {
    this.setState({
      showCurrentLicenses: true,
      showPastLicenses: false,
      showSummary: false,
    })
  }

  showPastLicenses = () => {
    this.setState({
      showPastLicenses: true,
      showCurrentLicenses: false,
      showSummary: false,
    })
  }

  async loadLicenseSummariesTable(date) {
    const { loading } = this.state
    if (loading) return
    this.setState({ loading: true })
    this.setState({
      licenseSummariesTable:
      await this.datalabFacade.getSoftwareLicenseSummaries(date.startDate.valueOf(), date.endDate.valueOf()),
      loading: false,
    })
  }

  async loadLicenseSummariesGraph(date) {
    const { loading } = this.state
    if (loading) return
    this.setState({ loading: true })
    this.setState({
      licenseSummariesGraph:
      await this.datalabFacade.getSoftwareLicenseSummaries(date.startDate.valueOf(), date.endDate.valueOf()),
      loading: false,
    })
  }

  render() {
    const {
      licenses,
      podSasCount,
      showingLicenseCountHelp,
      value,
      showCurrentLicenses,
      showPastLicenses,
      licenseSummariesGraph,
      licenseSummariesTable,
      timeRange,
      selectedMonth,
      showSummary,
      loading,
      services,
      aadUsers,
      monthlyActiveDesktops,
      monthlyActiveUsers,
    } = this.state

    return (
      <LocalizationProvider dateAdapter={AdapterDateFns}>
        <div className="details-grid">
          <Grid container spacing={3} direction="row" alignItems="stretch">
            <Grid item xs={6}>
              {!licenses || !podSasCount ? <SpinnerIndeterminate /> : (
                <ScrollablePaper variant="outlined">
                  <SectionHeader>
                    <Typography variant="h6" component="h3">
                      SAS License Data
                    </Typography>
                    <HelpIconButton
                      onClick={() => this.setState({ showingLicenseCountHelp: true })}
                      aria-label="active license help"
                      size="large"
                    >
                      <HelpIcon />
                    </HelpIconButton>
                    {showingLicenseCountHelp && (
                    <HelpDialog
                      open={showingLicenseCountHelp}
                      callback={() => this.setState({ showingLicenseCountHelp: false })}
                      title="Active Licenses"
                      message={`Current data shows the number of software licenses currently being consumed by
                        active DataLab virtual machines (per pod), and the total number of licenses available.
                        The percentage in each piece is of the current active licenses, 
                        not the maximum available licenses. 
                        Past Data displays the highest number of licenses consumed per day, per pod`}
                    />
                    )}
                    <Box>
                      <Tabs
                        aria-label="sas-tabs"
                        textColor="secondary"
                        value={value}
                        onChange={(event, newValue) => { this.setState({ value: newValue }) }}
                        indicatorColor="secondary"
                        centered
                      >
                        <Tab onClick={this.showCurrentLicenses} value="one" label="Current Data" />
                        <Tab onClick={this.showPastLicenses} value="two" label="Past Data" />
                        <Tab onClick={this.showSummary} value="three" label="Summary" />
                      </Tabs>
                    </Box>
                  </SectionHeader>
                  {showCurrentLicenses ? (
                    <SasGraphs podSasData={podSasCount} sasData={licenses} />
                  ) : null}
                  {showPastLicenses ? (
                    <PastSasGraph
                      position="relative"
                      pastData={licenseSummariesGraph}
                      pastDataTable={licenseSummariesTable}
                      timeRanges={timeRanges}
                      timeRange={timeRange}
                      setTimeRange={this.setTimeRange}
                      months={allMonths}
                      selectedMonth={selectedMonth}
                      setTableTimeRange={this.setTableTimeRange}
                    />
                  ) : null}
                  {showSummary ? (
                    <SasSummary
                      pastData={licenseSummariesTable}
                      months={allMonths}
                      selectedMonth={selectedMonth}
                      setTableTimeRange={this.setTableTimeRange}
                      loading={loading}
                    />
                  ) : null}
                </ScrollablePaper>
              )}
            </Grid>
            <Grid item xs={6}>
              {!services ? <SkeletonArrayRow /> : (
                <UnscrollablePaper variant="outlined">
                  <SectionHeader>
                    <Typography variant="h6" component="h3">
                      Project Services
                    </Typography>
                  </SectionHeader>
                  <ArrayAttributeRow
                    columnHeaders={[
                      { name: 'Service name', key: 'serviceName' },
                      { name: 'Maximum allocations to Projects', key: 'maxAllocations' },
                      { name: 'Allocated', key: 'allocated' },
                    ]}
                    keyValuePairs={services}
                    rowKey="serviceName"
                  />
                </UnscrollablePaper>
              )}
            </Grid>
            <Grid item xs={6}>
              {!aadUsers ? <SkeletonArrayRow /> : (
                <UnscrollablePaper variant="outlined">
                  <SectionHeader>
                    <Typography variant="h6" component="h3">
                      AAD P1 users
                    </Typography>
                  </SectionHeader>
                  <TableContainer>
                    <Table aria-label="aad users table">
                      <TableRow>
                        <TableCell>Pod</TableCell>
                        <TableCell align="right">Admins</TableCell>
                        <TableCell align="right">Admins (read-only)</TableCell>
                        <TableCell align="right">Pod Owners</TableCell>
                        <TableCell align="right">Analysts</TableCell>
                        <TableCell align="right">Total Admins (unique)</TableCell>
                        <TableCell align="right">Total Users (unique)</TableCell>
                      </TableRow>
                      <TableBody>
                        {aadUsers.podCounts.map((row) => (
                          <TableRow key={row.podId}>
                            <TableCell>{row.podId}</TableCell>
                            <TableCell align="right">{row.admins}</TableCell>
                            <TableCell align="right">{row.readonlyAdmins}</TableCell>
                            <TableCell align="right">{row.podOwners}</TableCell>
                            <TableCell align="right">{row.analysts}</TableCell>
                            <TableCell align="right">{row.totalUniquePodAdmins}</TableCell>
                            <TableCell align="right">{row.totalUniquePodUsers}</TableCell>
                          </TableRow>
                        ))}
                        <TableRow>
                          <TableCell colSpan={1} />
                          <TableCell align="right">Library Admins</TableCell>
                          <TableCell align="right">Billing Admins</TableCell>
                          <TableCell align="right">System Admins</TableCell>
                          <TableCell />
                        </TableRow>
                        <TableRow>
                          <TableCell colSpan={1} />
                          <TableCell align="right">{aadUsers.libraryAdmins}</TableCell>
                          <TableCell align="right">{aadUsers.billingAdmins}</TableCell>
                          <TableCell align="right">{aadUsers.sysadmins}</TableCell>
                          <TableCell align="right" />
                          <TableCell align="right">{aadUsers.totalUniquePrivilegedAdmins}</TableCell>
                          <TableCell align="right">{aadUsers.totalUniquePrivilegedAdmins}</TableCell>
                        </TableRow>
                        <TableRow>
                          <TableCell rowSpan={3} colSpan={3} />
                          <TableCell align="right">Total:</TableCell>
                          <TableCell align="right">{aadUsers.totalUniqueAnalysts}</TableCell>
                          <TableCell align="right">{aadUsers.totalUniqueAdmins}</TableCell>
                          <TableCell align="right">{aadUsers.totalUniqueUsers}</TableCell>
                        </TableRow>
                      </TableBody>
                    </Table>
                  </TableContainer>
                </UnscrollablePaper>
              )}
            </Grid>
            <Grid item xs={6}>
              <UnscrollablePaper variant="outlined">
                {!monthlyActiveDesktops ? <SkeletonArrayRow /> : (
                  <ChildUnscrollablePaper variant="outlined">
                    <SectionHeader>
                      <Typography variant="h6" component="h3">
                        Total Active Desktops (This Calendar Month)
                      </Typography>
                    </SectionHeader>
                    <ArrayAttributeRow
                      columnHeaders={[
                        { name: 'Unique Active Desktops', key: 'totalMonthlyActiveDesktops' },
                        { name: 'Month', key: 'month' },
                        { name: 'Last Updated', key: 'lastUpdated' }]}
                      keyValuePairs={monthlyActiveDesktops}
                    />
                  </ChildUnscrollablePaper>
                )}
                {!monthlyActiveUsers ? <SkeletonArrayRow /> : (
                  <ChildUnscrollablePaper variant="outlined">
                    <SectionHeader>
                      <Typography variant="h6" component="h3">
                        Total Active Users (Last 30 Days)
                      </Typography>
                    </SectionHeader>
                    <ArrayAttributeRow
                      columnHeaders={[
                        { name: 'Unique Active Users', key: 'totalMonthlyActiveUsers' },
                        { name: 'Period Start', key: 'periodStart' },
                        { name: 'Period End', key: 'periodTo' }]}
                      keyValuePairs={monthlyActiveUsers}
                    />
                  </ChildUnscrollablePaper>
                )}
              </UnscrollablePaper>
            </Grid>
          </Grid>
        </div>
      </LocalizationProvider>
    )
  }
}

LicensedSoftwareScreen.propTypes = propTypes
export default wrap((LicensedSoftwareScreen))
