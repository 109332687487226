import React from 'react'
import flow from 'lodash.flow'
import { connect } from 'react-redux'
import PaginatedDataTable from '../../components/PaginatedDataTable'
import asBaseScreen from '../../screenWrappers/BaseScreen'

// Screen requires the following data providers and features injected
const wrap = flow([
  asBaseScreen,
])

/**
 * Displays a list of Events
 */
class EventsScreen extends React.Component {
  constructor(props) {
    super(props)
    this.datalabFacade = props.datalabFacade
    this.state = {
    }
  }

  render() {
    return (
      <PaginatedDataTable
        expandedCells
        timeRangeKey="timeStarted"
        tableCaption="List of Datalab Actions"
        datalabFacade={this.props.datalabFacade}
        collectionName="events"
        podId={this.props.user.pod}
        headers={
                        [
                          { id: 'action', label: 'Action', type: 'string' },
                          { id: 'entityName', label: 'Object Identifier', type: 'string' },
                          {
                            id: 'status', label: 'Status', type: 'status', statusEnums: ['Succeeded', 'In Progress', 'Failed'],
                          },
                          { id: 'remarks', label: 'Remarks', type: 'long-string' },
                          { id: 'initiatedBy', label: 'Initiated By', type: 'string' },
                          { id: 'timeStarted', label: 'Time Started', type: 'date-time-verbose' },
                          { id: 'timeCompleted', label: 'Time Completed', type: 'date-time-verbose' },
                          { id: '_id', label: 'ID', type: 'string' },
                          { id: 'parentId', label: 'Parent Action', type: 'id-link' },
                          { id: 'childEvents', label: 'Sub Actions', type: 'array-id-link' },
                        ]
}
        defaultFilter={{
          property: 'initiatedBy', condition: '$ne', value: 'System', displayValue: 'System',
        }}
        defaultSort={{ property: 'timeStarted', direction: 'desc' }}
        onRowChangeCallback={() => { }}
        rowKey="_id"
        csvExportFileName="datalab_actions"
        limitCount={1000}
      />
    )
  }
}

export default wrap((connect(mapStateToProps)(EventsScreen)))

function mapStateToProps(state) {
  return {
    operations: state.operations,
  }
}
