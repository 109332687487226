import React from 'react'
import { styled } from '@mui/material/styles'
import Menu from '@mui/material/Menu'
import MenuItem from '@mui/material/MenuItem'
import {
  Link as MuiLink, Typography,
} from '@mui/material'
import Box from '@mui/material/Box'
import '@fortawesome/fontawesome-free/css/all.css'
import '../../resources/styles/main.css'
import Button from '@mui/material/Button'
import CelebrationIcon from '@mui/icons-material/Celebration'
import InfoIcon from '@mui/icons-material/Info'
import VerifiedUserIcon from '@mui/icons-material/VerifiedUser'
import ArrowDropDownIcon from '@mui/icons-material/ArrowDropDown'
import ListItemIcon from '@mui/material/ListItemIcon'
import ListItemText from '@mui/material/ListItemText'
import LogoutIcon from '@mui/icons-material/Logout'
import PropTypes from 'prop-types'
import { RoleDisplayName } from '../enums/RoleDisplayName'
import datalabAdminLogo from '../../resources/images/DataLab Admin (280x60).svg'
import seadAdminLogo from '../../resources/images/SEAD Admin (280x60).svg'
import datalabAnalystLogo from '../../resources/images/DataLab (280x60).svg'
import seadAnalystLogo from '../../resources/images/SEAD (280x60).svg'
import { Role } from '../enums/Role'
import DrawerWhatsNew from './DrawerWhatsNew'

const PREFIX = 'NavBar'

const propTypes = {
  user: PropTypes.shape({
    pod: PropTypes.string.isRequired,
    roles: PropTypes.arrayOf(PropTypes.string).isRequired,
    name: PropTypes.string.isRequired,
    username: PropTypes.string.isRequired,
  }).isRequired,
  authButtonMethod: PropTypes.func.isRequired,
}

const classes = {
  root: `${PREFIX}-root`,
  navLink: `${PREFIX}-navLink`,
  navItem: `${PREFIX}-navItem`,
}

const StyledNavbar = styled(Box)((
  {
    theme,
  }
) => ({
  position: 'fixed',
  display: 'flex',
  width: '100%',
  height: '50px',
  zIndex: 1100,
  boxShadow: `0 1px 5px -3px ${theme.palette.primary.light}`,
  alignItems: 'center',
  color: 'black',
  backgroundColor: '#FFF',
  [`& .${classes.navItem}`]: {
    color: theme.palette.primary.main,
    borderRadius: 0,
    height: '30px',
    fontSize: '11px',
    fontWeight: '450',
    '&:hover': {
      color: theme.palette.secondary.main,
    },
  },
}))

class NavBar extends React.Component {
  constructor(props) {
    super(props)
    const { user } = props
    this.state = {
      whatsNewOpen: false,
      menuAnchor: null,
      menuOpen: false,
    }

    if (user.pod === 'ABS') {
      this.logoImage = user.roles.includes(Role.ANALYST)
        ? datalabAnalystLogo
        : datalabAdminLogo
    } else {
      this.logoImage = user.roles.includes(Role.ANALYST)
        ? seadAnalystLogo
        : seadAdminLogo
    }
  }

  handleMenuClick = (event) => {
    this.setState({ menuAnchor: event.target, menuOpen: true })
  }

  handleMenuClose = () => {
    this.setState({ menuAnchor: null, menuOpen: false })
  }

  openWhatsNew = () => {
    this.setState({
      whatsNewOpen: true,
    })
  }

  closeWhatsNew = () => {
    this.setState({
      whatsNewOpen: false,
    })
  }

  render() {
    const { user, authButtonMethod } = this.props
    const { whatsNewOpen, menuOpen, menuAnchor } = this.state

    return (
      <StyledNavbar expand="sm" fixed="top">
        <Box sx={{
          width: '200px',
          padding: '10px',
          '@media (max-width: 1100px)': {
            display: 'none',
          },
        }}
        >
          <Box
            sx={{ width: '100%' }}
            component="img"
            id="absLogo"
            src={this.logoImage}
            alt="ABSLogo"
          />
        </Box>
        <Box sx={{ marginLeft: 'auto' }} />
        {user.pod === 'ABS'
          ? (
            <MuiLink
              variant="caption"
              sx={{
                '@media (max-width: 1000px)': {
                  display: 'none',
                },
                textDecoration: 'none',
                textWrap: 'nowrap',
                fontSize: '11px',
                fontWeight: '450',
              }}
              target="_blank"
              rel="noreferrer"
              href="https://www.abs.gov.au/ausstats/abs@.nsf/mf/1406.0.55.003"
            >
              Always follow your Responsible Use of ABS Microdata obligations
            </MuiLink>
          )
          : (
            <MuiLink
              variant="caption"
              sx={{
                '@media (max-width: 1000px)': {
                  display: 'none',
                },
                textDecoration: 'none',
                textWrap: 'nowrap',
                fontSize: '11px',
                fontWeight: '450',
              }}
              target="_blank"
              rel="noreferrer"
              href="https://www.abs.gov.au/about/data-services/secure-environment-analysing-data-sead/conditions-use"
            >
              Always abide by the SEAD Conditions of Use
            </MuiLink>
          )}
        <Button
          className={classes.navItem}
          variant="text"
          startIcon={<CelebrationIcon />}
          sx={{ textTransform: 'none', textWrap: 'nowrap', '.MuiButton-startIcon': { mr: '4px' } }}
          onClick={this.openWhatsNew}
        >
          What&apos;s New!
        </Button>

        <DrawerWhatsNew open={whatsNewOpen} closeCallback={this.closeWhatsNew} />

        {user.pod === 'ABS' ? (
          <Button
            className={classes.navItem}
            variant="text"
            startIcon={<InfoIcon />}
            sx={{ textTransform: 'none', '.MuiButton-startIcon': { mr: '4px' } }}
            href="https://www.abs.gov.au/ausstats/abs@.nsf/mf/1406.0.55.007"
            target="_blank"
          >
            About
          </Button>
        ) : (
          <Button
            className={classes.navItem}
            variant="text"
            startIcon={<InfoIcon />}
            sx={{ textTransform: 'none', '.MuiButton-startIcon': { mr: '4px' } }}
            href="https://www.abs.gov.au/about/data-services/secure-environment-analysing-data-sead"
            target="_blank"
          >
            About
          </Button>
        )}

        {user.pod === 'ABS'
          ? (
            <Button
              className={classes.navItem}
              variant="text"
              startIcon={<VerifiedUserIcon />}
              sx={{ textTransform: 'none', '.MuiButton-startIcon': { mr: '4px' } }}
              href="https://www.abs.gov.au/about/legislation-and-policy/privacy/datalab-privacy-notice"
              target="_blank"
            >
              Privacy
            </Button>
          ) : (
            <Button
              className={classes.navItem}
              variant="text"
              startIcon={<VerifiedUserIcon />}
              sx={{ textTransform: 'none', '.MuiButton-startIcon': { mr: '4px' } }}
              href="https://www.abs.gov.au/about/legislation-and-policy/privacy/secure-environment-analysing-data-sead-privacy-notice"
              target="_blank"
            >
              Privacy
            </Button>
          )}
        <Button
          className={classes.navItem}
          disableRipple
          sx={{ textTransform: 'none' }}
          endIcon={<ArrowDropDownIcon />}
          variant="text"
          onClick={this.handleMenuClick}
        >
          {user.username}
        </Button>
        <Menu
          id="basic-menu"
          anchorEl={menuAnchor}
          open={menuOpen}
          onClose={this.handleMenuClose}
        >
          <Box sx={{ m: '10px', width: '300px' }}>
            <Typography sx={{ fontSize: '14px', fontWeight: '500' }} onClick={this.handleMenuClose}>
              {user.name}
            </Typography>
            <Typography sx={{ fontSize: '14px' }} onClick={this.handleMenuClose}>
              {user.username}
            </Typography>
            <Typography sx={{ fontSize: '14px' }} onClick={this.handleMenuClose}>
              {' '}
              <strong>Pod: </strong>
              {' '}
              {user.pod === 'ABS' ? 'ABS DataLab' : user.pod }
              {' '}
            </Typography>
            <Typography sx={{ fontSize: '14px' }} onClick={this.handleMenuClose}>
              <strong>Role(s):</strong>
              {' '}
              {user.roles.map((r) => RoleDisplayName[r]).join(', ')}
            </Typography>
          </Box>
          <MenuItem onClick={() => authButtonMethod('logout')}>
            <ListItemIcon>
              <LogoutIcon fontSize="small" sx={{ color: 'primary.main' }} />
            </ListItemIcon>
            <ListItemText> Sign Out</ListItemText>

          </MenuItem>
        </Menu>
      </StyledNavbar>
    )
  }
}

NavBar.propTypes = propTypes
export default NavBar
