"use strict";
Object.defineProperty(exports, "__esModule", { value: true });
exports.reportDtoSchema = void 0;
const zod_1 = require("zod");
/** Database fields set by user input that can be queried via the api */
const reportInputFields = zod_1.z.object({});
/** Generated database fields that can be queried via the api */
const generatedFields = zod_1.z.object({
    reportType: zod_1.z.string(),
    frequency: zod_1.z.string(),
    description: zod_1.z.string(),
    dateAdded: zod_1.z.string(),
    fileName: zod_1.z.string(),
});
/** All database fields that are exposed via API */
const reportDtoSchema = reportInputFields.merge(generatedFields);
exports.reportDtoSchema = reportDtoSchema;
