import React, { useState } from 'react'
import {
  Button,
  Dialog,
  DialogActions,
  DialogContent,
  DialogContentText,
  DialogTitle,
} from '@mui/material'
import PropTypes from 'prop-types'

const DIALOG_PROPERTIES = [
  {
    type: 'loginDetails',
    title: 'User Creation in Progress',
    message: 'An email will be sent to the user containing their login details.',
  },
  {
    type: 'resetPassword',
    title: 'Resetting Password',
    message: `The users password has been reset. They will receive an email containing a temporary password, 
    which will need to be changed the next time they sign in.`,
  },
]

const getDialogProperties = (dialogType) => {
  const dialogInfo = DIALOG_PROPERTIES.find(({ type }) => dialogType === type)
  if (!dialogInfo) {
    throw new Error(`Unknown dialog type ${dialogType}`)
  }
  return dialogInfo
}

const propTypes = {
  dialogType: PropTypes.string.isRequired,
  open: PropTypes.bool.isRequired,
  callback: PropTypes.func.isRequired,
}
export default function TempPasswordDialog({
  dialogType, open, callback,
}) {
  const [openDialog] = useState(open)

  const handleClose = () => {
    callback(false)
  }

  const dialog = getDialogProperties(dialogType)

  return (
    <div>
      <Dialog
        open={openDialog}
        aria-labelledby="alert-dialog-title"
        aria-describedby="alert-dialog-description"
      >
        <DialogTitle id="alert-dialog-title">{dialog.title}</DialogTitle>
        <DialogContent>
          <DialogContentText id="alert-dialog-description">
            {dialog.message}
          </DialogContentText>
        </DialogContent>
        <DialogActions>
          <Button onClick={handleClose} color="primary" autoFocus>
            Ok
          </Button>
        </DialogActions>
      </Dialog>
    </div>
  )
}
TempPasswordDialog.propTypes = propTypes
