import React from 'react'
import flow from 'lodash.flow'
import {
  FormControl,
  InputLabel,
  TextField,
  Grid,
  FormHelperText,
  Button,
  Container,
  Chip,
  Paper,
} from '@mui/material'
import Autocomplete from '@mui/material/Autocomplete'
import { Formik } from 'formik'
import * as Yup from 'yup'
import { styled } from '@mui/material/styles'
import asBaseScreen from '../../screenWrappers/BaseScreen'
import { withConfirmFeature, withLoadingFeature, withSnackbarsFeature } from '../../screenWrappers/Features'
import { withAllOrganisations, withAllTags } from '../../screenWrappers/DataProviders.js'
import SkeletonForm from '../../components/SkeletonForm'

// Screen requires the following data providers and features injected
const wrap = flow([
  withAllOrganisations,
  withAllTags,
  withLoadingFeature,
  withConfirmFeature,
  withSnackbarsFeature,
  asBaseScreen,
])

const StyledChip = styled(Chip)(({ theme }) => ({
  margin: theme.spacing(0.5, 0.25),
}))

/**
 * Using Formik to handle the form data movement
 * Using Yup to handle the form validation (works well with Formik)
 *
 * @see https://hackernoon.com/react-form-validation-with-formik-and-yup-8b76bda62e10
 *
 * Handles both new and updating scenarios, updating boolean prop passed to component
 */
export class NewOrganisationScreen extends React.Component {
  constructor(props) {
    super(props)
    this.datalabFacade = props.datalabFacade
    this.state = {
      loading: true,
      selectableTagKeys: undefined,
      selectableTagValues: undefined,
      selectedTagKey: null,
      selectedTagValue: null,
    }
  }

  componentDidUpdate(prevProps) {
    if (this.state.loading && this.props.tags && (this.props.updating ? this.props.organisationData : true)) {
      const selectableTagKeys = this.props.tags.map((tag) => tag.tagKey)

      this.setState({
        selectableTagKeys,
        loading: false,
      })
    }
  }

  handleSubmitNew = (values) => {
    const organisation = {
      organisationName: values.organisationName,
      ABN: values.ABN,
      podId: this.props.user.pod,
      tags: values.tags,
    }

    this.props.askForConfirmationListener(
      'Are you sure you want to create this organisation?',
      async () => {
        await this.props.datalabFacade.createOrganisation(organisation)
        this.props.showSnackbarSuccess(`Create Organisation (${organisation.organisationName}) succeeded`)
      },
      { redirect: '/organisations' }
    )
  }

  handleSubmitUpdate = (values) => {
    const organisation = {
      organisationId: values.organisationId,
      organisationName: values.organisationName,
      ABN: values.ABN,
      tags: values.tags,
    }

    this.props.askForConfirmationListener(
      'Are you sure you want to update this Organisation?',
      async () => {
        await this.props.datalabFacade.updateOrganisation(organisation)
        this.props.showSnackbarSuccess(`Update Organisation (${organisation.organisationName}) succeeded`)
      },
      { redirect: '/organisations' }
    )
  }

  render() {
    return (

      this.state.loading ? <SkeletonForm />
        : (
          <Container maxWidth="md">

            <Formik
              validateOnChange={false}
              validateOnBlur={false}
              initialValues={{
                organisationId: this.props.updating ? this.props.organisationData.organisationId : '',
                organisationName: this.props.updating ? this.props.organisationData.organisationName : '',
                ABN: this.props.updating ? this.props.organisationData.ABN : '',
                tags: this.props.updating ? (!this.props.organisationData.tags ? [] : this.props.organisationData.tags) : [],
              }}
              onSubmit={(values) => {
                this.props.updating ? this.handleSubmitUpdate(values) : this.handleSubmitNew(values)
              }}
              validationSchema={Yup.object().shape({
                organisationName:
                            Yup.string()
                              .trim()
                              .max(150)
                              .test('isUnique', 'This organisation already exists', (name) => {
                                if (!name) return true
                                if (this.props.updating && this.props.organisationData.organisationName === name) {
                                  return true
                                }
                                return new Promise((resolve, reject) => {
                                  this.props.startLoading()
                                  this.props.datalabFacade.isOrganisationNameUnique(name).then((result) => {
                                    this.props.stopLoading()
                                    resolve(result.isUnique)
                                  }).catch((error) => {
                                    this.setState(() => { throw error })
                                  })
                                })
                              })
                              .required()
                              .label('Organisation Name'),
                ABN: Yup.string()
                  .trim()
                  .matches(/^[0-9]+$/, 'May only contain numbers')
                  .length(11)
                  .required()
                  .label('ABN')
                  .test('isUnique', 'This ABN already exists', (name) => {
                    if (!name) return true
                    if (this.props.updating && this.props.organisationData.ABN === name) {
                      return true
                    }
                    return new Promise((resolve, reject) => {
                      this.props.startLoading()
                      this.props.datalabFacade.isABNUnique(name).then((result) => {
                        this.props.stopLoading()
                        resolve(result.isUnique)
                      }).catch((error) => {
                        this.setState(() => { throw error })
                      })
                    })
                  }),
              })}
            >
              {(props) => {
                const {
                  values,
                  touched,
                  errors,
                  handleChange,
                  handleBlur,
                  handleSubmit,
                  setFieldValue,
                } = props

                return (

                  <form onSubmit={handleSubmit}>
                    <div>

                      <p className="mandatory-info">All fields are required</p>
                      <Grid container spacing={3} alignItems="center" direction="column">

                        <Grid container className="formRow" spacing={1} alignItems="center" justify="center" direction="row">
                          <Grid item xs={2}>
                  <InputLabel htmlFor="organisationName">Organisation Name</InputLabel>
                </Grid>
                          <Grid item xs>
                  <FormControl fullWidth>
                                <TextField
                                    id="organisationName"
                                    aria-describedby="helperOrganisationName"
                                    onChange={handleChange}
                                    onBlur={handleBlur}
                                    value={values.organisationName}
                                    placeholder="Australian Bureau of Statistics"
                                  />
                                <FormHelperText
                                    id="helperOrganisationName"
                                    error={errors.organisationName && touched.organisationName}
                                  >
                                    {(errors.organisationName && touched.organisationName) ? errors.organisationName
                                          : 'The name of the organistion'}
                                  </FormHelperText>
                              </FormControl>
                </Grid>
                        </Grid>

                        <Grid container className="formRow" spacing={1} alignItems="center" justify="center" direction="row">
                          <Grid item xs={2}>
                  <InputLabel htmlFor="ABN">ABN</InputLabel>
                </Grid>
                          <Grid item xs>
                  <FormControl fullWidth>
                                <TextField
                                    id="ABN"
                                    aria-describedby="helperABN"
                                    onChange={handleChange}
                                    onBlur={handleBlur}
                                    value={values.ABN}
                                    placeholder="12345678910"
                                  />
                                <FormHelperText
                                    id="helperABN"
                                    error={errors.ABN && touched.ABN}
                                  >
                                    {(errors.ABN && touched.ABN)
                                          ? errors.ABN
                                          : 'ABN'}
                                  </FormHelperText>
                              </FormControl>
                </Grid>
                        </Grid>

                        <Grid container className="formRow" spacing={1} alignItems="center" justify="center" direction="row">
                          <Grid container className="formRow" spacing={1} alignItems="center" justify="center" direction="row">
                  <Grid item xs={2}>
                                <InputLabel htmlFor="selectedTagKey">Select Tag</InputLabel>
                              </Grid>
                  <Grid item xs>
                                <FormControl fullWidth>
                                    <Autocomplete
                                          id="selectedTagKey"
                                          blurOnSelect
                                          options={this.state.selectableTagKeys}
                                          value={this.state.selectedTagKey}
                                          renderInput={(params) => (
                                            <TextField {...params} label="Tag Name" variant="outlined" fullwidth />
                                          )}
                                          onChange={(event, value) => {
                                            if (value) {
                                              setFieldValue('selectedTagKey', value)
                                              setFieldValue('selectedTagValue', '')

                                              this.setState({
                                                selectedTagKey: value,
                                                selectedTagValue: '',
                                                selectableTagValues: this.props.tags.find((t) => t.tagKey === value).tagValues
                                                  .filter((t) => !values.tags.some((tag) => tag.tagKey === value && tag.tagValue === t)),
                                              })
                                            } else {
                                              setFieldValue('selectedTagKey', '')
                                              setFieldValue('selectedTagValue', '')
                                              this.setState({
                                                selectedTagKey: '',
                                                selectedTagValue: '',
                                                selectableTagValues: [],
                                              })
                                            }
                                          }}
                                        />
                                  </FormControl>
                              </Grid>
                  <Grid item xs={2}>
                                <InputLabel htmlFor="selectedTagValue">Select Tag Value</InputLabel>
                              </Grid>
                  <Grid item xs>
                                <FormControl fullWidth>
                                    <Autocomplete
                                          id="selectedTagValue"
                                          blurOnSelect
                                          disabled={!this.state.selectedTagKey}
                                          options={this.state.selectableTagValues}
                                          value={this.state.selectedTagValue}
                                          renderInput={(params) => (
                                            <TextField {...params} label="Tag Value" variant="outlined" fullwidth />
                                          )}
                                          onChange={(event, value) => {
                                            setFieldValue('selectedTagValue', value)
                                            this.setState({
                                              selectedTagValue: value,
                                            })
                                          }}
                                        />
                                  </FormControl>
                              </Grid>
                  <Grid item xs>
                                <Button
                                    variant="outlined"
                                    disabled={!this.state.selectedTagKey || !this.state.selectedTagValue}
                                    onClick={() => {
                                          values.tags.push({
                                            tagKey: this.state.selectedTagKey,
                                            tagValue: this.state.selectedTagValue,
                                          })
                                          setFieldValue('selectedTagKey', '')
                                          setFieldValue('selectedTagValue', '')
                                          values.selectedTagKey = ''
                                          values.selectedTagValue = ''
                                          this.setState({
                                            selectedTagKey: '',
                                            selectedTagValue: '',
                                            selectableTagValues: [],
                                          })
                                        }}
                                  >
                                        Add&nbsp;&nbsp;
                                    <i className="fas fa-user-plus" />
                                  </Button>
                              </Grid>
                </Grid>
                          <Grid container className="formRow" spacing={1} alignItems="center" justify="center" direction="row">
                  <Grid item xs={2}>
                                <InputLabel htmlFor="selectedTags">Tags Added:</InputLabel>
                              </Grid>
                  <Grid item xs>
                                <Paper variant="outlined" id="selectedTags">
                                    {values.tags.map((tag) => (
                                          <StyledChip
                                            key={`${tag.tagKey}_${tag.tagValue}`}
                                            label={`${tag.tagKey}: ${tag.tagValue}`}
                                            onDelete={(delTag) => {
                                                values.tags.splice(values.tags.indexOf(tag), 1)
                                                setFieldValue('selectedTagKey', '')
                                                setFieldValue('selectedTagValue', '')
                                                values.selectedTagKey = ''
                                                values.selectedTagValue = ''
                                                this.setState({
                                                  selectedTagKey: '',
                                                  selectedTagValue: '',
                                                  selectableTagValues: [],
                                                })
                                              }}
                                          />
                                        ))}
                                  </Paper>
                              </Grid>
                </Grid>
                        </Grid>

                      </Grid>

                      <div className="action-button-container">
                        <Button type="submit" variant="contained" color="primary">
                          Submit
                                </Button>
                      </div>

                    </div>
                  </form>
                )
              }}
            </Formik>

          </Container>
        )
    )
  }
}

export default wrap(NewOrganisationScreen)
