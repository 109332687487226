import React from 'react'
import flow from 'lodash.flow'
import asBaseScreen from '../../screenWrappers/BaseScreen'
import { withUser, withAllOrganisations, withAllTags } from '../../screenWrappers/DataProviders'
import { withSnackbarsFeature, withConfirmFeature, withLoadingFeature } from '../../screenWrappers/Features'
import { NewUserScreen } from './NewUserScreen'

// Screen requires the following data providers and features injected
const wrap = flow([
  withUser,
  withConfirmFeature,
  withSnackbarsFeature,
  withLoadingFeature,
  withAllOrganisations,
  withAllTags,
  asBaseScreen,
])

// eslint-disable-next-line react/jsx-props-no-spreading
export default wrap(((props) => <NewUserScreen updating {...props} />))
