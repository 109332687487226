import React from 'react';
import Skeleton from '@mui/material/Skeleton';
import {
  Container,
} from "@mui/material";

export default function SkeletonForm(props) {
  return (
    <Container maxWidth="md" style={{paddingTop: "50px"}}>
      <Skeleton height={80}/>
      <Skeleton height={80}/>
      <Skeleton height={80}/>
      <Skeleton height={80}/>
      <Skeleton style={{margin: "auto"}} width={100} height={60}/>
    </Container>
  );
}