import React, { useEffect } from 'react'
import PropTypes from 'prop-types'
import Chip from '@mui/material/Chip'
import { styled } from '@mui/system'
import TextField from '@mui/material/TextField'
import Downshift from 'downshift'
import { Paper, Button } from '@mui/material'

const StyledChip = styled(Chip)(({ theme }) => ({
  margin: theme.spacing(0.5, 0.25),
}))

/*
 * Tag input field - code imported from https://codesandbox.io/s/material-ui-input-with-chips-0s2j4?from-embed=&file=/src/TagsInput.js
 */
export default function TagInputField({ ...props }) {
  const {
    selectedTags, placeholder, tags, ...other
  } = props
  const [inputValue, setInputValue] = React.useState('')
  const [selectedItem, setSelectedItem] = React.useState([])
  const { initialTags } = props

  useEffect(() => {
    setSelectedItem(tags)
  }, [tags])
  useEffect(() => {
    selectedTags(selectedItem)
  }, [selectedItem, selectedTags])

  function handleAddValue(newValue) {
    const newSelectedItem = [...selectedItem]
    const duplicatedValues = newSelectedItem.indexOf(
      newValue.trim()
    )

    if (duplicatedValues !== -1) {
      setInputValue('')
      return
    }
    if (!newValue.replace(/\s/g, '').length) return

    newSelectedItem.push(newValue.trim())
    setSelectedItem(newSelectedItem)
    setInputValue('')
  }

  function handleKeyDown(event) {
    if (event.key === 'Enter') {
      if (event.target.value && event.target.value !== '') {
        event.preventDefault()
      }
      handleAddValue(event.target.value)
    }
    if (
      selectedItem.length
            && !inputValue.length
            && event.key === 'Backspace'
    ) {
      setSelectedItem(selectedItem.slice(0, selectedItem.length - 1))
    }
  }
  function handleChange(item) {
    let newSelectedItem = [...selectedItem]
    if (newSelectedItem.indexOf(item) === -1) {
      newSelectedItem = [...newSelectedItem, item]
    }
    setInputValue('')
    setSelectedItem(newSelectedItem)
  }

  const handleDelete = (item) => () => {
    const newSelectedItem = [...selectedItem]
    newSelectedItem.splice(newSelectedItem.indexOf(item), 1)
    setSelectedItem(newSelectedItem)
  }

  function handleInputChange(event) {
    setInputValue(event.target.value)
  }

  return (
    <Downshift
      id="downshift-multiple"
      inputValue={inputValue}
      onChange={handleChange}
      selectedItem={selectedItem}
    >
      {({ getInputProps }) => {
        const {
          onBlur, onChange, onFocus, ...inputProps
        } = getInputProps({
          onKeyDown: handleKeyDown,
          placeholder,
        })
        return (
          <div>

            <div style={{ display: 'flex' }}>
              <TextField
                InputProps={{
                  onBlur,
                  onChange: (event) => {
                    handleInputChange(event)
                    onChange(event)
                  },
                  onFocus,
                }}
                {...other}
                {...inputProps}
              />

              <Button
                variant="contained"
                onClick={() => {
                  handleAddValue(inputValue)
                }}
              >
                Add
              </Button>
            </div>

            <Paper variant="outlined">
              {selectedItem.map((item) => (
                <StyledChip
                  key={item}
                  tabIndex={-1}
                  label={item}
                  onDelete={handleDelete(item)}
                />
              ))}
            </Paper>
          </div>
        )
      }}
    </Downshift>
  )
}
TagInputField.defaultProps = {
  tags: [],
}
TagInputField.propTypes = {
  selectedTags: PropTypes.func.isRequired,
  tags: PropTypes.arrayOf(PropTypes.string),
}
