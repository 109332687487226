"use strict";
Object.defineProperty(exports, "__esModule", { value: true });
exports.eventDtoSchema = void 0;
const zod_1 = require("zod");
/** Database fields set by user input that can be queried via the api */
const eventInputFields = zod_1.z.object({});
/** Generated database fields that can be queried via the api */
const generatedFields = zod_1.z.object({
    action: zod_1.z.string(),
    entityName: zod_1.z.string(),
    status: zod_1.z.enum(['Succeeded', 'In Progress', 'Failed']),
    remarks: zod_1.z.string(),
    initiatedBy: zod_1.z.string(),
    timeStarted: zod_1.z.string().datetime(),
    timeCompleted: zod_1.z.string().datetime(),
    _id: zod_1.z.string(),
    parentId: zod_1.z.string(),
    childEvents: zod_1.z.array(zod_1.z.string()),
});
/** All database fields that are exposed via API */
const eventDtoSchema = eventInputFields.merge(generatedFields);
exports.eventDtoSchema = eventDtoSchema;
