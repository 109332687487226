import React from 'react'
import {
  TableCell,
  TableHead as MuiTableHead,
  TableRow,
  TableSortLabel,
} from '@mui/material'
import PropTypes from 'prop-types'
import { styled } from '@mui/material/styles'
import { tableHeadersProps, orderProps } from './propTypes'

const propTypes = {
  onRequestSort: PropTypes.func.isRequired,
  order: orderProps.isRequired,
  options: PropTypes.bool.isRequired,
  checkboxSelectable: PropTypes.bool,
  collapseColumn: PropTypes.bool,
  headers: tableHeadersProps.isRequired,
}

const defaultProps = {
  checkboxSelectable: false,
  collapseColumn: false,
}

const TableCellHeader = styled(TableCell)({
  fontWeight: 'bold',
  resize: 'horizontal',
  minWidth: '1px',
  maxWidth: '400px',
  overflow: 'hidden',
  whiteSpace: 'nowrap',
  padding: '2px 0px 2px 10px',
  textOverflow: 'ellipsis',
  position: 'sticky',
  top: 0,
  backgroundColor: '#fafafa',
  borderTop: 'none !important',
  borderBottom: 'none !important',
  boxShadow: 'inset 0 -2px 0 #dddddd',
  zIndex: 2,
})

function TableHead({
  onRequestSort, order, options, checkboxSelectable, collapseColumn, headers,
}) {
  return (
    <MuiTableHead>
      <TableRow>
        {collapseColumn
          && (
            <TableCell
              key="collapse"
              align="left"
              padding="none"
            />
          )}
        {/* If checkboxSelectable prop is present, then create empty header for the checkbox column */}
        {checkboxSelectable
          && (
            <TableCellHeader
              padding="normal"
            >
              <div>
                <TableSortLabel
                  hideSortIcon
                />
              </div>
            </TableCellHeader>
          )}
        {headers.filter((header) => !header.hidden).map((header) => (

          <TableCellHeader
            key={header.id}
            align={header.numeric ? 'right' : 'left'}
            padding="normal"
            sortDirection={order ? order.direction : false}
          >
            <div>
              <TableSortLabel
                active={order && order.property === header.id}
                hideSortIcon={header.type === 'array'}
                direction={order ? order.direction : 'asc'}
                onClick={() => onRequestSort(header)}
              >
                {header.label}
              </TableSortLabel>
            </div>
          </TableCellHeader>
        ))}
        {/* If deletable prop then show delete icon */}
        {options
          && (
            <TableCellHeader
              padding="normal"
              key="options"
            >
              <div>
                <TableSortLabel
                  hideSortIcon
                >
                  Actions
                </TableSortLabel>
              </div>
            </TableCellHeader>
          )}
      </TableRow>
    </MuiTableHead>
  )
}
TableHead.propTypes = propTypes
TableHead.defaultProps = defaultProps
export default TableHead
