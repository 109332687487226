import React from 'react'
import PropTypes from 'prop-types'
import axios from 'axios'
import flow from 'lodash.flow'
import {
  Button,
  Typography,
  TextField,
  Link,
  List,
  ListItem,
  ListItemText,
  ListItemSecondaryAction,
  IconButton,
  Paper,
  Container,
  FormHelperText,
} from '@mui/material'
import DeleteIcon from '@mui/icons-material/Delete'
import asBaseScreen from '../../screenWrappers/BaseScreen'
import { withSnackbarsFeature, withConfirmFeature } from '../../screenWrappers/Features'
import DatalabFacade from '../../dataService/DatalabFacade'

// Screen requires the following data providers and features injected
const wrap = flow([
  withConfirmFeature,
  withSnackbarsFeature,
  asBaseScreen,
])

// Regex pattern for validating version input
const VERSION_NUMBER_PATTERN = /^\d+(\.\d+)*$/
const VERSION_SPEC_PATTERN = /^((==|>=?|<=?|!=) *(\d+(\.\d+)*)(, *(?=.))?)+(?![, ])$/

const propTypes = {
  datalabFacade: PropTypes.instanceOf(DatalabFacade).isRequired,
  showSnackbarWarning: PropTypes.func.isRequired,
  showSnackbarInProgress: PropTypes.func.isRequired,
  askForConfirmationListener: PropTypes.func.isRequired,
}

class AddPythonPackageScreen extends React.Component {
  constructor(props) {
    super(props)
    this.datalabFacade = props.datalabFacade
    this.state = {
      selectedPackages: [],
      inputPackage: '',
      inputVersion: '',
      errors: false,
    }
  }

  handleSubmit = async () => {
    const { selectedPackages } = this.state
    const {
      showSnackbarWarning,
      showSnackbarInProgress,
      askForConfirmationListener,
    } = this.props

    if (selectedPackages.length > 10) {
      showSnackbarWarning('A maximum of 10 packages can be added at a time')
    } else {
      askForConfirmationListener(
        selectedPackages.length > 1
          ? 'Are you sure you want to add these Python package and their dependencies to the Approved Repository?'
          : 'Are you sure you want to add this Python package and its dependencies to the Approved Repository?',
        async () => {
          await this.datalabFacade.addPythonPackages(selectedPackages)
          const packages = selectedPackages.map((pkg) => `${pkg.packageName}: ${pkg.packageVersion}`)
          showSnackbarInProgress(`Add Python Packages (${packages.join(', ')}) in progress...`)
          this.handleClearInput()
        },
        {
          redirect: '/packageManagement',
        }
      )
    }
  }

  /**
   * Check a package name is valid by looking it up on PyPI
   * @param {string} name
   * @return {Promise<boolean>} True for a valid package name
   */
  validatePackageName = async (name) => {
    const headers = { Accept: 'application/vnd.pypi.simple.v1+json' }
    try {
      await axios.get(`https://pypi.org/simple/${name}/`, headers)
      return true
    } catch (error) {
      return false
    }
  }

  /**
   * Validate the user input and return any errors found
   * @param {string} name
   * @param {string} version
   * @return {Promise<Object|false>}
   */
  validateInput = async (name, version) => {
    const errors = {}
    const { selectedPackages } = this.state

    if (name === '') {
      errors.name = 'Please enter a package name'
    }
    if (!errors.name && name.match(/[, ]/)) {
      errors.name = 'Please specify only one package at a time'
    }
    if (!errors.name && selectedPackages.some(({ packageName }) => packageName === name)) {
      errors.name = 'This package has already been selected'
    }
    if (!errors.name && !await this.validatePackageName(name)) {
      errors.name = 'This package cannot be found on PyPI'
    }

    if (version === '') {
      errors.version = 'Please enter a package version'
    }
    if (!errors.version && !VERSION_SPEC_PATTERN.test(version)) {
      errors.version = 'This version format is not valid'
    }

    return Object.keys(errors).length ? errors : false
  }

  setSelectedPackages = async () => {
    // eslint-disable-next-line react/destructuring-assignment
    const inputPackage = this.state.inputPackage.trim()
    // eslint-disable-next-line react/destructuring-assignment
    let inputVersion = this.state.inputVersion.trim()

    // Apply default greater-than-equal operator to plain version numbers
    if (VERSION_NUMBER_PATTERN.test(inputVersion)) {
      inputVersion = `>= ${inputVersion}`
    }

    // Validate user input
    const errors = await this.validateInput(inputPackage, inputVersion)
    if (Object.values(errors).some(Boolean)) {
      this.setState({ errors })
      return
    }

    const newPackage = {
      packageName: inputPackage,
      packageVersion: inputVersion,
    }

    this.setState((previousState) => ({
      selectedPackages: [...previousState.selectedPackages, newPackage],
      inputPackage: '',
      inputVersion: '',
      errors,
    }))

    this.packageNameInput.focus()
  }

  removePackage = (packageToRemove) => {
    const { selectedPackages } = this.state
    const updatedPackages = selectedPackages.filter((item) => item !== packageToRemove)
    this.setState({ selectedPackages: updatedPackages })
  }

  handleClearInput = () => {
    this.setState({
      inputPackage: '',
      inputVersion: '',
      errors: false,
    })
  }

  handleAddInput = () => {
    this.setSelectedPackages()
  }

  render() {
    const {
      inputPackage,
      inputVersion,
      selectedPackages,
      errors,
    } = this.state

    return (
      <Container maxWidth="md">
        <Typography variant="body1" component="h2">
          Select Python packages to add to the Approved Repository
        </Typography>
        <Typography variant="body2" component="p">
          You can find a comprehensive list of Python packages on the
          {' '}
          <Link href="https://pypi.org/" target="_blank" rel="noreferrer">PyPI website</Link>
          .
        </Typography>
        <div style={{
          width: '100%',
          padding: '10px 10px 10px 0px',
          display: 'flex',
          alignItems: 'flex-start',
          justifyContent: 'center',
        }}
        >
          <div style={{
            flexGrow: 1, display: 'flex', flexDirection: 'column', alignItems: 'center',
          }}
          >
            <TextField
              id="searchBox-pythonPackage"
              sx={{ width: '100%', padding: '10px 10px 10px 0px' }}
              value={inputPackage}
              placeholder="Package name"
              autoFocus
              onChange={(e) => { this.setState({ inputPackage: e.target.value }) }}
              onKeyDown={(e) => e.key === 'Enter' && this.setSelectedPackages()}
              error={errors?.name}
              inputRef={(input) => { this.packageNameInput = input }}
            />
            {errors?.name && (
              <FormHelperText error>{errors.name}</FormHelperText>
            )}
          </div>
          <div style={{
            flexGrow: 1, display: 'flex', flexDirection: 'column', alignItems: 'center',
          }}
          >
            <TextField
              id="searchBox-pythonPackageVersion"
              sx={{ width: '100%', padding: '10px 10px 10px 0px' }}
              value={inputVersion}
              placeholder="Package version e.g. 1.2.3"
              onChange={(e) => { this.setState({ inputVersion: e.target.value }) }}
              onKeyDown={(e) => e.key === 'Enter' && this.setSelectedPackages()}
              error={errors?.version}
            />
            {errors?.version && (
              <FormHelperText error>{errors.version}</FormHelperText>
            )}
          </div>
          <div style={{ marginTop: '20px' }}>
            <Button variant="text" color="primary" onClick={this.handleClearInput}>
              Clear
            </Button>
            <Button
              variant="outlined"
              color="primary"
              disabled={inputPackage === '' || inputVersion === ''}
              onClick={this.handleAddInput}
            >
              Add
            </Button>
          </div>
        </div>
        <List>
          {selectedPackages.map((pkg) => (
            <Paper key={pkg.packageName} style={{ margin: '10px' }}>
              <ListItem>
                <ListItemText
                  primary={pkg.packageName}
                  secondary={`Version: ${pkg.packageVersion}`}
                />
                <ListItemSecondaryAction>
                  <IconButton
                    onClick={() => this.removePackage(pkg)}
                    edge="end"
                    aria-label="remove"
                    size="large"
                  >
                    <DeleteIcon />
                  </IconButton>
                </ListItemSecondaryAction>
              </ListItem>
            </Paper>
          ))}
        </List>
        <div style={{ textAlign: 'center', marginTop: '30px' }}>
          <Button
            type="submit"
            variant="contained"
            color="primary"
            disabled={selectedPackages.length === 0}
            onClick={this.handleSubmit}
          >
            Commit Packages
          </Button>
        </div>
      </Container>
    )
  }
}

AddPythonPackageScreen.propTypes = propTypes
export default wrap((AddPythonPackageScreen))
