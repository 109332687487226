import React from 'react';
import flow from 'lodash.flow';
import { NewProjectScreen } from './NewProjectScreen';
import asBaseScreen from '../../screenWrappers/BaseScreen';
import { withAllVmOptionsConfig, withProject, withAllOrganisations, withAllTags, withAllUsers, withAllProducts } from '../../screenWrappers/DataProviders';
import { withConfirmFeature, withSnackbarsFeature, withLoadingFeature } from '../../screenWrappers/Features';

//Screen requires the following data providers and features injected
const wrap = flow([
    withAllVmOptionsConfig,
    withProject,
    withAllTags,
    withConfirmFeature,
    withLoadingFeature,
    withSnackbarsFeature,
    withAllOrganisations,
    withAllUsers,
    withAllProducts,
    asBaseScreen
])

export default wrap(((props) =>
    <NewProjectScreen cloning={true} {...props} />));
