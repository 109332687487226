import React from 'react'
import flow from 'lodash.flow'
import {
  FormControl,
  InputLabel,
  TextField,
  Grid,
  FormHelperText,
  Button,
  Container,
} from '@mui/material'
import { Formik } from 'formik'
import * as Yup from 'yup'
import asBaseScreen from '../../screenWrappers/BaseScreen'
import { withBannerMessage } from '../../screenWrappers/DataProviders'
import { withSnackbarsFeature, withConfirmFeature } from '../../screenWrappers/Features'
import SkeletonForm from '../../components/SkeletonForm'

// Screen requires the following data providers and features injected
const wrap = flow([
  withBannerMessage,
  withConfirmFeature,
  withSnackbarsFeature,
  asBaseScreen,
])

/**
 * Using Formik to handle the form data movement
 * Using Yup to handle the form validation (works well with Formik)
 *
 * @see https://hackernoon.com/react-form-validation-with-formik-and-yup-8b76bda62e10
 *
 */
class BannerMessagesScreen extends React.Component {
  constructor(props) {
    super(props)
    this.datalabFacade = props.datalabFacade
    this.state = {
      loading: true,
      message: undefined,
    }
  }

  async componentDidUpdate(prevProps) {
    if (this.state.loading) {
      const podId = this.props.user.pod
      const bannerMessage = await this.datalabFacade.getBannerMessage(podId)
      this.setState({
        loading: false,
        message: bannerMessage.message,
      })
    }
  }

  handleSubmit = (values) => {
    const message = {
      podId: this.props.user.pod,
      message: values.messageText,

    }

    this.props.askForConfirmationListener(
      'Are you sure you want to post this message?',
      async () => {
        await this.props.datalabFacade.postBannerMessage(message)
        this.props.showSnackbarSuccess(`Post Banner Message (${message.message}) succeeded`)
        window.location.reload()
      }
    )
  }

  handleRemove = () => {
    const message = {
      message: '',
      podId: this.props.user.pod,
    }

    this.props.askForConfirmationListener(
      'Are you sure you want to remove this message?',
      async () => {
        await this.props.datalabFacade.postBannerMessage(message)
        this.props.showSnackbarSuccess('Remove Banner Message succeeded')
        window.location.reload()
      }
    )
  }

  render() {
    return (
      this.state.loading ? <SkeletonForm />
        : (
          <div className="details-grid">
            <Container maxWidth="md">

              <Formik
                validateOnChange={false}
                validateOnBlur={false}
                initialValues={{
                  messageText: this.state.message,
                }}

                onSubmit={(values) => { this.handleSubmit(values) }}

                validationSchema={Yup.object().shape({
                  messageText: Yup.string()
                    .trim().min(1).required()
                    .label('Banner Message'),
                })}
              >
                {(props) => {
                  const {
                    values,
                    touched,
                    errors,
                    handleChange,
                    handleBlur,
                    handleSubmit,
                  } = props

                  return (
                    <form onSubmit={handleSubmit}>
                      <Grid container spacing={3} alignItems="center" direction="column">

                        <Grid container className="formRow" spacing={1} alignItems="center" justifyContent="center" direction="row">
                          <Grid item xs={2}>
                  <InputLabel htmlFor="messageText">Message</InputLabel>
                </Grid>
                          <Grid item xs>
                  <FormControl fullWidth>
                                <TextField
                                    id="messageText"
                                    aria-describedby="helperMessageText"
                                    multiline
                                    onChange={handleChange}
                                    onBlur={handleBlur}
                                    placeholder="Enter message here"
                                    value={values.messageText}
                                    inputProps={{ maxLength: 400 }}
                                  />

                                <FormHelperText
                                    id="helperMessageText"
                                    error={errors.messageText && touched.messageText}
                                  >
                                    {(errors.messageText && touched.messageText) ? errors.messageText
                                        : (!values.messageText) ? ''
                                          : `${values.messageText.length}/400`}

                                  </FormHelperText>
                              </FormControl>
                </Grid>
                        </Grid>
                      </Grid>

                      <div className="action-button-container">
                        <Button
                          type="submit"
                          variant="contained"
                          color="primary"
                          disabled={this.state.message === values.messageText}
                        >
                                    Submit
                        </Button>
                      </div>

                      <div className="action-button-container">
                        <Button variant="contained" color="primary" onClick={this.handleRemove}>
                          Clear banner
                                  </Button>
                      </div>

                    </form>
                  )
                }}
              </Formik>
            </Container>
          </div>
        )
    )
  }
}

export default wrap(BannerMessagesScreen)

