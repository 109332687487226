import React from 'react';
import {
  Typography,
} from "@mui/material";
import { getRuntimeConfig } from '../util/Config';
const config = getRuntimeConfig();

/**
 * Displays a watermark in the bottom right in dev and test environments
 */
export default function EnvironmentWatermark(props) {

  const style = {
    position: 'fixed',
    bottom: '40px',
    right: '10px',
    opacity: '0.15'
  }

  return (
    <React.Fragment>
      {config.PROPERTIES_STAGE !== 'prod' &&
        <Typography variant='h1' style={style}>{config.PROPERTIES_STAGE.toUpperCase()}</Typography>
      }
    </React.Fragment>
  );
}