import React from 'react';
import {
    Table,
    TableRow,
    TableCell,
    TableBody,
    CircularProgress,
    Link
} from "@mui/material";
import format from 'date-fns/format';
import *  as Parallel from 'async-parallel';
import { useNavigate } from 'react-router-dom';
import { dateFutureDayOrTonight } from '../util/StringUtils'

/**
 * Displays a complex attribute in a row for use in View pages (View Project, View User etc.) using a table format
 * 
 */
export default function ComplexAttributeRow(props) {

    const [keyValuePairs, setKeyValuePairs] = React.useState(props.keyValuePairs);
    const [lazyHandled, setLazyHandled] = React.useState(false);
    const navigate = useNavigate();

    //If the refresh timestamp changes (from an upstream component changing it) then reload the lazy data
    React.useEffect(() => {
        if (props.refreshTimestamp || (!props.refreshTimestamp && !lazyHandled)) {
            //set all lazy values to undefined to trigger loading animations
            const resetLazyPairs = props.keyValuePairs.map(element => {
                if (element.lazy) {
                    element.value = undefined;
                }
                return element;
            });
            setKeyValuePairs(resetLazyPairs);
            setLazyHandled(true);
            loadLazyValues();
        }
        // eslint-disable-next-line react-hooks/exhaustive-deps
    }, [props.refreshTimestamp]);

    React.useEffect(() => {
        setKeyValuePairs(props.keyValuePairs);
    }, [props.keyValuePairs]);

    const loadLazyValues = async () => {
        const pairs = keyValuePairs.filter(pair => pair.lazy)
        await Parallel.each(pairs, async pair => {
            const value = await pair.lazy()
            let newKeyValuePairs = [...keyValuePairs];
            let fulfilledElement = newKeyValuePairs.find(element => element.lazyKey === pair.lazyKey);
            let elementIndex = newKeyValuePairs.findIndex(element => element.lazyKey === pair.lazyKey);
            fulfilledElement.value = value[pair.lazyKey];
            newKeyValuePairs[elementIndex] = fulfilledElement;
            setKeyValuePairs(newKeyValuePairs);
            pair.dataCallback(fulfilledElement.value);
        })
    }

    return (
        <React.Fragment>
            <Table className="attribute-table">
                <TableBody>
                    {keyValuePairs.map(element => {

                        let applyStyle;
                        let value = element.value;

                        if (element.boolean) {
                            element.value ?
                                value = "YES"
                                :
                                value = "NO"
                        }

                        if (element.status) {
                            if (value === 'OPEN' || value === 'ACTIVE' || value === 'READY' || value === 'RUNNING' || value === 'YES') {
                                applyStyle = 'status-cell-green';
                            } else if (value === 'BUILDING' || value === 'DEALLOCATING' || value === 'STOPPING' || value === 'STARTING' || value === 'RESTARTING') {
                                applyStyle = 'status-cell-building';
                            } else if (value === 'DISABLED' || value === 'ERROR') {
                                applyStyle = 'status-cell-red';
                            } else if (value === 'DORMANT') {
                                applyStyle = 'status-cell-dormant';
                            } else {
                                applyStyle = 'status-cell-grey';
                            }
                        }


                        if (element.currency) {
                            if (!value) { value = 0 }
                            var formatter = new Intl.NumberFormat('en-US', {
                                style: 'currency',
                                currency: 'AUD',
                            });
                            value = formatter.format(value);
                        }

                        if (element.date && element.value) {
                            value = format(new Date(value), 'dd/MM/yyyy')
                        }

                        if (element.dateFutureDayOrTonight && element.value !== undefined) {
                            value = dateFutureDayOrTonight(new Date(value))
                        }

                        if (element.dateTime && element.value) {
                            value = format(new Date(value), 'dd/MM/yyyy hh:mm a');
                        }

                        return (

                            <TableRow key={element.header} style={{ height: '40px' }}>
                                <TableCell style={{ width: '20%' }} className="table-header" component="th" scope="row">
                                    {element.header}
                                </TableCell>
                                <TableCell style={{ width: '100%' }} className={applyStyle}>
                                    {(element.lazy && element.value === undefined) ?
                                        <CircularProgress size="24px" />
                                        :
                                        element.linkTo ?
                                            <Link href="#" onClick={() => navigate(`/${element.linkTo}/${element.value}`)}>{value}</Link>
                                            :
                                            <p>{value}</p>

                                    }
                                </TableCell>
                            </TableRow>
                        )
                    })}
                </TableBody>
            </Table>
        </React.Fragment>
    );
}