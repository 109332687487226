import React from 'react';
import { PieChart, Pie, Tooltip, Cell, ResponsiveContainer, Label } from 'recharts';
import Box from '@mui/material/Box';
import { TableBody, TableHead, Typography } from '@mui/material';
import TableCell from '@mui/material/TableCell';
import TableContainer from '@mui/material/TableContainer';
import TableRow from '@mui/material/TableRow';
import Table from '@mui/material/Table';

const colours = ["#8884D8", "#D8B284", "#AAD884", "#84D4D8", "#F2CBE9", "#D9DB8B", "#CF7979", "#6DA0FF"]

const CustomTooltipPie = ({ active, payload }) => {
  if (active && payload && payload.length) {
    return (
      <div className="customTooltip">
        <p className="label">{`${payload[0].payload.podId}`}</p>
        <p className="desc">{`Active Licenses: ${payload[0].value}`}</p>
      </div>
    );
  }

  return null;
};

const noData = [{ podId: "A", value: 1 }]

const SasGraphs = (props) => {
  const activeVms = props.podSasData.every(item => item.count === 0)

  if (activeVms !== true) {
    return (
      <Box
        marginBottom={"5px"}
        display={"flex"}
        flexDirection={"column"}
        alignItems={"center"}
      >
        <Typography variant='h6' marginTop={5}>Current Active SAS Licenses</Typography>
        <div className='current-graph-container'>
          <ResponsiveContainer width={'99%'} height='99%'>
            <PieChart width={400} height={400}  >
              <Pie
                dataKey="count"
                nameKey="podId"
                isAnimationActive={true}
                data={props.podSasData}
                cx="50%"
                cy="50%"
                outerRadius={150}
                fill="#8884d8"
                label={({ cx, cy, midAngle, innerRadius, outerRadius, percent, index }) => {
                  const RADIAN = Math.PI / 180;
                  const radius = innerRadius + (outerRadius - innerRadius) * 0.5;
                  const x = cx + radius * Math.cos(-midAngle * RADIAN);
                  const y = cy + radius * Math.sin(-midAngle * RADIAN);
                  if (props.podSasData[index].count !== 0) {
                    return (
                      <text
                        x={x}
                        y={y}
                        fontSize={16}
                        fill="white"
                        textAnchor={x > cx ? "start" : "end"}
                        dominantBaseline="central"
                      >
                        {`${props.podSasData[index].podId} ${(percent * 100).toFixed(0)}%`}
                      </text>
                    );
                  }
                }
                }
                labelLine={false}
              >
                {props.podSasData.map((entry, index) =>
                  <Cell key={`cell-${index}`} fill={colours[index % colours.length]} />
                )}
              </Pie>
              <Tooltip content={<CustomTooltipPie />} />
            </PieChart>
          </ResponsiveContainer>
        </div>
        <Typography variant="body1" >
          {`${props.sasData[0].activeLicenses}/${props.sasData[0].maxLicenses} licenses in use`}
        </Typography>
        <Typography variant="subtitle1" marginTop={2}>
          {"Data generated " + new Date().toLocaleString()}
        </Typography>
        <TableContainer>
          <Table aria-label="current-sas-licenses">
            <TableHead>
            <TableRow>
              <TableCell align="left">Software Name</TableCell>
              <TableCell align="center">Licenses in Use</TableCell>
            </TableRow>
            </TableHead>
            <TableBody>
              <TableRow>
              <TableCell align="left">{props.sasData[0].softwareName}</TableCell>
              <TableCell align="left"></TableCell>
              </TableRow>
              <TableRow>
              <TableCell align="center"> Total </TableCell>
              <TableCell align="center"> {props.sasData[0].activeLicenses}</TableCell>
              </TableRow>
              {props.podSasData.map((row) => (
                <TableRow key={row.podId}>
                  <TableCell align="center">{row.podId}</TableCell>
                  <TableCell align="center">{row.count}</TableCell>
                </TableRow>
              ))}
            </TableBody>
          </Table>
        </TableContainer>
      </Box>
    );
  } else {
    return (
      <Box
        marginBottom={"5px"}
        display={"flex"}
        flexDirection={"column"}
        alignItems={"center"}
      >
        <Typography variant='h6'>Current Active SAS Licenses</Typography>
        <div className='current-graph-container'>
          <ResponsiveContainer width={'99%'} height="100%">
            <PieChart width={400} height={400}>
              <Pie cx="50%" data={noData} cy="50%" innerRadius={120} outerRadius={150} fill="#8884d8" labelLine={false} >
                <Label value="No Active SAS Licenses" position="center" />
              </Pie>
            </PieChart>
          </ResponsiveContainer>
        </div>
        <Typography variant="subtitle1" >
          {`${props.sasData[0].activeLicenses}/${props.sasData[0].maxLicenses} licenses in use`}
        </Typography>
        <Typography variant="subtitle1" marginTop={2}>
          {"Data generated " + new Date().toLocaleString()}
        </Typography>
      </Box>
    )
  }
}
export default SasGraphs