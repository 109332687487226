import React from 'react'
import { styled } from '@mui/material/styles'
import flow from 'lodash.flow'
import {
  Grid,
  Typography,
  Paper,
  Button,
} from '@mui/material'
import format from 'date-fns/format'
import asBaseScreen from '../../screenWrappers/BaseScreen'
import { withUser } from '../../screenWrappers/DataProviders'
import ComplexAttributeRow from '../../components/ComplexAttributeRow.js'
import SkeletonArrayRow from '../../components/SkeletonArrayRow'
import { withRouter } from '../../screenWrappers/withRouter'

const SectionHeader = styled('div')({
  padding: '10px',
  '& h3': {
    display: 'inline',
    margin: '5px',
  },
})

const UnscrollablePaper = styled(Paper)({
  margin: '20px',
  minHeight: '50vh',
  backgroundColor: '#fafafa',
})

// Screen requires the following data providers and features injected
const wrap = flow([
  withRouter,
  withUser,
  asBaseScreen,
])

/**
 * Displays user account(analyst) details in simple list view
 */
class MyAccountScreen extends React.Component {
  constructor(props) {
    super(props)
    this.datalabFacade = props.datalabFacade
  }

  handleEditBtnClick(event) {
    this.props.navigate('settings')
  }

  render() {
    return (
      <Grid container spacing={3} direction="row" alignItems="stretch">
        <Grid item xs={6}>
          {!this.props.userData ? <SkeletonArrayRow />
            : (
              <UnscrollablePaper variant="outlined">
                <SectionHeader>
                  <Typography variant="h6" component="h3">
                    Basic Attributes
                  </Typography>
                </SectionHeader>
                <ComplexAttributeRow keyValuePairs={[
                  { header: 'Username', value: this.props.userData.userName },
                  { header: 'Name', value: this.props.userData.displayName },
                  { header: 'Email', value: this.props.userData.contactEmail },
                  { header: 'Phone', value: this.props.userData.phone },
                  { header: 'Date Registered', value: format(new Date(this.props.userData.registeredDate), 'dd MMM yyyy') },
                  { header: 'Status', value: this.props.userData.status },
                ]}
                />
              </UnscrollablePaper>
            )}
        </Grid>
        <Grid item xs={6}>
          {!this.props.userData ? <SkeletonArrayRow />
            : (
              <UnscrollablePaper variant="outlined">
                <SectionHeader>
                  <Typography variant="h6" component="h3">
                    Account Settings
                  </Typography>
                  <div className="sub-action-button-container">
                    <Button variant="outlined" color="primary" onClick={this.handleEditBtnClick.bind(this)}>
                      Edit
                    </Button>
                  </div>
                </SectionHeader>
                <ComplexAttributeRow keyValuePairs={[
                  { header: 'Opt out of reminder emails', value: this.props.userData.emailOptOut, boolean: true },
                ]}
                />
              </UnscrollablePaper>
            )}
        </Grid>
      </Grid>
    )
  }
}

export default wrap((MyAccountScreen))
