"use strict";
Object.defineProperty(exports, "__esModule", { value: true });
exports.updateProductLinksDtoSchema = exports.linkProjectToProductsDtoSchema = void 0;
const zod_1 = require("zod");
const base_1 = require("../base");
exports.linkProjectToProductsDtoSchema = zod_1.z.object({
    projectId: zod_1.z.string(),
    productNames: zod_1.z.array(zod_1.z.string()),
});
exports.updateProductLinksDtoSchema = base_1.asyncOperationDtoSchema.extend({
    productName: zod_1.z.string(),
    projectIds: zod_1.z.object({
        add: zod_1.z.array(zod_1.z.string()),
        remove: zod_1.z.array(zod_1.z.string()),
    }),
});
