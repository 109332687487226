"use strict";
Object.defineProperty(exports, "__esModule", { value: true });
exports.productDtoSchema = void 0;
const zod_1 = require("zod");
const tag_1 = require("../tag");
/** Database fields set by user input that can be queried via the api */
const productInputFields = zod_1.z.object({
    /** The short name of the product */
    name: zod_1.z.string()
        .min(3)
        .max(50)
        .trim()
        .regex(/^(?=.{3,63}$)[a-z0-9]+(-[a-z0-9]+)*$/),
    displayName: zod_1.z.string(),
    /** Tag instances to associate with the project */
    tags: zod_1.z.array(tag_1.tagInstanceDtoSchema)
        .default([])
        .optional(),
});
/** Generated database fields that can be queried via the api */
const productGeneratedFields = zod_1.z.object({});
/** All database fields that are exposed via API */
const productDtoSchema = productInputFields.merge(productGeneratedFields);
exports.productDtoSchema = productDtoSchema;
