/* eslint-disable react/jsx-props-no-spreading */
import React from 'react'
import PropTypes from 'prop-types'
import {
  FormControl,
  InputLabel,
  TextField,
  Grid,
  FormHelperText,
  Select,
  MenuItem,
  Button,
  IconButton,
  Container,
  Slider,
  Paper,
  Typography,
  Alert,
  Chip,
} from '@mui/material'
import { DatePicker } from '@mui/x-date-pickers/DatePicker'
import { Formik } from 'formik'
import * as Yup from 'yup'
import Autocomplete from '@mui/material/Autocomplete'
import HelpIcon from '@mui/icons-material/Help'
import { styled } from '@mui/material/styles'
import flow from 'lodash.flow'
import DatalabFacade from '../dataService/DatalabFacade'
import { withLoadingFeature } from '../screenWrappers/Features'
import {
  vmSizeOptionsShape,
  selectableUsersProject,
  selectableTagsProject,
  ProjectValues,
} from '../propTypeShapes'
import HelpDialogListed from './HelpDialogListed'

// Screen requires the following data providers and features injected
const wrap = flow([
  withLoadingFeature,
])

const storageSizeSliderIntervals = [
  {
    value: 1,
    label: '1TB',
  },
  {
    value: 5,
    label: '5TB',
  },
  {
    value: 10,
    label: '10TB',
  },
]

const StyledChip = styled(Chip)(({ theme }) => ({
  margin: theme.spacing(0.5, 0.25),
}))

const propTypes = {
  // eslint-disable-next-line react/forbid-prop-types
  formRef: PropTypes.object.isRequired,
  updating: PropTypes.bool,
  vmSizeOptions: PropTypes.arrayOf(vmSizeOptionsShape).isRequired,
  initialValues: ProjectValues.isRequired,
  selectableUsers: selectableUsersProject.isRequired,
  selectableOrganisations: PropTypes.arrayOf(PropTypes.string).isRequired,
  selectableTags: selectableTagsProject.isRequired,
  startLoading: PropTypes.func.isRequired,
  stopLoading: PropTypes.func.isRequired,
  datalabFacade: PropTypes.instanceOf(DatalabFacade).isRequired,
  podId: PropTypes.string.isRequired,
  values: ProjectValues,
  handleChange: PropTypes.func,
  handleBlur: PropTypes.func,
  handleSubmit: PropTypes.func.isRequired,
  setFieldValue: PropTypes.func,
  errors: ProjectValues,
  touched: ProjectValues,
}

const defaultProps = {
  values: undefined,
  updating: undefined,
  errors: undefined,
  touched: undefined,
  setFieldValue: undefined,
  handleBlur: undefined,
  handleChange: undefined,
}

function ProjectDetailsForm({
  formRef,
  updating,
  vmSizeOptions,
  initialValues,
  selectableUsers,
  selectableOrganisations,
  selectableTags,
  startLoading,
  stopLoading,
  datalabFacade,
  podId,
  handleSubmit,
}) {
  const [showingVmSizeHelp, setShowingVmSizeHelp] = React.useState(false)
  const [selectedTagKey, setSelectedTagKey] = React.useState(null)
  const [selectedTagValue, setSelectedTagValue] = React.useState(null)
  const [selectableTagValues, setSelectableTagValues] = React.useState([])
  const prefix = podId === 'ABS' ? '' : podId.substring(0, 3).toLowerCase()

  const renderResearcherOptionText = (option) => {
    let text = `${option.displayName} (${option.userName})`
    if (option.organisation) {
      text = `${text} (${option.organisation})`
    }
    return text
  }

  const setResearcherFieldValue = (value, setFieldValue) => {
    setFieldValue('projectContact', value.displayName)
    setFieldValue('contactEmail', value.contactEmail)
    setFieldValue('contactPhone', value.phone)
  }

  let projectUuid = null
  /**
   * If updating, skip validation check on projectUuid since users can't edit this field
   * If not updating, i.e. creating project, do validation
   */
  if (!updating) {
    projectUuid = Yup.string()
    if (podId !== 'ABS') {
      // Given we have a 3 char prefix, the projectId value has to be at least 1 - 5 chars long.
      projectUuid = Yup.string()
        .matches(
          /^[a-z0-9]{1,5}$/,
          'Project ID does not match the required format.'
          + ' Project IDs should be between 4 and 8 characters long (including prefix)'
          + ' and only contain numbers and lowercase letters.'
        )
        .test('isUnique', 'A Project already exists with this ID, please enter a unique ID', (name) => {
          if (!name) return true
          return new Promise((resolve) => {
            startLoading()
            datalabFacade.isProjectidUnique(prefix + name).then((result) => {
              stopLoading()
              resolve(result.isUnique)
            }).catch((error) => {
              throw error
            })
          })
        }).required()
        .label('Project ID')
    } else {
      projectUuid = Yup.string()
        .matches(
          /^[a-z0-9]{3,8}$/,
          'Project ID does not match the required format.'
          + ' Project IDs should be between 3 and 8 characters long'
          + ' and only contain numbers and lowercase letters.'
        )
        .test('isUnique', 'A Project already exists with this ID, please enter a unique ID', (name) => {
          if (!name) return true
          return new Promise((resolve) => {
            startLoading()
            datalabFacade.isProjectidUnique(name).then((result) => {
              stopLoading()
              resolve(result.isUnique)
            }).catch((error) => {
              throw error
            })
          })
        }).required()
        .label('Project ID')
    }
  }

  let endDate
  if (updating) {
    endDate = Yup.date().required().label('End date')
  } else {
    endDate = Yup.date().min(new Date()).required().label('End date')
  }

  return (
    <Container maxWidth="md">
      {showingVmSizeHelp
            && (
            <HelpDialogListed
              open={showingVmSizeHelp}
              callback={() => setShowingVmSizeHelp(false)}
              title="Available VM size options to set as default for this Project"
              headers={
                    [
                      {
                        formatted: 'Type',
                        key: 'displayName',
                      }, {
                        formatted: 'Description',
                        key: 'description',
                      },
                    ]
                }
              rows={vmSizeOptions}
              rowKey="type"
            />
            )}

      <Formik
        innerRef={formRef}
        validateOnChange={false}
        validateOnBlur={false}
        initialValues={initialValues}
        onSubmit={(values) => {
          handleSubmit(values)
        }}
        validationSchema={Yup.object().shape({
          uuid: projectUuid,
          projectName: Yup.string().trim().min(3).max(100)
            .required()
            .label('Project name'),
          projectStorageSize: Yup.number().required().label('Project storage size'),
          projectStorageSizeDatalake: Yup.number().required().label('Data Lake storage size'),
          description: Yup.string().trim().required().label('Description'),
          projectContact: Yup.string().trim().max(50).required()
            .label('Lead researcher name'),
          contactEmail: Yup.string().email().required().label('Lead researcher email'),
          contactPhone: Yup.string()
            .trim().matches(/^([0-9 +]*)$/, 'Only numbers, spaces, and (+) allowed').min(8)
            .max(16)
            .required()
            .label('Lead researcher phone'),
          endDate,
          defaultVMsize: Yup.string().trim().required().label('Default VM size'),
          organisation: Yup.string().min(2).trim().required()
            .label('Lead researcher Organisation'),
        })}
      >
        {(props) => {
          const {
            values,
            touched,
            errors,
            handleChange,
            handleBlur,
            setFieldValue,
          } = props
          return (
            <form onSubmit={handleSubmit}>
              <div>
                {errors && Object.keys(errors).length > 0 && (
                <Alert severity="error">
                  Validation failed. Required information is missing or invalid
                </Alert>
                )}
                <p className="mandatory-info">All fields are required</p>

                <Grid container spacing={3} alignItems="center" direction="column">

                  <Grid
                    container
                    className="formRow"
                    spacing={1}
                    alignItems="center"
                    justify="center"
                    direction="row"
                  >
                    <Grid item xs>
                      <Typography variant="h6" component="h2">
                        Project details
                      </Typography>
                    </Grid>
                  </Grid>
                </Grid>
                <Grid
                  container
                  className="formRow"
                  spacing={1}
                  alignItems="center"
                  justify="center"
                  direction="row"
                >
                  <Grid item xs={2}>
                    <InputLabel htmlFor="uuid">Project ID</InputLabel>
                  </Grid>
                  { (podId !== 'ABS' && !updating)
                                && (
                                <Grid item xs={1}>
                                  <InputLabel>{prefix}</InputLabel>
                                </Grid>
                                )}
                  { podId !== 'ABS' ? (
                    <Grid item xs>
                      <FormControl fullWidth>
                        <TextField
                          id="uuid"
                          aria-describedby="helperUUID"
                          onChange={handleChange}
                          onBlur={handleBlur}
                          value={values.uuid}
                          placeholder="12345"
                          disabled={updating}
                        />
                        <FormHelperText id="helperUUID" error={errors.uuid && touched.uuid}>
                          {errors.uuid && touched.uuid ? errors.uuid : 'The ID of this Project'}
                        </FormHelperText>
                      </FormControl>
                    </Grid>
                  )
                    : (
                      <Grid item xs>
                        <FormControl fullWidth>
                          <TextField
                            id="uuid"
                            aria-describedby="helperUUID"
                            onChange={handleChange}
                            onBlur={handleBlur}
                            value={values.uuid}
                            placeholder="1234z567"
                            disabled={updating}
                          />
                          <FormHelperText id="helperUUID" error={errors.uuid && touched.uuid}>
                            {errors.uuid && touched.uuid ? errors.uuid : 'The ID of this Project'}
                          </FormHelperText>
                        </FormControl>
                      </Grid>
                    )}
                </Grid>
                <Grid
                  container
                  className="formRow"
                  spacing={1}
                  alignItems="center"
                  justify="center"
                  direction="row"
                >
                  <Grid item xs={2}>
                    <InputLabel htmlFor="projectName">Project name</InputLabel>
                  </Grid>
                  <Grid item xs>
                    <FormControl fullWidth>
                      <TextField
                        id="projectName"
                        aria-describedby="helperProjectName"
                        onChange={handleChange}
                        onBlur={handleBlur}
                        value={values.projectName}
                      />
                      <FormHelperText
                        id="helperProjectName"
                        error={errors.projectName && touched.projectName}
                      >
                        {errors.projectName && touched.projectName
                          ? errors.projectName : 'The name of this Project'}
                      </FormHelperText>
                    </FormControl>
                  </Grid>
                </Grid>
                <Grid
                  container
                  className="formRow"
                  spacing={1}
                  alignItems="center"
                  justify="center"
                  direction="row"
                >
                  <Grid item xs={3}>
                    <InputLabel htmlFor="projectStorageSize">File Share storage size </InputLabel>
                  </Grid>
                  <Grid item xs>
                    <FormControl fullWidth>

                      <Slider
                        id="projectStorageSize"
                        defaultValue={1}
                        aria-describedby="helperProjectStorageSize"
                        step={1}
                        min={1}
                        max={10}
                        marks={storageSizeSliderIntervals}
                        style={{
                          marginTop: 7,
                          marginLeft: 7,
                        }}
                        valueLabelDisplay="on"
                        aria-labelledby="discrete-slider-restrict"
                        onChange={(event, value) => setFieldValue('projectStorageSize', value || '')}
                        onBlur={handleBlur}
                        value={values.projectStorageSize}
                      />

                      <FormHelperText
                        id="helperProjectStorageSize"
                        error={errors.projectStorageSize && touched.projectStorageSize}
                      >
                        {errors.projectStorageSize && touched.projectStorageSize
                          ? errors.projectStorageSize : 'The project storage size (TiB)'}
                      </FormHelperText>
                    </FormControl>
                  </Grid>
                </Grid>
                <Grid
                  container
                  className="formRow"
                  spacing={1}
                  alignItems="center"
                  justify="center"
                  direction="row"
                >
                  <Grid item xs={3}>
                    <InputLabel htmlFor="projectStorageSizeDatalake">Data Lake storage size </InputLabel>
                  </Grid>
                  <Grid item xs>
                    <FormControl fullWidth>

                      <Slider
                        id="projectStorageSizeDatalake"
                        defaultValue={1}
                        aria-describedby="helperProjectStorageSizeDatalake"
                        step={1}
                        min={1}
                        max={10}
                        marks={storageSizeSliderIntervals}
                        style={{
                          marginTop: 7,
                          marginLeft: 7,
                        }}
                        valueLabelDisplay="on"
                        aria-labelledby="discrete-slider-restrict"
                        onChange={(event, value) => setFieldValue('projectStorageSizeDatalake', value || '')}
                        onBlur={handleBlur}
                        value={values.projectStorageSizeDatalake}
                      />

                      <FormHelperText
                        id="helperProjectStorageSizeDatalake"
                        error={errors.projectStorageSizeDatalake && touched.projectStorageSizeDatalake}
                      >
                        {errors.projectStorageSizeDatalake
                        && touched.projectStorageSizeDatalake ? errors.projectStorageSizeDatalake
                          : "The storage quota (TiB) for the project's working storage "
                           + "Azure Data Lake Gen2 storage container ('datalake' container)"}
                      </FormHelperText>
                    </FormControl>
                  </Grid>
                </Grid>
                <Grid
                  container
                  className="formRow"
                  spacing={1}
                  alignItems="center"
                  justify="center"
                  direction="row"
                >
                  <Grid item xs={2}>
                    <InputLabel htmlFor="organisation">Organisation</InputLabel>
                  </Grid>
                  <Grid item xs>
                    <FormControl fullWidth>
                      <Autocomplete
                        id="organisation"
                        options={selectableOrganisations}
                        getOptionLabel={(option) => option}
                        variant="outlined"
                        onChange={(event, value) => setFieldValue('organisation', value || '')}
                        value={values.organisation}
                        renderInput={(params) => (
                          <TextField
                            {...params}
                            variant="outlined"
                            fullWidth
                          />
                        )}
                      />
                      <FormHelperText
                        id="helperOrganisation"
                        error={errors.organisation && touched.organisation}
                      >
                        {errors.organisation && touched.organisation
                          ? errors.organisation : 'The Project organisation '}
                      </FormHelperText>
                    </FormControl>
                  </Grid>
                </Grid>
                <Grid
                  container
                  className="formRow"
                  spacing={1}
                  alignItems="center"
                  justify="center"
                  direction="row"
                >
                  <Grid item xs={2}>
                    <InputLabel htmlFor="description">Description</InputLabel>
                  </Grid>
                  <Grid item xs>
                    <FormControl fullWidth>
                      <TextField
                        multiline
                        id="description"
                        aria-describedby="helperDescription"
                        onChange={handleChange}
                        onBlur={handleBlur}
                        value={values.description}
                      />
                      <FormHelperText
                        id="helperDescription"
                        error={errors.description && touched.description}
                      >
                        {errors.description && touched.description
                          ? errors.description : 'A short description of this Project'}
                      </FormHelperText>
                    </FormControl>
                  </Grid>
                </Grid>
                <Grid
                  container
                  className="formRow"
                  spacing={1}
                  alignItems="center"
                  justify="center"
                  direction="row"
                >
                  <Grid item xs={2}>
                    <InputLabel htmlFor="endDate">End date</InputLabel>
                  </Grid>
                  <Grid item xs>
                    <FormControl fullWidth>
                      <DatePicker
                        disableToolbar
                        variant="inline"
                        inputFormat="dd/MM/yyyy"
                        margin="normal"
                        disablePast
                        id="endDate"
                        value={values.endDate}
                        onChange={(e) => {
                          setFieldValue('endDate', e)
                        }}
                        onBlur={handleBlur}
                        renderInput={(params) => <TextField {...params} />}
                        KeyboardButtonProps={{
                          'aria-label': 'change date',
                        }}
                      />
                      <FormHelperText id="helperEndDate" error={errors.endDate && touched.endDate}>
                        {errors.endDate && touched.endDate
                          ? errors.endDate : 'The date this project is intended to end'}
                      </FormHelperText>
                    </FormControl>
                  </Grid>
                </Grid>
                <Grid
                  container
                  className="formRow"
                  spacing={1}
                  alignItems="center"
                  justify="center"
                  direction="row"
                >
                  <Grid item xs={2}>
                    <InputLabel htmlFor="defaultVMsize">Default VM size</InputLabel>
                  </Grid>
                  <Grid item xs>
                    <FormControl fullWidth>
                      <Select
                        id="defaultVMsize"
                        value={values.defaultVMsize}
                        onChange={(e) => setFieldValue(
                          'defaultVMsize',
                          vmSizeOptions.find((vmSizeOption) => vmSizeOption.type === e.target.value).type
                        )}
                        onBlur={handleBlur}
                        aria-describedby="helperDSVMSize"
                      >
                        {vmSizeOptions.map((vmSize) => (
                          <MenuItem key={vmSize.type} value={vmSize.type}>
                            {vmSize.displayName}
                            {' '}
                          </MenuItem>
                        ))}
                      </Select>
                      <FormHelperText
                        id="helperDSVMSize"
                        error={errors.defaultVMsize && touched.defaultVMsize}
                      >
                        {errors.defaultVMsize && touched.defaultVMsize
                          ? errors.defaultVMsize : 'The default VM size for this Project'}
                      </FormHelperText>
                    </FormControl>
                  </Grid>
                  <Grid item xs>
                    <IconButton onClick={() => setShowingVmSizeHelp(true)} aria-label="delete">
                      <HelpIcon />
                    </IconButton>
                  </Grid>
                </Grid>
                <Grid container spacing={3} alignItems="center" direction="column">
                  <Grid
                    container
                    className="formRow"
                    spacing={1}
                    alignItems="center"
                    justify="center"
                    direction="row"
                  >
                    <Grid item xs>
                      <Typography variant="h6" component="h2">
                        Lead researcher contact details
                      </Typography>
                    </Grid>
                  </Grid>
                </Grid>
                <Grid
                  container
                  className="formRow"
                  spacing={1}
                  alignItems="center"
                  justify="center"
                  direction="row"
                >
                  <Grid item xs={2}>
                    <InputLabel htmlFor="autoSelLeadResearcher">Use Existing User</InputLabel>
                  </Grid>
                  <Grid item xs>
                    <Autocomplete
                      id="leadResearcher"
                      options={selectableUsers}
                      getOptionLabel={(option) => renderResearcherOptionText(option)}
                      onChange={(event, value) => setResearcherFieldValue(
                        value,
                        setFieldValue
                      )}
                      renderInput={(params) => (
                        <TextField
                          {...params}
                          variant="outlined"
                          fullWidth
                          inputProps={
                            params.inputProps
                          }
                        />
                      )}
                    />
                    <FormHelperText id="helperResearcherQuickSel">
                      Optionally, use details from an existing user
                    </FormHelperText>
                  </Grid>
                </Grid>
                <Grid
                  container
                  className="formRow"
                  spacing={1}
                  alignItems="center"
                  justify="center"
                  direction="row"
                >
                  <Grid item xs={2}>
                    <InputLabel htmlFor="projectContact">Name</InputLabel>
                  </Grid>
                  <Grid item xs>
                    <FormControl fullWidth>
                      <TextField
                        id="projectContact"
                        aria-describedby="helperProjectContact"
                        onChange={handleChange}
                        onBlur={handleBlur}
                        value={values.projectContact}
                      />
                      <FormHelperText
                        id="helperProjectContact"
                        error={errors.projectContact && touched.projectContact}
                      >
                        {errors.projectContact && touched.projectContact
                          ? errors.projectContact : 'The name of the lead researcher for this Project'}
                      </FormHelperText>
                    </FormControl>
                  </Grid>
                </Grid>
                <Grid
                  container
                  className="formRow"
                  spacing={1}
                  alignItems="center"
                  justify="center"
                  direction="row"
                >
                  <Grid item xs={2}>
                    <InputLabel htmlFor="contactEmail">Email</InputLabel>
                  </Grid>
                  <Grid item xs>
                    <FormControl fullWidth>
                      <TextField
                        id="contactEmail"
                        aria-describedby="helperContactEmail"
                        type="email"
                        autoComplete="email"
                        onChange={handleChange}
                        onBlur={handleBlur}
                        value={values.contactEmail}
                      />
                      <FormHelperText
                        id="helperContactEmail"
                        error={errors.contactEmail && touched.contactEmail}
                      >
                        {errors.contactEmail && touched.contactEmail
                          ? errors.contactEmail : 'The email address of the lead researcher'}
                      </FormHelperText>
                    </FormControl>
                  </Grid>
                </Grid>
                <Grid
                  container
                  className="formRow"
                  spacing={1}
                  alignItems="center"
                  justify="center"
                  direction="row"
                >
                  <Grid item xs={2}>
                    <InputLabel htmlFor="contactPhone">Phone</InputLabel>
                  </Grid>
                  <Grid item xs>
                    <FormControl fullWidth>
                      <TextField
                        id="contactPhone"
                        aria-describedby="helperContactPhone"
                        type="phone"
                        autoComplete="phone"
                        onChange={handleChange}
                        onBlur={handleBlur}
                        value={values.contactPhone}
                      />
                      <FormHelperText
                        id="helperContactPhone"
                        error={errors.contactPhone && touched.contactPhone}
                      >
                        {errors.contactPhone && touched.contactPhone
                          ? errors.contactPhone : 'The phone number of the lead researcher'}
                      </FormHelperText>
                    </FormControl>
                  </Grid>
                </Grid>
                <Grid container spacing={3} alignItems="center" direction="column">
                  <Grid
                    container
                    className="formRow"
                    spacing={1}
                    alignItems="center"
                    justify="center"
                    direction="row"
                  >
                    <Grid item xs>
                      <Typography variant="h6" component="h2">
                        Tags
                      </Typography>
                    </Grid>
                  </Grid>
                </Grid>
                <Grid
                  container
                  className="formRow"
                  spacing={1}
                  alignItems="center"
                  justify="center"
                  direction="row"
                >
                  <Grid
                    container
                    className="formRow"
                    spacing={1}
                    alignItems="center"
                    justify="center"
                    direction="row"
                  >
                    <Grid item xs={2}>
                      <InputLabel htmlFor="selectedTagKey">Select Tag</InputLabel>
                    </Grid>
                    <Grid item xs>
                      <FormControl fullWidth>
                        <Autocomplete
                          id="selectedTagKey"
                          blurOnSelect
                          options={selectableTags.map((t) => t.tagKey)}
                          value={selectedTagKey}
                          renderInput={(params) => (
                            <TextField
                              {...params}
                              label="Tag Name"
                              variant="outlined"
                              fullWidth
                            />
                          )}
                          onChange={(event, value) => {
                            if (value) {
                              setSelectedTagKey(value)
                              setSelectedTagValue(null)
                              setSelectableTagValues(selectableTags.find((t) => t.tagKey === value).tagValues
                                .filter((t) => (
                                  !values.tags.some((tag) => tag.tagKey === value && tag.tagValue === t))))
                            } else {
                              setSelectedTagKey(null)
                              setSelectedTagValue(null)
                              setSelectableTagValues([])
                            }
                          }}
                        />
                      </FormControl>
                    </Grid>
                    <Grid item xs={2}>
                      <InputLabel htmlFor="selectedTagValue">Select Tag Value</InputLabel>
                    </Grid>
                    <Grid item xs>
                      <FormControl fullWidth>
                        <Autocomplete
                          id="selectedTagValue"
                          blurOnSelect
                          disabled={!selectedTagKey}
                          options={selectableTagValues}
                          value={selectedTagValue}
                          renderInput={(params) => (
                            <TextField {...params} label="Tag Value" variant="outlined" fullWidth />)}
                          onChange={(event, value) => {
                            setSelectedTagValue(value)
                          }}
                        />
                      </FormControl>
                    </Grid>
                    <Grid item xs>
                      <Button
                        variant="outlined"
                        disabled={!selectedTagKey || !selectedTagValue}
                        onClick={() => {
                          values.tags.push({
                            tagKey: selectedTagKey,
                            tagValue: selectedTagValue,
                          })
                          setSelectedTagKey(null)
                          setSelectedTagValue(null)
                          setSelectableTagValues([])
                        }}
                      >
                        Add
                      </Button>
                    </Grid>
                  </Grid>
                  <Grid
                    container
                    className="formRow"
                    spacing={1}
                    alignItems="center"
                    justify="center"
                    direction="row"
                  >
                    <Grid item xs={2}>
                      <InputLabel htmlFor="selectedTags">Tags Added:</InputLabel>
                    </Grid>
                    <Grid item xs>
                      <Paper variant="outlined" id="selectedTags">
                        {values.tags.map((tag) => (
                          <StyledChip
                            key={`${tag.tagKey}_${tag.tagValue}`}
                            label={`${tag.tagKey}: ${tag.tagValue}`}
                            onDelete={() => {
                              values.tags.splice(values.tags.indexOf(tag), 1)
                              setSelectedTagKey(null)
                              setSelectedTagValue(null)
                              setSelectableTagValues([])
                            }}
                          />
                        ))}
                      </Paper>
                    </Grid>
                  </Grid>
                </Grid>
              </div>
            </form>
          )
        }}
      </Formik>
    </Container>
  )
}
ProjectDetailsForm.propTypes = propTypes
ProjectDetailsForm.defaultProps = defaultProps
export default wrap(ProjectDetailsForm)
