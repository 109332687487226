import React, { useCallback } from 'react'
import Button from '@mui/material/Button'
import Dialog from '@mui/material/Dialog'
import DialogActions from '@mui/material/DialogActions'
import DialogContent from '@mui/material/DialogContent'
import DialogTitle from '@mui/material/DialogTitle'
import Typography from '@mui/material/Typography'
import PropTypes from 'prop-types'

const propTypes = {
  open: PropTypes.bool.isRequired,
  callback: PropTypes.func.isRequired,
  hideButtons: PropTypes.boolean,
  title: PropTypes.string.isRequired,
  message: PropTypes.string.isRequired,
}

const defaultProps = {
  hideButtons: false,
}

/**
 * A dialog that display information in a list
 * @param {object} props - The component props
 * @returns {Element} - Rendered component
 */
export default function HelpDialog(props) {
  const {
    open, callback, hideButtons, title, message,
  } = props
  const [isOpen] = React.useState(open)

  const handleYes = useCallback(() => {
    callback(true)
  }, [])

  /**
   *
   */

  return (
    <div>
      <Dialog
        open={isOpen}
        aria-labelledby="alert-dialog-title"
        aria-describedby="alert-dialog-description"
      >
        <DialogTitle id="alert-dialog-title">{title}</DialogTitle>
        <DialogContent>
          <Typography variant="caption" component="p">
            {message}
          </Typography>
        </DialogContent>
        {!hideButtons
          && (
          <DialogActions>
            <Button onClick={handleYes} color="primary" autoFocus>
              OK
            </Button>
          </DialogActions>
          )}
      </Dialog>
    </div>
  )
}

HelpDialog.propTypes = propTypes
HelpDialog.defaultProps = defaultProps
