export function getRuntimeConfig() {
  return {
    ...JSON.parse(
      document.getElementById('js-bundle-runtime-config').innerHTML
    ),
    ...JSON.parse(
      document.getElementById('maintenance-mode-config').innerHTML
    )
  }
};
