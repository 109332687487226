import React from 'react'
import flow from 'lodash.flow'
import asBaseScreen from '../../screenWrappers/BaseScreen'
import { withSnackbarsFeature, withConfirmFeature } from '../../screenWrappers/Features'
import PaginatedDataTable from '../../components/PaginatedDataTable'

// Screen requires the following data providers and features injected
const wrap = flow([
  withConfirmFeature,
  withSnackbarsFeature,
  asBaseScreen,
])

/**
 * Displays a list of registered orgaisations
 */
class SessionsScreen extends React.Component {
  constructor(props) {
    super(props)
    this.datalabFacade = props.datalabFacade
    this.state = {
    }
  }

  render() {
    return (
      <PaginatedDataTable
        tableCaption="List of desktop sessions"
        datalabFacade={this.props.datalabFacade}
        collectionName="sessions"
        podId={this.props.user.pod}
        headers={[{ id: 'startTime', type: 'date-time-verbose', label: 'Start Time' },
          { id: 'endTime', type: 'date-time-verbose-or-live', label: 'End Time' },
          { id: 'duration', type: 'duration', label: 'Duration' },
          { id: 'userName', type: 'string', label: 'User' },
          { id: 'vmName', type: 'string', label: 'VM Name' },
        ]}
        defaultSort={{ property: 'startTime', direction: 'desc' }}
        onRowChangeCallback={() => { }}
        rowKey="startTime"
        limitCount={1000}
        splitDateTimes={['startTime', 'endTime']}
        csvExportFileName="datalab_desktopsessions"
      />
    )
  }
}

export default wrap(SessionsScreen)
