import React from 'react';
import flow from 'lodash.flow';
import asBaseScreen from '../../screenWrappers/BaseScreen';
import { withAllProducts, withProduct, withAllTags } from '../../screenWrappers/DataProviders.js';
import { withSnackbarsFeature, withConfirmFeature, withLoadingFeature } from '../../screenWrappers/Features';
import { NewProductScreen } from './NewProductScreen';

//Screen requires the following data providers and features injected
const wrap = flow([
  withProduct,
  withAllProducts,
  withAllTags,
  withConfirmFeature,
  withSnackbarsFeature,
  withLoadingFeature,
  asBaseScreen,
]);

export default wrap(((props) =>
    <NewProductScreen updating={true} {...props} />));


