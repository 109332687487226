import React from 'react'
import flow from 'lodash.flow'
import { withAdministrator } from '../../screenWrappers/DataProviders'
import { withSnackbarsFeature, withConfirmFeature, withLoadingFeature } from '../../screenWrappers/Features'
import NewAdministratorScreen from './NewAdministratorScreen'

// Screen requires the following data providers and features injected
const wrap = flow([
  withAdministrator,
  withConfirmFeature,
  withSnackbarsFeature,
  withLoadingFeature,
])

// eslint-disable-next-line react/jsx-props-no-spreading
export default wrap((props) => <NewAdministratorScreen updating {...props} />)
