import React from 'react'
import flow from 'lodash.flow'
import { Tooltip, Button } from '@mui/material'
import Skeleton from '@mui/material/Skeleton'
import { useNavigate } from 'react-router-dom'
import PropTypes from 'prop-types'
import asBaseScreen from '../../screenWrappers/BaseScreen'
import PaginatedDataTable from '../../components/PaginatedDataTable'
import { withSnackbarsFeature, withConfirmFeature } from '../../screenWrappers/Features'
import { Role } from '../../enums/Role'
import { withAllTags } from '../../screenWrappers/DataProviders'
import { withRouter } from '../../screenWrappers/withRouter'
import { useSnackbars } from '../../hooks/useSnackbars'
import { useFeatures } from '../../hooks/useFeatures'
import { appUserShape, tagShape } from '../../propTypeShapes'
import DatalabFacade from '../../dataService/DatalabFacade'
import RequiredRoleBoundary from '../../components/RequiredRoleBoundary'

// Screen requires the following data providers and features injected
const wrap = flow([
  withRouter,
  asBaseScreen,
  withConfirmFeature,
  withSnackbarsFeature,
  withAllTags,
])

const propTypes = {
  user: appUserShape.isRequired,
  datalabFacade: PropTypes.instanceOf(DatalabFacade).isRequired,
  tags: PropTypes.arrayOf(tagShape),
}

const defaultProps = {
  tags: null,
}

/**
 * Displays the selection of tags within the given pod
 */
function TagsScreen({ user, datalabFacade, tags }) {
  const [loading, setLoading] = React.useState(true)
  const navigate = useNavigate()
  const { showSnackbarSuccess } = useSnackbars()
  const { setConfirm } = useFeatures()

  React.useEffect(() => {
    if (tags) {
      setLoading(false)
    }
  }, [tags])

  const handleRowSelected = (rowId) => {
    navigate(`/tags/${rowId}`)
  }

  const handleNewBtnClick = () => {
    navigate('newTag')
  }

  const handleEditBtnClick = (rowId) => {
    navigate(`${rowId}/updateTag`)
  }

  const handleDeleteTag = (tag) => {
    setConfirm({
      message:
      `Are you sure you want to delete the tag ${tag}?`
      + ' This will also delete all tag values belonging to this tag'
      + ' and delete it from any associated organisations, products, users, and projects.',
      callback: async () => {
        const podId = user.pod
        await datalabFacade.deleteTag(tag, podId)
        showSnackbarSuccess(`Delete ${tag} tag succeeded`)
        navigate('/tags')
      },
    })
  }

  return (
    loading
      ? (
        <>
          {[...Array(15)].map((n, index) => (
            <Skeleton
              // eslint-disable-next-line react/no-array-index-key
              key={index}
              height={50}
              style={{ margin: '20px' }}
            />
          ))}
        </>
      )
      : (

        <RequiredRoleBoundary
          allowedRoles={[Role.ADMIN, Role.USER_ADMIN, Role.DATA_ADMIN]}
          functionProps={[
            'onClick',
            'deletable',
            'updatable',
          ]}
        >
          <div className="action-button-container">
            <Tooltip title="Create a new tag">
              <Button variant="contained" color="primary" onClick={handleNewBtnClick}>
                <p>New Tag&nbsp;&nbsp;</p>
                <i className="fas fa-plus-circle" />
              </Button>
            </Tooltip>
          </div>

          <PaginatedDataTable
            podId={user.pod}
            tableCaption="List of Tags"
            datalabFacade={datalabFacade}
            updatable={handleEditBtnClick}
            deletable={handleDeleteTag}
            onRowChangeCallback={handleRowSelected}
            collectionName="tags"
            disableArrayLinks
            headers={[
              { id: 'tagKey', type: 'string', label: 'Tag Name' },
              { id: 'tagValues', type: 'tag-value-array', label: 'Tag Value' },
            ]}
            defaultSort={{ property: 'tagKey', direction: 'asc' }}
            rowKey="tagKey"
            csvExportFileName="datalab_tags"
            tags={tags}
          />
        </RequiredRoleBoundary>
      )
  )
}

TagsScreen.propTypes = propTypes
TagsScreen.defaultProps = defaultProps

export default wrap(TagsScreen)
