import React from 'react';
import { Provider } from 'react-redux';
import { createStore } from 'redux';
import App from './App';
import { persistStore, persistReducer } from 'redux-persist';
import storage from 'redux-persist/lib/storage';
import { PersistGate } from 'redux-persist/integration/react';
import { StoreAction } from './enums/StoreAction';

/**
 * Using redux and redux-persist to store the state of our async operations
 * This allows us to inform the user if the action was successful
 * This is technically the root component but it merely wraps
 * the App.js component so we can handle persisted state
 * @see https://www.npmjs.com/package/redux-persist
 * @see https://daveceddia.com/how-does-redux-work/
 * @author Ryan Magor
 */

const persistConfig = {
  key: 'root',
  storage,
}

const initialState = {
  operations: [],
  refreshVmState: false
};

/** Here are the operations we can make on our global state store
 * redux-persist store these in local storage
 * Remember to remove operations from the store to avoid memory leak
 */
function reducer(state = initialState, action) {

  let newState = {...state}

  switch (action.type) {
    case StoreAction.ADD_OPERATION:
      let updatedOps = [...state.operations];
      updatedOps.push(action.operation);
      newState.operations = updatedOps;
      break;
    case StoreAction.REMOVE_OPERATION:
      let removedOps = [...state.operations];
      removedOps = removedOps.filter(function (obj) {
        return obj.time !== action.operation.time;
      });
      newState.operations = removedOps;
      break;
    //These operations add and remove notifications that the vm state may have changed
    //The vm details page subscribes to these notifications and can refresh the vm status when a new notification is added
    case StoreAction.NOTIFY_VM_STATE_CHANGED:
      newState.refreshVmState = true;
      break;
    case StoreAction.ACKNOwLEDGE_VM_STATE_CHANGED:
      newState.refreshVmState = false;
      break;
    default:
      return newState;
  }
  return newState;
}

const persistedReducer = persistReducer(persistConfig, reducer)
let store = createStore(persistedReducer)
let persistor = persistStore(store)

export default function DataLab(props) {

  return (
    <Provider store={store}>
      <PersistGate loading={null} persistor={persistor}>
        <App />
      </PersistGate>
    </Provider>
  )
}
