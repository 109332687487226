import React from 'react';
import Alert from './Alert';
import { getRuntimeConfig } from '../util/Config';
const config = getRuntimeConfig();

const LOGOUT_AFTER = 1000  * 60 * 30; //30 minutes
const WARN_AFTER = 1000 * 60 * 29; //29 minutes

export default class IdleActivityTimer extends React.Component {

    constructor(props) {
        super(props)
        this.logout = props.logout;
        this.state = {
            showWarnDialog: false,
            remaining: null
        }
    }

    componentDidMount() {
        document.addEventListener("mousemove", this._updateLastActive, false);
        document.addEventListener("mousedown", this._updateLastActive, false);
        document.addEventListener("keypress", this._updateLastActive, false);
        document.addEventListener("DOMMouseScroll", this._updateLastActive, false);
        document.addEventListener("mousewheel", this._updateLastActive, false);
        document.addEventListener("touchmove", this._updateLastActive, false);
        document.addEventListener("MSPointerMove", this._updateLastActive, false);

        //set initial to now
        this._updateLastActive(true);

        // set the DOM to update every 1 second in order to check idle time
        setInterval(() => {
            let timeSinceLastActive = this._timeSinceLastActive();
            this.setState({ remaining: Math.floor((LOGOUT_AFTER - timeSinceLastActive) / 1000) })
            if (timeSinceLastActive > LOGOUT_AFTER) {
                this.logout();
            } else if (timeSinceLastActive > WARN_AFTER) {
                this.setState({ showWarnDialog: true })
            }
        }, 1000)

    }

    render() {
        return (
            <React.Fragment>
                {this.state.showWarnDialog &&
                    <Alert
                        open={this.state.showWarnDialog} callback={this.onConfirm}
                        title={"Are you still there?"}
                        content={`You will be automatically logged out after ${this.state.remaining} seconds`}
                        hideButtons={true} />
                }
            </React.Fragment>
        )
    }

    _timeSinceLastActive = (e) => {
        let currentDate = new Date();
        let dateStored = window.localStorage.getItem("datalabLastActive");
        if (!dateStored) {
            //maybe another tab already logged us out
            this.logout();
        }
        let datalabLastActive = new Date(parseInt(dateStored, 10));
        let timePassedSinceLastActive = currentDate.getTime() - datalabLastActive.getTime();
        return timePassedSinceLastActive;
    }

    _updateLastActive = (force) => {

        if(force || window.localStorage.getItem("datalabLastActive")){
            this.setState({ showWarnDialog: false })
            //add to local storage to track activity across tabs
            let date = new Date();
            window.localStorage.setItem("datalabLastActive", date.getTime());
        }
       
    }

}