import React from 'react'

/**
 * Recursively maps all children in the tree, ie. performs a deep map
 * and invokes the function fn for each child in the tree
 */
export function recursiveMap(children, fn) {
  return React.Children.map(children, (child) => {
    if (!React.isValidElement(child)) {
      return child
    }
    let clonedChild = child
    if (child.props.children) {
      const childrenChildren = recursiveMap(child.props.children, fn)
      clonedChild = React.cloneElement(child, {
        children: childrenChildren.length === 1 ? childrenChildren[0] : childrenChildren,
      })
    }
    return fn(clonedChild)
  })
}

/**
 * Stop execution / sleep for specified number of milliseconds
 * @param {number} millis Milliseconds to sleep for
 */
export function sleep(millis) {
  return new Promise((resolve) => { setTimeout(resolve, millis) })
}
