"use strict";
Object.defineProperty(exports, "__esModule", { value: true });
exports.projectActionDtoSchema = exports.projectRestoreDtoSchema = exports.projectPostDtoSchema = void 0;
const zod_1 = require("zod");
const vm_1 = require("../vm");
const base_1 = require("../base");
const fields_1 = require("./fields");
/** Schema for creating a new project */
exports.projectPostDtoSchema = base_1.asyncOperationDtoSchema
    .merge(fields_1.projectInputFields)
    /** These are the additional fields required when creating a project */
    .merge(zod_1.z.object({
    /** The configuration of the VM to assign the users as {@link vmInputFields}, required if users are specified in the users array */
    selectedVm: vm_1.vmInputFields.optional(),
    /** Product names to initially link to the project */
    products: zod_1.z.array(zod_1.z.string()).optional(),
})).refine(
// requires selectedVm if users are specified
(input) => { var _a; return (((_a = input.users) === null || _a === void 0 ? void 0 : _a.length) ? input.selectedVm : true); }, {
    message: 'selectedVm is required when users are specified',
}).refine(
// Checks that the podID prefix for non ABS pod projects is present within the project uuid
(input) => (input.podId !== 'ABS'
    ? input.uuid.startsWith(input.podId.substring(0, 3).toLowerCase())
    : true), {
    message: 'Uuid should be prefixed with the podId',
});
/** Schema for restoring a closed project */
exports.projectRestoreDtoSchema = base_1.asyncOperationDtoSchema.extend({
    /** The identifier of the project */
    uuid: zod_1.z.string(),
});
/** Schema for various api operations that take an action on a project */
exports.projectActionDtoSchema = base_1.asyncOperationDtoSchema.extend({
    /** The identifier of the project */
    projectId: zod_1.z.string(),
});
