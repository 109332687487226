import React from 'react';
import { connect } from 'react-redux';
import { useEffect, useRef } from "react";
import { useParams } from 'react-router-dom'

function usePrevious(value) {
  const ref = useRef();
  useEffect(() => {
    ref.current = value;
  });
  return ref.current;
}

// This is a Higher Order Component
// Wrapping this around a component will inject the object(s) (based on the url params)
// into the child component as a prop
// @see https://reactjs.org/docs/higher-order-components.html
const asDataProvider = (WrappedComponent, dataType, dataDisplayName, fetchMethod, fetchParam, options = {}) => {
  return connect(mapStateToProps)(function DataProvider(props) {

    const [data, setData] = React.useState(null);
    const [loading, setLoading] = React.useState(true);

    const params = useParams();

    const prevOperationsLength = usePrevious(props.operations.length);

    useEffect(() => {
      getData();
      // eslint-disable-next-line
    }, [] );

    React.useEffect(() => {
      //if the amount of ongoing operations is less than the current amount it means an operation finished, so let's refresh the data
      if (props.operations.length < prevOperationsLength) {
        getData();
      }
      // eslint-disable-next-line
    }, [props.operations.length]);

    const getData = async () => {
      let data = { timestamp: new Date() };
      if (options.getLoggedInUser) {
        //Get the logged in user
        data[dataType] = await props.datalabFacade[fetchMethod](props.user.username);
      } else if (options.getUserPodId) {
        //Get the logged in user's podId
        data[dataType] = await props.datalabFacade[fetchMethod](props.user.pod);
      } else if (options.podIdandParam) {
        //Get the logged in users podId and url param
        data[dataType] = await props.datalabFacade[fetchMethod](params[fetchParam], props.user.pod);
      } else  {
        //Otherwise get it from the url params
        data[dataType] = await props.datalabFacade[fetchMethod](params[fetchParam]);
      }

      //Attach this function as a refresh listener for children to refresh data
      data[`${dataType}RefreshListener`] = getData.bind(this);
      setData(data)
      setLoading(false)
    }

    return <WrappedComponent {...data} {...props} loading={loading} />
  }
  )
}

export const withUser = (WrappedComponent) => { return asDataProvider(WrappedComponent, 'userData', 'User', 'getUser', 'userName') }
export const withAdministrator = (WrappedComponent) => { return asDataProvider(WrappedComponent, 'userData', 'User', 'getADUser', 'userName') }
export const withLoggedInUser = (WrappedComponent) => { return asDataProvider(WrappedComponent, 'userData', 'User', 'getUser', 'userName', { getLoggedInUser: true }) }
export const withAllUsers = (WrappedComponent) => { return asDataProvider(WrappedComponent, 'users', 'Users', 'getUsers', 'podId', { getUserPodId: true }) }
export const withBannerMessage = (WrappedComponent) => { return asDataProvider(WrappedComponent, 'bannner', 'bannerMessage', 'getBannerMessage', 'podId', { getUserPodId: true }) }
export const withProject = (WrappedComponent) => { return asDataProvider(WrappedComponent, 'project', 'Project', 'getProject', 'projectId') }
export const withAllVmOptionsConfig = (WrappedComponent) => { return asDataProvider(WrappedComponent, 'vmOptionsConfig', 'metadata', 'getVMOptionsConfig') }
export const withVm = (WrappedComponent) => { return asDataProvider(WrappedComponent, 'vmData', 'Virtual Machine', 'getVm', 'VMname') }
export const withVmState = (WrappedComponent) => { return asDataProvider(WrappedComponent, 'vmState', 'Virtual Machine state', 'getVmState', 'VMname') }
export const withAllPodAdmins = (WrappedComponent) => { return asDataProvider(WrappedComponent, 'podAdmins', 'Pod Administrators', 'getPodAdmins', 'podId', { getUserPodId: true }) }
export const withCurrentActiveLicenseData = (WrappedComponent) => { return asDataProvider(WrappedComponent, 'softwareLicenses', 'Software Licenses', 'getCurrentLicenseData') }
export const withAllProducts = (WrappedComponent) => { return asDataProvider(WrappedComponent, 'products', 'Products', 'getProducts', 'podId', { getUserPodId: true }) }
export const withProduct = (WrappedComponent) => { return asDataProvider(WrappedComponent, 'productData', 'Product', 'getProduct', 'name', { podIdandParam: true }) }
export const withProjectsProducts = (WrappedComponent) => { return asDataProvider(WrappedComponent, 'linkedProducts', 'Linked Products', 'getProjectsProducts', 'projectId') }
export const withAllOrganisations = (WrappedComponent) => { return asDataProvider(WrappedComponent, 'organisations', 'Organisations', 'getOrganisations', 'podId', { getUserPodId: true }) }
export const withOrganisation = (WrappedComponent) => { return asDataProvider(WrappedComponent, 'organisationData', 'Organisation', 'getOrganisation', "organisationId") }
export const withAllDatabricksWorkspaces = (WrappedComponent) => { return asDataProvider(WrappedComponent, 'databricksWorkspaces', 'Databricks Workspaces', 'getDatabricksWorkspaces') }
export const withAllAadUsers = (WrappedComponent) => { return asDataProvider(WrappedComponent, 'aadUsers', 'AAD Users', 'getAadUsers') }
export const withMonthlyActiveDesktops = (WrappedComponent) => { return asDataProvider(WrappedComponent, 'monthlyActiveDesktops', 'Monthly Active Desktops', 'getMonthlyActiveDesktops') }
export const withMonthlyActiveUsers = (WrappedComponent) => { return asDataProvider(WrappedComponent, 'monthlyActiveUsers', 'Monthly Active Users', 'getMonthlyActiveUsers') }
export const withSoftwareLicenseSummaries = (WrappedComponent) => { return asDataProvider(WrappedComponent, 'licenseSummaries', 'Software License Summaries', 'getSoftwareLicenseSummaries') }
export const withAllTags = (WrappedComponent) => { return asDataProvider(WrappedComponent, 'tags', 'Tags', 'getTags', 'podId', {getUserPodId: true}) }
export const withTag = (WrappedComponent) => { return asDataProvider(WrappedComponent, 'tag', 'Tag', 'getTag', 'tagKey', {podIdandParam: true})}
function mapStateToProps(state) {
  return {
    operations: state.operations
  };
}