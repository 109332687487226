import AdministratorsScreen from '../screens/podowner/AdministratorsScreen'
import NewAdministratorScreen from '../screens/podowner/NewAdministratorScreen'
import UpdateAdministratorScreen from '../screens/podowner/UpdateAdministratorScreen'
import BannerMessagesScreen from '../screens/podowner/BannerMessagesScreen'
import CommonRoutes from './CommonRoutes'
import AdminRoutes from './AdminRoutes'

/**
 * Paths applicable to Pod Owners, beware that these need to be ordered correctly for breadcrumbs to work
 * ie users/ should be before users/:userName
 */
export default [
  ...AdminRoutes,
  {
    path: '/administrators',
    name: 'Administrators',
    Component: AdministratorsScreen,
  },
  {
    path: '/administrators/new',
    name: 'New Administrator',
    Component: NewAdministratorScreen,
  },
  {
    path: '/administrators/:userName/edit',
    name: 'Update Administrator',
    Component: UpdateAdministratorScreen,
  },
  {
    path: '/bannerMessages',
    name: 'Banner Messages',
    Component: BannerMessagesScreen,
  },
  ...CommonRoutes,
]
