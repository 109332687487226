import PropTypes from 'prop-types'

export const vmTypeOptionsShape = PropTypes.shape({
  _id: PropTypes.string.isRequired,
  configType: PropTypes.string.isRequired,
  type: PropTypes.string.isRequired,
  value: PropTypes.string.isRequired,
  displayName: PropTypes.string.isRequired,
  description: PropTypes.string.isRequired,
  imageNamePrefix: PropTypes.string.isRequired,
  // eslint-disable-next-line react/forbid-prop-types
  resourceTags: PropTypes.object.isRequired,
})

export const vmSizeOptionsShape = PropTypes.shape({
  _id: PropTypes.string.isRequired,
  configType: PropTypes.string.isRequired,
  type: PropTypes.string.isRequired,
  displayName: PropTypes.string.isRequired,
  description: PropTypes.string.isRequired,
  compatibilitySet: PropTypes.number.isRequired,
  vmSize: PropTypes.string.isRequired,
  meterName: PropTypes.string.isRequired,
  requiredRegion: PropTypes.string,
})

export const vmVersionOptionsShape = PropTypes.shape({
  _id: PropTypes.string.isRequired,
  configType: PropTypes.string.isRequired,
  version: PropTypes.string.isRequired,
  latest: PropTypes.bool.isRequired,
  availableForRebuild: PropTypes.bool.isRequired,
  deprecationText: PropTypes.string.isRequired,
  softwareText: PropTypes.string.isRequired,
})

export const vmDiskOptionsShape = PropTypes.shape({
  _id: PropTypes.string.isRequired,
  configType: PropTypes.string.isRequired,
  description: PropTypes.string.isRequired,
  displayName: PropTypes.string.isRequired,
  size: PropTypes.number.isRequired,
})

export const vmOptionConfigShape = PropTypes.oneOfType([
  vmTypeOptionsShape,
  vmSizeOptionsShape,
  vmVersionOptionsShape,
  vmDiskOptionsShape,
])

export const tagShape = PropTypes.shape({
  tagKey: PropTypes.string.isRequired,
  tagValues: PropTypes.arrayOf(PropTypes.string),
})

export const appUserShape = PropTypes.shape({
  pod: PropTypes.string.isRequired,
  roles: PropTypes.arrayOf(PropTypes.string).isRequired,
  name: PropTypes.string.isRequired,
  username: PropTypes.string.isRequired,
})

export const currentLicenseDataShape = PropTypes.shape({
  data: PropTypes.arrayOf(PropTypes.shape({
    softwareName: PropTypes.string.isRequired,
    maxLicenses: PropTypes.number.isRequired,
    activeLicenses: PropTypes.number.isRequired,
  })),
  sasCount: PropTypes.arrayOf(PropTypes.shape({
    count: PropTypes.number.isRequired, podId: PropTypes.string.isRequired,
  })),
})

export const licenseSummariesShape = PropTypes.arrayOf(PropTypes.shape({
  podId: PropTypes.string.isRequired,
  data: PropTypes.arrayOf(PropTypes.shape({
    date: PropTypes.string.isRequired,
    max: PropTypes.number.isRequired,
  })),
}))

export const aadUsersShape = PropTypes.shape({
  sysadmins: PropTypes.number.isRequired,
  libraryAdmins: PropTypes.number.isRequired,
  billingAdmins: PropTypes.number.isRequired,
  totalUniquePrivilegedAdmins: PropTypes.number.isRequired,
  totalUniqueAdmins: PropTypes.number.isRequired,
  totalUniqueAnalysts: PropTypes.number.isRequired,
  totalUniqueUsers: PropTypes.number.isRequired,
  podCounts: PropTypes.arrayOf(PropTypes.shape({
    podId: PropTypes.string.isRequired,
    admins: PropTypes.number.isRequired,
    readonlyAdmins: PropTypes.number.isRequired,
    podOwners: PropTypes.number.isRequired,
    analysts: PropTypes.number.isRequired,
    totalUniquePodAdmins: PropTypes.number.isRequired,
    totalUniquePodUsers: PropTypes.number.isRequired,
  })),
})

export const databricksWorkspacesShape = PropTypes.arrayOf(PropTypes.shape({
  _id: PropTypes.string.isRequired,
  workspaceName: PropTypes.string.isRequired,
  customVirtualNetworkId: PropTypes.string.isRequired,
  customPublicSubnetName: PropTypes.string.isRequired,
  customPrivateSubnetName: PropTypes.string.isRequired,
  managedResourceGroupId: PropTypes.string.isRequired,
  workspaceUrl: PropTypes.string.isRequired,
  scimProvisioningObjectId: PropTypes.string.isRequired,
  scimProvisioningApplicationId: PropTypes.string.isRequired,
  allocatedProject: PropTypes.string.isRequired,
}))

export const monthlyActiveDesktopsShape = PropTypes.shape({
  _id: PropTypes.string.isRequired,
  date: PropTypes.string.isRequired,
  lastUpdated: PropTypes.string.isRequired,
  totalMonthlyActiveDesktops: PropTypes.number.isRequired,
  monthlyActiveDesktops: PropTypes.shape({
    vmName: PropTypes.string.isRequired,
    userName: PropTypes.string.isRequired,
  }),
})

export const monthlyActiveUsersShape = PropTypes.shape({
  _id: PropTypes.string.isRequired,
  date: PropTypes.string.isRequired,
  periodStartDate: PropTypes.string.isRequired,
  lastUpdated: PropTypes.string.isRequired,
  totalMonthlyActiveUsers: PropTypes.number.isRequired,
  monthlyActiveUsers: PropTypes.arrayOf(PropTypes.shape({
    userName: PropTypes.string.isRequired,
    vmNames: PropTypes.arrayOf(PropTypes.string).isRequired,
  })),
})

export const timeRangeShape = PropTypes.shape({
  label: PropTypes.string.isRequired,
  startDate: PropTypes.instanceOf(Date).isRequired,
  endDate: PropTypes.instanceOf(Date).isRequired,
})
// Stored in the redux store for managing long running operations
export const asyncOperationShape = PropTypes.shape({
  eventId: PropTypes.string.isRequired,
  time: PropTypes.number.isRequired,
  endpoint: PropTypes.string.isRequired,
  // eslint-disable-next-line react/forbid-prop-types
  requestBody: PropTypes.object.isRequired,
})

export const projectShape = PropTypes.shape({
  contactEmail: PropTypes.string.isRequired,
  contactPhone: PropTypes.string.isRequired,
  description: PropTypes.string.isRequired,
  membersSecurityGroupId: PropTypes.string.isRequired,
  podId: PropTypes.string.isRequired,
  primaryRegion: PropTypes.string.isRequired,
  projectContact: PropTypes.string.isRequired,
  projectName: PropTypes.string.isRequired,
  projectStorageSize: PropTypes.number.isRequired,
  projectUsageDatalake: PropTypes.number.isRequired,
  projectStorageSizeChangelog: PropTypes.arrayOf(PropTypes.shape({
    oldProjectStorageSize: PropTypes.number.isRequired,
    newProjectStorageSize: PropTypes.number.isRequired,
    oldProjectStorageSizeDatalake: PropTypes.number.isRequired,
    newProjectStorageSizeDatalake: PropTypes.number.isRequired,
    date: PropTypes.string.isRequired,
  })),
  projectStore: PropTypes.string.isRequired,
  resourceGroupName: PropTypes.string.isRequired,
  securityGroupID: PropTypes.string.isRequired,
  startDate: PropTypes.string.isRequired,
  status: PropTypes.string.isRequired,
  subscription: PropTypes.string.isRequired,
  tags: PropTypes.arrayOf(tagShape).isRequired,
  users: PropTypes.arrayOf(PropTypes.string).isRequired,
  uuid: PropTypes.string.isRequired,
  version: PropTypes.string.isRequired,
})

export const userShape = PropTypes.shape({
  userName: PropTypes.string.isRequired,
  status: PropTypes.string.isRequired,
  VMs: PropTypes.arrayOf(PropTypes.shape({
    project: PropTypes.string.isRequired,
    VM: PropTypes.string.isRequired,
  })),
  activeVm: PropTypes.string,
})

export const vmShape = PropTypes.shape({
  VMname: PropTypes.string.isRequired,
  project: PropTypes.string.isRequired,
  type: PropTypes.string.isRequired,
  size: PropTypes.string.isRequired,
  version: PropTypes.string.isRequired,
  status: PropTypes.string.isRequired,
  powerState: PropTypes.string.isRequired,
  active: PropTypes.bool.isRequired,
  buildDate: PropTypes.string.isRequired,
  destroyDate: PropTypes.string,
  bypassShutdownUntil: PropTypes.string,
  podId: PropTypes.string,
})

export const selectableUsersProject = PropTypes.arrayOf(PropTypes.shape({
  _id: PropTypes.string.isRequired,
  contactEmail: PropTypes.string.isRequired,
  displayName: PropTypes.string.isRequired,
  userName: PropTypes.string.isRequired,
  status: PropTypes.string.isRequired,
  registeredDate: PropTypes.string.isRequired,
  podId: PropTypes.string.isRequired,
  phone: PropTypes.string.isRequired,
  organisation: PropTypes.string,
  objectId: PropTypes.string.isRequired,
  comments: PropTypes.string,
  activeVm: PropTypes.string,
  aaddsSid: PropTypes.string,
  tags: PropTypes.arrayOf(tagShape),
}))

export const selectableTagsProject = PropTypes.arrayOf(PropTypes.shape({
  _id: PropTypes.string.isRequired,
  tagKey: PropTypes.string.isRequired,
  tagValues: PropTypes.arrayOf(PropTypes.string).isRequired,
  podId: PropTypes.string.isRequired,
}))

// The initial tags value is an empty array
export const ProjectValues = PropTypes.shape({
  contactEmail: PropTypes.string.isRequired,
  contactPhone: PropTypes.string.isRequired,
  defaultVMsize: PropTypes.string.isRequired,
  description: PropTypes.string.isRequired,
  endDate: PropTypes.instanceOf(Date).isRequired,
  organisation: PropTypes.string.isRequired,
  projectContact: PropTypes.string.isRequired,
  projectName: PropTypes.string.isRequired,
  projectStorageSize: PropTypes.number.isRequired,
  // eslint-disable-next-line react/forbid-prop-types
  tags: PropTypes.array.isRequired,
  uuid: PropTypes.string.isRequired,
})

export const organisationsShape = PropTypes.shape({
  _id: PropTypes.string.isRequired,
  ABN: PropTypes.string.isRequired,
  organisationId: PropTypes.string.isRequired,
  organisationName: PropTypes.string.isRequired,
  podId: PropTypes.string.isRequired,
  tags: PropTypes.arrayOf(tagShape),
})

export const existingUserShape = PropTypes.shape({
  _id: PropTypes.string.isRequired,
  userName: PropTypes.string.isRequired,
  tags: PropTypes.arrayOf(tagShape),
  status: PropTypes.string.isRequired,
  registeredDate: PropTypes.string.isRequired,
  podId: PropTypes.string.isRequired,
  phone: PropTypes.string.isRequired,
  organisation: PropTypes.string.isRequired,
  objectId: PropTypes.string.isRequired,
  displayName: PropTypes.string.isRequired,
  contactEmail: PropTypes.string.isRequired,
  comments: PropTypes.string,
  aaddsSid: PropTypes.string,
  VMs: PropTypes.arrayOf(PropTypes.shape({
    project: PropTypes.string.isRequired,
    VM: PropTypes.string.isRequired,
  })),
})
