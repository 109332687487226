"use strict";
Object.defineProperty(exports, "__esModule", { value: true });
exports.sessionDtoSchema = void 0;
const zod_1 = require("zod");
/** Database fields set by user input that can be queried via the api */
const sessionInputFields = zod_1.z.object({});
/** Generated database fields that can be queried via the api */
const generatedFields = zod_1.z.object({
    startTime: zod_1.z.string().datetime(),
    endTime: zod_1.z.string().datetime(),
    duration: zod_1.z.number(),
    userName: zod_1.z.string(),
    vmName: zod_1.z.string(),
});
/** All database fields that are exposed via API */
const sessionDtoSchema = sessionInputFields.merge(generatedFields);
exports.sessionDtoSchema = sessionDtoSchema;
