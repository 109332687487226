import React from 'react'
import { useSnackbar } from 'notistack'
import { Button } from '@mui/material'
import CancelIcon from '@mui/icons-material/Cancel'
import { useNavigate } from 'react-router-dom'
import AnimatedVmStartingSvg from '../../resources/images/animated_vm_starting.svg'

const snackbarActions = (key, options) => {
  const navigate = useNavigate()
  const { closeSnackbar } = useSnackbar()

  return (
    <>
      {options.logsLink
        && (
          <Button
            variant="outlined"
            style={{ color: 'white', borderColor: 'white', fontSize: '0.75em' }}
            onClick={() => navigate('/events')}
          >
            {'Logs >>'}
          </Button>
        )}
      <Button
        aria-label="dismiss"
        variant="text"
        style={{ color: 'white' }}
        size="small"
        onClick={() => { closeSnackbar(key) }}
      >
        <CancelIcon />
      </Button>
    </>
  )
}
const useSnackbars = () => {
  const { enqueueSnackbar, closeSnackbar } = useSnackbar()

  const closeSnackBar = (key) => {
    closeSnackbar(key)
  }

  const showSnackbarInProgress = (message, preventDuplicate = true) => {
    enqueueSnackbar(message, {
      action: (k) => snackbarActions(k, { logsLink: true }),
      anchorOrigin: { horizontal: 'right', vertical: 'top' },
      autoHideDuration: 15000,
      variant: 'info',
      preventDuplicate,
    })
  }

  const showSnackbarSuccess = (message) => {
    enqueueSnackbar(message, {
      action: (key) => snackbarActions(key, { logsLink: true }),
      anchorOrigin: { horizontal: 'right', vertical: 'top' },
      autoHideDuration: 15000,
      variant: 'success',
      preventDuplicate: true,
      classes: {
        containerRoot: 'snackbar',
      },
    })
  }

  const showSnackbarTracking = () => {
    const id = new Date().getTime().toString()
    const key = enqueueSnackbar(
      <div className="snackbar-tracker">
        <img alt="vm" style={{ maxWidth: '100px', height: 'auto' }} src={AnimatedVmStartingSvg} />
        {' '}
        <p id={id}>{'We\'re tracking your request...'}</p>
      </div>,
      {
        action: (k) => snackbarActions(k, { logsLink: false }),
        anchorOrigin: { horizontal: 'right', vertical: 'top' },
        preventDuplicate: true,
        persist: true,
        className: 'snackbar-tracker-wrapper',
      }
    )
    return { key, id }
  }

  const showSnackbarWarning = (message) => {
    enqueueSnackbar(message, {
      action: (k) => snackbarActions(k, { logsLink: false }),
      anchorOrigin: { horizontal: 'right', vertical: 'top' },
      autoHideDuration: 15000,
      preventDuplicate: true,
      variant: 'warning',
    })
  }

  const showSnackbarLoggedOut = (message) => {
    enqueueSnackbar(message, {
      action: (k) => snackbarActions(k, { logsLink: false }),
      anchorOrigin: { horizontal: 'right', vertical: 'top' },
      persist: true,
      preventDuplicate: true,
      variant: 'info',
    })
  }

  const showSnackbarFail = (message) => {
    enqueueSnackbar(message, {
      action: (k) => snackbarActions(k, { logsLink: true }),
      anchorOrigin: { horizontal: 'right', vertical: 'top' },
      autoHideDuration: 15000,
      preventDuplicate: true,
      variant: 'error',
    })
  }

  return {
    showSnackbarInProgress,
    showSnackbarSuccess,
    showSnackbarWarning,
    showSnackbarFail,
    showSnackbarLoggedOut,
    showSnackbarTracking,
    closeSnackBar,
  }
}

export {
  useSnackbars,
}
