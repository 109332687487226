import React from 'react';
import SpinnerIndeterminate from './SpinnerIndeterminate';
import {
  Typography,
  Dialog
} from "@mui/material";

export default function SpinnerIndeterminateModal(props) {
  return (
    <Dialog aria-labelledby="Loading spinner" open={true}>
      <SpinnerIndeterminate />
      {props.message &&
        <Typography className="loading-modal">{props.message}</Typography>
      }
    </Dialog>
  );
}