"use strict";
Object.defineProperty(exports, "__esModule", { value: true });
exports.organisationDtoSchema = void 0;
const zod_1 = require("zod");
const tag_1 = require("../tag");
/** Database fields set by user input that can be queried via the api */
const organisationInputFields = zod_1.z.object({});
/** Generated database fields that can be queried via the api */
const generatedFields = zod_1.z.object({
    organisationId: zod_1.z.string(),
    organisationName: zod_1.z.string(),
    ABN: zod_1.z.string(),
    tags: zod_1.z.array(tag_1.tagInstanceDtoSchema),
});
/** All database fields that are exposed via API */
const organisationDtoSchema = organisationInputFields.merge(generatedFields);
exports.organisationDtoSchema = organisationDtoSchema;
