import React from 'react'
import { Route, Routes } from 'react-router-dom'
import AdminRoutes from './routes/AdminRoutes'
import AnalystRoutes from './routes/AnalystRoutes'
import LibraryAdminRoutes from './routes/LibraryAdminRoutes'
import BillingAdminRoutes from './routes/BillingAdminRoutes'
import PodOwnerRoutes from './routes/PodOwnerRoutes'
import PageNotFoundScreen from './screens/common/PageNotFoundScreen'
import { Role } from './enums/Role'
import DatalabFacade from './dataService/DatalabFacade'
import { SeadAccountInfo } from './util/AuthenticationHandler'

type Paths = {
  path: string;
  name: string;
  Component: typeof React.Component;
}[]

const ROUTE_CONFIG: {
  paths: Paths,
  roles: string[]
}[] = [
  {
    paths: AdminRoutes,
    roles: [Role.ADMIN, Role.USER_ADMIN, Role.DATA_ADMIN, Role.SYSADMIN, Role.READONLY],
  },
  {
    paths: AnalystRoutes,
    roles: [Role.ANALYST],
  },
  {
    paths: LibraryAdminRoutes,
    roles: [Role.LIBRARY_ADMIN, Role.SYSADMIN],
  },
  {
    paths: PodOwnerRoutes,
    roles: [Role.POD_OWNER, Role.SYSADMIN],
  },
  {
    paths: BillingAdminRoutes,
    roles: [Role.BILLING_ADMIN, Role.SYSADMIN],
  },
]

/**
 * Check if any from a list of roles appears in a list of available roles
 * @param roles - The users roles
 * @param availableRoles - All roles
 * @returns True if roles and availableRoles intersect
 */
const hasRole = (roles: string[], availableRoles: string[]): boolean => (
  roles.some((r) => availableRoles.includes(r))
)

interface Props {
  roles: string[],
  commonProps: {
    user: SeadAccountInfo
    isAuthenticated: boolean,
    authButtonMethod: () => void,
    datalabFacade: DatalabFacade,
  }
}

/**
 * DataLab app router component
 * @param props
 */
function Router(props: Readonly<Props>) {
  const { roles: userRoles, commonProps } = props

  // Find all route groups that match current user role
  const configs = ROUTE_CONFIG.filter(({ roles }) => hasRole(userRoles, roles))
  // Combine all Route elements from matched groups into a single array
  const routes = configs.reduce((acc: React.ReactElement[], { paths }) => ([
    ...acc,
    ...paths.map(({ path, Component }) => {
      const element = <Component routes={paths} {...commonProps} />
      return <Route path={path} element={element} key={path} />
    }),
  ]), [])

  return (
    <Routes>
      {routes}
      <Route path="*" element={<PageNotFoundScreen />} />
    </Routes>
  )
}

export default Router
export type { Paths }
