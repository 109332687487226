import axios, { AxiosResponse } from 'axios'
import { getRuntimeConfig } from '../util/Config'

const runtimeConfig = getRuntimeConfig()

/**
 *
 * @param accessToken
 * @param endpointUrl
 * @param params
 */
async function fetchData(
  accessToken: string,
  endpointUrl: string,
  params?: Record<string, unknown>
): Promise<unknown> {
  let config = {}
  if (accessToken) {
    config = {
      params: params || {},
      headers: {
        Authorization: `Bearer ${accessToken}`,
        'Ocp-Apim-Subscription-Key': runtimeConfig.AZURE_APIM_SUBSCRIPTION_KEY,
      },
    }
  }
  let response
  try {
    response = await axios.get(
      endpointUrl,
      config
    )
  } catch (error) {
    // if no results just return empty array
    if (error.response && error.response.status === 404) {
      return []
    }
    // throw back
    throw error
  }
  return response.data
}

/**
 * Fetch one object from the API
 * @param accessToken - An access token with the correct read scope
 * @param schema - The schema to validate the object against
 * @param endpointUrl - The GET endpoint to fetch the object from
 * @param params - The query params
 */
async function fetchOne(
  accessToken: string,
  endpointUrl: string,
  params?: Record<string, unknown>
): Promise<unknown> {
  const config = {
    params,
    headers: {
      Authorization: `Bearer ${accessToken}`,
      'Ocp-Apim-Subscription-Key': runtimeConfig.AZURE_APIM_SUBSCRIPTION_KEY,
    },
  }
  const response = await axios.get(
    endpointUrl,
    config
  )

  return response.data
}

/**
 *
 * @param accessToken
 * @param endpointUrl
 * @param body
 */
async function postOperation(
  accessToken: string,
  endpointUrl: string,
  body: Record<string, unknown>
): Promise<AxiosResponse<unknown, unknown>> {
  let headers = {}
  if (accessToken) {
    headers = {
      Authorization: `Bearer ${accessToken}`,
      'Ocp-Apim-Subscription-Key': runtimeConfig.AZURE_APIM_SUBSCRIPTION_KEY,
    }
  }
  const response = await axios.post(
    endpointUrl,
    body,
    {
      headers,
    }
  )
  return response
}

export default {
  fetchData,
  fetchOne,
  postOperation,
}
