export const RoleDisplayName = {
  sysadmin: 'System Administrator',
  admin: 'Project Administrator',
  dataadmin: 'Data Administrator',
  useradmin: 'User Administrator',
  analyst: 'Analyst',
  readonly: 'Project Reader',
  podowner: 'Pod Owner',
  billingadmin: 'Billing Administrator',
  libraryadmin: 'Library Administrator',
}
