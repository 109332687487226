import React from 'react'
import flow from 'lodash.flow'
import PropTypes from 'prop-types'
import asBaseScreen from '../../screenWrappers/BaseScreen'
import PaginatedDataTable from '../../components/PaginatedDataTable'
import { withRouter } from '../../screenWrappers/withRouter'
import { appUserShape } from '../../propTypeShapes'
import DatalabFacade from '../../dataService/DatalabFacade'

// Screen requires the following data providers and features injected
const wrap = flow([
  withRouter,
  asBaseScreen,
])

const propTypes = {
  user: appUserShape.isRequired,
  datalabFacade: PropTypes.instanceOf(DatalabFacade).isRequired,
}

/**
 * Displays the project-product links (ACLs for projects to access products)
 * within the given pod
 * @param {object} props - Component props
 * @returns {React.JSX.Element} a ProjectProductLinksScreen component
 */
function ProjectProductLinksScreen(props) {
  const { user, datalabFacade } = props
  return (
    <PaginatedDataTable
      podId={user.pod}
      tableCaption="List of Project Product Links"
      datalabFacade={datalabFacade}
      collectionName="projectProductLinks"
      defaultSort={{ property: 'productName', direction: 'asc' }}
      rowKey="_id"
      csvExportFileName="ABS SEAD Project Product Links Export"
      headers={[
        {
          id: '_id',
          type: 'string',
          label: 'ID',
          hidden: true,
        },
        {
          id: 'productName',
          type: 'string',
          label: 'Product',
          linkTo: 'products',
          linkToSuffix: 'linkProjects',
        },
        {
          id: 'projectId',
          type: 'string',
          label: 'Project',
          linkTo: 'projects',
          linkToSuffix: 'linkProducts',
        },
      ]}
    />
  )
}

ProjectProductLinksScreen.propTypes = propTypes

export default wrap(ProjectProductLinksScreen)
