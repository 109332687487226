
/**
 * Takes a number and format it nicely for very small and very large sizes
 * and provide comparison to another number, as a percentage
 * @param {number} size - the number to format
 * @param {number} comparison - the number to compare to
 * @returns {string[]} - An array of formatted values
 */
const formatSizeUsage = (size, comparison) => {
  if (size === 0) {
    // returns 0.00 when the number is exactly zero
    return ['0.00', '0.00%']
  }
  if (size < 0.0001) {
    // returns 0.0001 when the number is very small for readability
    return ['< 0.0001', `${((0.0001 / comparison) * 100).toFixed(2)}%`]
  }
  // returns the size to 2 decimal places and the percentage as a whole number
  return [size.toFixed(2), `${((size / comparison) * 100).toFixed(0)}%`]
}

export { formatSizeUsage }
