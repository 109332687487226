import CommonRoutes from './CommonRoutes';
import MyAccountScreen from '../screens/analyst/MyAccountScreen';
import VmsScreen from '../screens/analyst/VmsScreen';
import VmDetailsScreen from '../screens/analyst/VmDetailsScreen';
import EditAccountSettingsScreen from '../screens/analyst/EditAccountSettingsScreen';

/**
 * Paths applicable to Analysts, beware that these need to be ordered correctly for breadcrumbs to work
 * ie users/ should be before users/:userName
 */
export default [
    {   
        //The default route
        path: "/",
        name: "My Projects",
        Component: VmsScreen
    },
    {
        path: "/myAccount/:userName",
        name: "My Account",
        Component: MyAccountScreen
    },
    {
        path: "/myAccount/:userName/settings",
        name: "Edit Account Settings",
        Component: EditAccountSettingsScreen
    },
    {
        path: "/vms",
        name: "My Projects",
        Component: VmsScreen
    },
    {
        path: "/vms/:VMname",
        name: "VMname",
        Component: VmDetailsScreen
    }
].concat(CommonRoutes);
