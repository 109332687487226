/* eslint-disable react/jsx-props-no-spreading */
import React, {
  useState, useEffect,
} from 'react'
import flow from 'lodash.flow'
import {
  FormControl,
  InputLabel,
  TextField,
  Grid,
  FormHelperText,
  Button,
  Container,
  Checkbox,
  Paper,
  Chip,
  Dialog,
  DialogActions,
  DialogTitle,
  DialogContentText,
  DialogContent,
} from '@mui/material'
import Autocomplete from '@mui/material/Autocomplete'
import { Formik } from 'formik'
import * as Yup from 'yup'
import { styled } from '@mui/material/styles'
import PropTypes from 'prop-types'
import { useNavigate } from 'react-router-dom'
import asBaseScreen from '../../screenWrappers/BaseScreen'
import { getRuntimeConfig } from '../../util/Config'
import { withSnackbarsFeature, withConfirmFeature, withLoadingFeature } from '../../screenWrappers/Features'
import { withAllOrganisations, withAllTags } from '../../screenWrappers/DataProviders'
import SkeletonForm from '../../components/SkeletonForm'
import DatalabFacade from '../../dataService/DatalabFacade'
import {
  organisationsShape,
  tagShape,
  appUserShape,
  existingUserShape,
} from '../../propTypeShapes'

const config = getRuntimeConfig()
const { AAD_SUFFIX } = config

// Screen requires the following data providers and features injected
const wrap = flow([
  withLoadingFeature,
  withConfirmFeature,
  withSnackbarsFeature,
  withAllOrganisations,
  withAllTags,
  asBaseScreen,
])

const StyledChip = styled(Chip)(({ theme }) => ({
  margin: theme.spacing(0.5, 0.25),
}))

const form = {
  email: PropTypes.string,
  emailOptOut: PropTypes.bool,
  displayName: PropTypes.string,
  organisation: PropTypes.string,
  phone: PropTypes.string,
  tags: PropTypes.arrayOf(tagShape),
  userName: PropTypes.string,
}

const formPropTypes = {
  values: PropTypes.shape(form).isRequired,
  touched: PropTypes.shape(Object.fromEntries(Object.keys(form).map((k) => [k, PropTypes.bool]))).isRequired,
  errors: PropTypes.shape(Object.fromEntries(Object.keys(form).map((k) => [k, PropTypes.string]))).isRequired,
  handleChange: PropTypes.func.isRequired,
  handleBlur: PropTypes.func.isRequired,
  handleSubmit: PropTypes.func.isRequired,
  setFieldValue: PropTypes.func.isRequired,
  updating: PropTypes.bool.isRequired,
  organisationData: PropTypes.arrayOf(organisationsShape).isRequired,
  tags: PropTypes.arrayOf(tagShape).isRequired,
  selectableTagKeys: PropTypes.arrayOf(PropTypes.string).isRequired,
}

/**
 */
function Form({
  values, touched, errors,
  handleChange, handleBlur, handleSubmit, setFieldValue, updating, organisationData, tags, selectableTagKeys,
}) {
  const [selectableTagValues, setSelectableTagValues] = useState('')
  const [selectedTagKey, setSelectedTagKey] = useState('')
  const [selectedTagValue, setSelectedTagValue] = useState('')

  return (
    <form onSubmit={handleSubmit}>
      <p className="mandatory-info">All fields are required</p>
      <Grid container spacing={3} alignItems="center" direction="column">
        <Grid
          container
          className="formRow"
          spacing={1}
          alignItems="center"
          justify="center"
          direction="row"
        >
          <Grid item xs={2}>
            <InputLabel htmlFor="userName">Username</InputLabel>
          </Grid>
          <Grid item xs>
            <FormControl fullWidth>
              <TextField
                id="userName"
                aria-describedby="helperuserName"
                onChange={handleChange}
                onBlur={handleBlur}
                value={values.userName}
                disabled={updating}
              />
              <FormHelperText
                id="helperuserName"
                error={errors.userName && touched.userName}
              >
                {(errors.userName && touched.userName) ? errors.userName
                  : 'A unique username'}
              </FormHelperText>
            </FormControl>
          </Grid>
          {!updating && (
            <Grid item xs={6}>
              <InputLabel>{AAD_SUFFIX}</InputLabel>
            </Grid>
          )}
        </Grid>
        <Grid
          container
          className="formRow"
          spacing={1}
          alignItems="center"
          justify="center"
          direction="row"
        >
          <Grid item xs={2}>
            <InputLabel htmlFor="displayName">Name</InputLabel>
          </Grid>
          <Grid item xs>
            <FormControl fullWidth>
              <TextField
                rowsMax="4"
                id="displayName"
                aria-describedby="helperName"
                onChange={handleChange}
                onBlur={handleBlur}
                value={values.displayName}
              />
              <FormHelperText
                id="helperName"
                error={errors.displayName && touched.displayName}
              >
                {(errors.displayName && touched.displayName) ? errors.displayName
                  : 'The display name for the User'}
              </FormHelperText>
            </FormControl>
          </Grid>
        </Grid>

        <Grid
          container
          className="formRow"
          spacing={1}
          alignItems="center"
          justify="center"
          direction="row"
        >
          <Grid item xs={2}>
            <InputLabel htmlFor="email">Email</InputLabel>
          </Grid>
          <Grid item xs>
            <FormControl fullWidth>
              <TextField
                id="email"
                aria-describedby="helperEmail"
                onChange={handleChange}
                onBlur={handleBlur}
                value={values.email}
                type="email"
                autoComplete="email"
              />
              <FormHelperText
                id="helperEmail"
                error={errors.email && touched.email}
              >
                {(errors.email && touched.email) ? errors.email
                  : 'External contact email'}
              </FormHelperText>
            </FormControl>
          </Grid>
        </Grid>

        <Grid
          container
          className="formRow"
          spacing={1}
          alignItems="center"
          justify="center"
          direction="row"
        >
          <Grid item xs={2}>
            <InputLabel htmlFor="phone">Contact phone #</InputLabel>
          </Grid>
          <Grid item xs>
            <FormControl fullWidth>
              <TextField
                id="phone"
                aria-describedby="helperphone"
                type="phone"
                autoComplete="phone"
                onChange={handleChange}
                onBlur={handleBlur}
                value={values.phone}
              />
              <FormHelperText
                id="helperphone"
                error={errors.phone && touched.phone}
              >
                {(errors.phone && touched.phone) ? errors.phone
                  : 'Contact phone number'}
              </FormHelperText>
            </FormControl>
          </Grid>
        </Grid>

        <Grid
          container
          className="formRow"
          spacing={1}
          alignItems="center"
          justify="center"
          direction="row"
        >
          <Grid item xs={2}>
            <InputLabel htmlFor="organisation">Organisation</InputLabel>
          </Grid>
          <Grid item xs>
            <FormControl fullWidth>
              <Autocomplete
                id="organisation"
                options={organisationData.map((o) => o.organisationName)}
                getOptionLabel={(option) => option}
                variant="outlined"
                onChange={(event, value) => setFieldValue('organisation', value || '')}
                value={values.organisation}
                renderInput={(params) => (
                  <TextField {...params} variant="outlined" fullWidth />
                )}
              />
              <FormHelperText
                id="helperOrganisation"
                error={errors.organisation && touched.organisation}
              >
                {(errors.organisation && touched.organisation) ? errors.organisation
                  : 'The organisation of the User'}
              </FormHelperText>
            </FormControl>
          </Grid>
        </Grid>
        <Grid
          container
          className="formRow"
          spacing={1}
          alignItems="center"
          justify="center"
          direction="row"
        >
          <Grid item xs={2}>
            <InputLabel htmlFor="emailOptOut">Opt out of emails</InputLabel>
          </Grid>
          <Grid item xs>
            <FormControl>
              <Checkbox
                style={{ width: '42px' }}
                checked={values.emailOptOut}
                onChange={() => setFieldValue('emailOptOut', !values.emailOptOut)}
                aria-describedby="helperEmailOptOut"
              />
              <FormHelperText
                id="helperEmailOptOut"
                error={errors.emailOptOut && touched.emailOptOut}
              >
                {(errors.emailOptOut && touched.emailOptOut)
                  ? errors.emailOptOut
                  : "Don't send automatic reminder emails"}
              </FormHelperText>
            </FormControl>
          </Grid>
        </Grid>

        <Grid
          container
          className="formRow"
          spacing={1}
          alignItems="center"
          justify="center"
          direction="row"
        >
          <Grid
            container
            className="formRow"
            spacing={1}
            alignItems="center"
            justify="center"
            direction="row"
          >
            <Grid item xs={2}>
              <InputLabel htmlFor="selectedTagKey">Select Tag</InputLabel>
            </Grid>
            <Grid item xs>
              <FormControl fullWidth>
                <Autocomplete
                  id="selectedTagKey"
                  blurOnSelect
                  options={selectableTagKeys}
                  value={selectedTagKey}
                  renderInput={(params) => (
                    <TextField {...params} label="Tag Name" variant="outlined" fullwidth />
                  )}
                  onChange={(event, value) => {
                    if (value) {
                      const tagValuesSelectable = tags.find((t) => t.tagKey === value).tagValues
                        .filter((t) => !values.tags.some((tag) => (
                          tag.tagKey === value && tag.tagValue === t)))
                      setFieldValue('selectedTagKey', value)
                      setFieldValue('selectedTagValue', '')
                      setSelectedTagKey(value)
                      setSelectedTagValue('')
                      setSelectableTagValues(tagValuesSelectable)
                    } else {
                      setFieldValue('selectedTagKey', '')
                      setFieldValue('selectedTagValue', '')
                      setSelectedTagKey('')
                      setSelectedTagValue('')
                      setSelectableTagValues([])
                    }
                  }}
                />
              </FormControl>
            </Grid>
            <Grid item xs={2}>
              <InputLabel htmlFor="selectedTagValue">Select Tag Value</InputLabel>
            </Grid>
            <Grid item xs>
              <FormControl fullWidth>
                <Autocomplete
                  id="selectedTagValue"
                  blurOnSelect
                  disabled={!selectedTagKey}
                  options={selectableTagValues}
                  value={selectedTagValue}
                  renderInput={(params) => (
                    <TextField {...params} label="Tag Value" variant="outlined" fullwidth />
                  )}
                  onChange={(event, value) => {
                    setFieldValue('selectedTagValue', value)
                    setSelectedTagValue(value)
                  }}
                />
              </FormControl>
            </Grid>
            <Grid item xs>
              <Button
                variant="outlined"
                disabled={!selectedTagKey || !selectedTagValue}
                onClick={() => {
                  values.tags.push({
                    tagKey: selectedTagKey,
                    tagValue: selectedTagValue,
                  })
                  setFieldValue('selectedTagKey', '')
                  setFieldValue('selectedTagValue', '')
                  setSelectedTagKey('')
                  setSelectedTagValue('')
                  setSelectableTagValues([])
                }}
              >
                Add&nbsp;&nbsp;
                <i className="fas fa-user-plus" />
              </Button>
            </Grid>
          </Grid>
          <Grid
            container
            className="formRow"
            spacing={1}
            alignItems="center"
            justify="center"
            direction="row"
          >
            <Grid item xs={2}>
              <InputLabel htmlFor="selectedTags">Tags Added:</InputLabel>
            </Grid>
            <Grid item xs>
              <Paper variant="outlined" id="selectedTags">
                {values.tags.map((tag) => (
                  <StyledChip
                    key={`${tag.tagKey}_${tag.tagValue}`}
                    label={`${tag.tagKey}: ${tag.tagValue}`}
                    onDelete={() => {
                      values.tags.splice(values.tags.indexOf(tag), 1)
                      setFieldValue('selectedTagKey', '')
                      setFieldValue('selectedTagValue', '')
                      setSelectedTagKey('')
                      setSelectedTagValue('')
                      setSelectableTagValues([])
                    }}
                  />
                ))}
              </Paper>
            </Grid>
          </Grid>
        </Grid>
      </Grid>
      <div className="action-button-container">
        <Button type="submit" variant="contained" color="primary">
          Submit
        </Button>
      </div>
    </form>

  )
}

Form.propTypes = formPropTypes

const propTypes = {
  datalabFacade: PropTypes.instanceOf(DatalabFacade).isRequired,
  organisations: PropTypes.arrayOf(organisationsShape),
  tags: PropTypes.arrayOf(tagShape),
  user: appUserShape.isRequired,
  updating: PropTypes.bool,
  userData: existingUserShape,
  askForConfirmationListener: PropTypes.func.isRequired,
  showSnackbarInProgress: PropTypes.func.isRequired,
  showSnackbarSuccess: PropTypes.func.isRequired,
  startLoading: PropTypes.func.isRequired,
  stopLoading: PropTypes.func.isRequired,
}

const defaultProps = {
  organisations: undefined,
  tags: undefined,
  userData: undefined,
  updating: false,
}

/**
 * Using Formik to handle the form data movement
 * Using Yup to handle the form validation (works well with Formik)
react-form-validation-with-formik-and-yup-8b76bda62e10
 * @param root0
 * @param root0.datalabFacade
 * @param root0.organisations
 * @param root0.tags
 * @param root0.user
 * @param root0.updating
 * @param root0.userData
 * @param root0.askForConfirmationListener
 * @param root0.showSnackbarInProgress
 * @param root0.showSnackbarSuccess
 * @param root0.startLoading
 * @param root0.stopLoading
 */
export function NewUserScreen({
  datalabFacade, organisations, tags, user, updating, userData, askForConfirmationListener,
  showSnackbarInProgress, showSnackbarSuccess, startLoading, stopLoading,
}) {
  const [loading, setLoading] = useState(true)
  const [organisationData, setOrganisationData] = useState([])
  const [selectableTagKeys, setSelectableTagKeys] = useState([])
  const [error] = useState(null)
  const [open, setOpen] = useState(false)
  const navigate = useNavigate()

  if (error) { throw error }

  const getOrganisations = async () => {
    const selectableOrganisations = await datalabFacade.getOrganisations(user.pod)
    setOrganisationData(selectableOrganisations)
  }

  useEffect(() => {
    if (loading && tags && organisations && (!updating || userData)) {
      const tagKeys = tags.map((tag) => tag.tagKey)
      getOrganisations()
      setSelectableTagKeys(tagKeys)
      setLoading(false)
    }
  }, [userData, tags, organisations])

  const handleClickOpen = () => {
    setOpen(true)
  }

  const handleClose = () => {
    setOpen(false)
    navigate('/users')
  }

  const handleSubmitNew = (details) => {
    const newUser = {
      userName: details.userName + AAD_SUFFIX,
      name: details.displayName,
      email: details.email,
      phone: details.phone,
      organisation: details.organisation,
      emailOptOut: details.emailOptOut,
      podId: user.pod,
      tags: details.tags,
    }

    askForConfirmationListener(
      'Are you sure you want to create this User?',
      async () => {
        await datalabFacade.createUser(newUser)
        showSnackbarInProgress(`Create User (${newUser.userName}) in progress...`)
        handleClickOpen()
      }
    )
  }

  const handleSubmitUpdate = (details) => {
    // Since the backend accepts different values......
    const currentUser = {
      userName: details.userName,
      displayName: details.displayName,
      contactEmail: details.email,
      phone: details.phone,
      organisation: details.organisation,
      emailOptOut: details.emailOptOut,
      tags: details.tags,
    }

    askForConfirmationListener(
      'Are you sure you want to update this User?',
      async () => {
        await datalabFacade.updateUser(currentUser)
        showSnackbarSuccess(`Update User (${currentUser.userName}) succeeded`)
      },
      { redirect: `/users/${userData.userName}` }
    )
  }

  const defaultEndDate = new Date()
  defaultEndDate.setMonth(defaultEndDate.getMonth() + 2)

  if (open) {
    return (
      <Dialog
        open={open}
        onClose={handleClose}
        aria-labelledby="alert-dialog-title"
        aria-describedby="alert-dialog-description"
      >
        <DialogTitle id="alert-dialog-title">
          User Creation in Progress
        </DialogTitle>
        <DialogContent>
          <DialogContentText id="alert-dialog-description">
            An email will be sent to the user containing their login details.
          </DialogContentText>
        </DialogContent>
        <DialogActions>
          <Button onClick={handleClose}>Ok</Button>
        </DialogActions>
      </Dialog>
    )
  }

  return loading ? <SkeletonForm />
    : (
      <div className="details-grid">
        <Container maxWidth="md">
          <Formik
            validateOnChange={false}
            validateOnBlur={false}
            initialValues={{
              userName: updating ? userData.userName : '',
              displayName: updating ? userData.displayName : '',
              email: updating ? userData.contactEmail : '',
              phone: updating ? userData.phone : '',
              organisation: updating ? userData.organisation : '',
              emailOptOut: userData?.emailOptOut || false,
              tags: userData?.tags || [],
            }}
            onSubmit={(details) => {
              if (updating) {
                handleSubmitUpdate(details)
              } else {
                handleSubmitNew(details)
              }
            }}
            validationSchema={Yup.object().shape({
              userName: updating ? Yup.string()
                : Yup.string()
                  .matches(
                    /^\w+([.-]?\w+)*$/,
                    'May only contain characters, numbers, fullstops, and hyphens'
                  )
                  .test(
                    'isUnique',
                    'This username already exists',
                    async (userName) => {
                      if (!userName) return true
                      startLoading()
                      const [{ isUnique }, { isUnique: isUniqueEntra }] = await Promise.all([
                        datalabFacade.isUsernameUnique(userName + AAD_SUFFIX),
                        datalabFacade.isADUsernameUnique(userName + AAD_SUFFIX)])
                      stopLoading()
                      return isUnique && isUniqueEntra
                    }
                  )
                  .required()
                  .label('Username'),
              displayName: Yup.string()
                .matches(
                  /^\w+([!@#\\$%\\^\\&*\\)\\( +=._-]*\w+)*$/,
                  'May only contain characters, numbers and special characters, no tabs'
                )
                .min(2)
                .trim()
                .required()
                .label('Display name'),
              email: Yup.string().email()
                .required()
                .label('Contact email'),
              phone: Yup.string()
                .trim()
                .matches(/^([0-9 +()]*)$/, 'Only numbers, spaces, brackets and (+) allowed')
                .min(8)
                .max(16)
                .required()
                .label('Contact phone'),
              organisation: Yup.string()
                .min(2)
                .trim().required()
                .label('Organisation'),
              emailOptOut: Yup.boolean()
                .required()
                .label('Email opt out'),
            })}
          >
            {({
              values,
              touched,
              errors,
              handleChange,
              handleBlur,
              handleSubmit,
              setFieldValue,
            }) => (
              <Form
                values={values}
                touched={touched}
                errors={errors}
                handleChange={handleChange}
                handleBlur={handleBlur}
                handleSubmit={handleSubmit}
                setFieldValue={setFieldValue}
                updating={updating}
                organisationData={organisationData}
                tags={tags}
                selectableTagKeys={selectableTagKeys}
              />
            )}
          </Formik>
        </Container>
      </div>
    )
}

NewUserScreen.propTypes = propTypes
NewUserScreen.defaultProps = defaultProps
export default wrap(NewUserScreen)

