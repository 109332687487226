import React from 'react'
import flow from 'lodash.flow'
import {
  TextField,
  Grid,
  Button,
  IconButton,
  Container,
  Chip,
  Paper,
  Box,
  Stepper,
  Step,
  Typography,
  Divider,
  Alert,
  StepButton,
} from '@mui/material'
import Autocomplete from '@mui/material/Autocomplete'
import HelpIcon from '@mui/icons-material/Help'
import { AdapterDateFns } from '@mui/x-date-pickers/AdapterDateFns'
import { LocalizationProvider } from '@mui/x-date-pickers/LocalizationProvider'
import { styled } from '@mui/material/styles'
import HelpDialogListed from '../../components/HelpDialogListed'
import TransferList from '../../components/TransferList'
import SkeletonForm from '../../components/SkeletonForm'
import { UserFixableError } from '../../util/Exceptions'
import asBaseScreen from '../../screenWrappers/BaseScreen'
import ProjectDetailsForm from '../../components/ProjectDetailsForm'
import {
  withAllVmOptionsConfig, withAllOrganisations, withAllTags, withAllUsers, withAllProducts,
} from '../../screenWrappers/DataProviders'
import { withConfirmFeature, withLoadingFeature, withSnackbarsFeature } from '../../screenWrappers/Features'
import ComplexAttributeRow from '../../components/ComplexAttributeRow'
import ArrayAttributeRow from '../../components/ArrayAttributeRow'

// Screen requires the following data providers and features injected
const wrap = flow([
  withLoadingFeature,
  withConfirmFeature,
  withSnackbarsFeature,
  withAllVmOptionsConfig,
  withAllOrganisations,
  withAllUsers,
  withAllProducts,
  withAllTags,
  asBaseScreen,
])

const ScrollablePaper = styled(Paper)({
  margin: '20px',
  backgroundColor: '#fafafa',
  height: '50vh',
  overflowY: 'auto',
})

const UnscrollablePaper = styled(Paper)({
  margin: '20px',
  backgroundColor: '#fafafa',
})

const SectionHeader = styled('div')({
  padding: '10px',
  '& h3': {
    display: 'inline',
    margin: '5px',
  },
})

/**
 * Using Formik to handle the form data movement
 * Using Yup to handle the form validation (works well with Formik)
 *
 * @see https://hackernoon.com/react-form-validation-with-formik-and-yup-8b76bda62e10
 *
 * Handles both new, cloning and updating scenarios, updating and cloning boolean prop passed to component via parent component (see CloneProjectScreen, UpdatePRojectScreen)
 */
export class NewProjectScreen extends React.Component {
  constructor(props) {
    super(props)
    this.datalabFacade = props.datalabFacade
    this.state = {
      loading: true,
      showingVmSizeHelp: false,
      vmSizeOptions: undefined,
      selectableTagKeys: undefined,
      selectableTagValues: [],
      selectedTagKey: null,
      selectedTagValue: null,
      selectableOrganisations: undefined,
      activeStep: 0,
      basicsValues: undefined,
      users: [],
      selectedVmSize: undefined,
      selectedVmType: undefined,
      selectedVmVersion: undefined,
      products: [],
      selectableUsers: undefined,
      selectableProducts: undefined,
    }
    this.formRef = React.createRef()
    this.steps = [
      {
        stepName: 'Basics',
        nextCallback: this.handleSubmitBasicsForm,
      },
      {
        stepName: 'Users',
        nextCallback: this.handleMoveToStep,
      },
      {
        stepName: 'Products',
        nextCallback: this.handleMoveToStep,
      },
      {
        stepName: 'Review',
        nextCallback: this.handleSubmitFinal,
      },
    ]
  }

  async componentDidUpdate(prevProps) {
    if (this.state.loading && this.props.vmOptionsConfig && this.props.organisations && this.props.tags && this.props.users && this.props.products && ((this.props.updating || this.props.cloning) ? this.props.project : true)) {
      const selectableOrganisations = this.props.organisations.map((o) => o.organisationName)
      let selectableUsers = this.props.users
      let selectableProducts = this.props.products.map((p) => p.name)

      const selectableTagKeys = this.props.tags.map((tag) => tag.tagKey)
      const vmSizeOptions = this.props.vmOptionsConfig.filter((x) => x.configType === 'vmSize')
      const vmTypeOptions = this.props.vmOptionsConfig.filter((x) => x.configType === 'vmType')
      const vmVersionOptions = this.props.vmOptionsConfig.filter((x) => x.configType === 'vmVersion' && x.availableForRebuild)

      selectableUsers = selectableUsers.filter((user) => user.status === 'ACTIVE')

      const defaultVmType = vmTypeOptions.find((vmTypeOption) => vmTypeOption.type === 'standard')
      let defaultVmSize = vmSizeOptions.find((vmSizeOption) => vmSizeOption.type === 'small')
      const defaultVmVersion = vmVersionOptions.find((e) => e.latest)

      const defaultEndDate = new Date()
      defaultEndDate.setMonth(defaultEndDate.getMonth() + 2)

      const prefix = this.props.user.pod === 'ABS' ? '' : this.props.user.pod.substring(0, 3).toLowerCase()

      const initialBasicsValues = {
        uuid: '',
        projectName: '',
        projectStorageSize: 1,
        projectStorageSizeDatalake: 1,
        description: '',
        projectContact: '',
        contactEmail: '',
        contactPhone: '',
        endDate: defaultEndDate,
        defaultVMsize: vmSizeOptions[0].type,
        organisation: '',
        tags: [],
      }

      let intialProducts = []
      let initialUsers = []

      if (this.props.cloning) {
        // set the initial values to the existing project
        Object.keys(initialBasicsValues).forEach((k) => {
          initialBasicsValues[k] = this.props.project[k]
          if (k === 'uuid' && this.props.project.podId !== 'ABS') {
            // remove the 3 letter prefix from uuid if project is a non-ABS project being cloned
            const noPrefixUuid = this.props.project[k].slice(3)
            initialBasicsValues[k] = noPrefixUuid
          }
        })
        defaultVmSize = vmSizeOptions.find((vmSizeOption) => vmSizeOption.type === this.props.project.defaultVMsize)
        if (!defaultVmSize) { throw new UserFixableError('The default Virtual Machine size for this Project is no longer available, please update in Project details') }

        intialProducts = await this.datalabFacade.getProjectsProducts(this.props.project.uuid)
        initialUsers = this.props.project.users.map((u) => selectableUsers.find((su) => su.userName === u)).filter((u) => u)
        selectableProducts = selectableProducts.filter((p) => !intialProducts.includes(p))
      }

      this.setState({
        vmSizeOptions,
        vmTypeOptions,
        vmVersionOptions,
        selectableTagKeys,
        selectableOrganisations,
        loading: false,
        defaultVmType,
        defaultVmSize,
        selectedVmType: defaultVmType,
        selectedVmSize: defaultVmSize,
        selectedVmVersion: defaultVmVersion,
        basicsValues: initialBasicsValues,
        selectableUsers,
        products: intialProducts,
        users: initialUsers,
        selectableProducts,
        prefix,
      })
    }
  }

  handleSubmitBasicsForm = () => {
    if (this.formRef.current) {
      this.formRef.current.handleSubmit()
    }
  }

  handleSubmitFinal = () => {
    this.props.updating
      ? this.props.askForConfirmationListener(
        'Are you sure you want to update this Project?',
        async () => {
          await this.props.datalabFacade.updateProject(this.state.basicsValues)
          this.props.showSnackbarSuccess(`Update Project (${this.props.project.uuid}) succeeded`)
        },
        { redirect: `/projects/${this.props.project.uuid}` }
      )
      : this.props.askForConfirmationListener(
        'Are you sure you want to create this Project?',
        async () => {
          const values = this.state.basicsValues
          values.podId = this.props.user.pod
          values.users = this.state.users.map((u) => u.userName)
          values.products = this.state.products
          if (values.users && values.users.length) {
            values.selectedVm = {
              type: this.state.selectedVmType.type,
              size: this.state.selectedVmSize.type,
              version: this.state.selectedVmVersion.version,
            }
          }

          values.uuid = this.props.user.pod === 'ABS' ? values.uuid : this.state.prefix + values.uuid
          await this.props.datalabFacade.createProject(values)
          this.props.showSnackbarInProgress(`Create Project (${values.uuid}) in progress...`)
          if (values.users && values.users.length) {
            this.props.showSnackbarInProgress(`Assign Users (${values.users.length} Users -> ${values.uuid}) will be queued after the project is created. Please check the action log.`)
          }
          if (values.products && values.products.length) {
            this.props.showSnackbarInProgress(`Link Products (${values.products.length} Products -> ${values.uuid}) will be queued after the project is created. Please check the action log.`)
          }
        },
        { redirect: '/projects' }
      )
  }

  handleBack = () => {
    this.setState({ activeStep: this.state.activeStep - 1, showingValidationErrors: false })
  }

  handleSubmit = (values) => {
    this.setState({ basicsValues: values, activeStep: this.state.nextStep })
  }

  handleMoveToStep = (step) => {
    // if on basics form we need to validate the form before moving on
    if (this.state.activeStep === 0) {
      this.setState({ nextStep: step })
      this.handleSubmitBasicsForm()
    } else {
      this.setState({ activeStep: step })
    }
  }

  render() {
    return (
      this.state.loading
        ? <SkeletonForm />
        : (
          <LocalizationProvider dateAdapter={AdapterDateFns}>
            <Container maxWidth="md">
              <Box sx={{ paddingBottom: '20px', width: '100%' }}>
                <Stepper nonLinear activeStep={this.state.activeStep}>
                  {this.steps.map((step, index) => {
                    const stepProps = {}
                    const labelProps = {}
                    return (
                      <Step key={step.stepName} {...stepProps}>
                        <StepButton onClick={() => this.handleMoveToStep(index)} {...labelProps}>
                {step.stepName}
              </StepButton>
                      </Step>
                    )
                  })}
                </Stepper>
              </Box>
            </Container>
            <Divider variant="middle" sx={{ borderColor: 'rgba(0, 0, 0, 1)' }} />
            <div className="details-grid">

              {this.state.activeStep === 0

                            && (
                            <ProjectDetailsForm
                              vmSizeOptions={this.state.vmSizeOptions}
                              initialValues={this.state.basicsValues}
                              selectableUsers={this.state.selectableUsers}
                              selectableOrganisations={this.state.selectableOrganisations}
                              selectableTags={this.props.tags}
                              formRef={this.formRef}
                              handleSubmit={this.handleSubmit.bind(this)}
                              datalabFacade={this.props.datalabFacade}
                              podId={this.props.user.pod}
                            />
                            )}

              {/* USERS TAB */}

              {
                            this.state.activeStep === 1
                            && (!this.state.selectableUsers ? <SkeletonForm />
                              : (
                                <Container maxWidth="md">
                                  {this.state.showingVmSizeHelp
                                        && (
                                        <HelpDialogListed
                                          open={this.state.showingVmSizeHelp}
                                          callback={() => this.setState({ showingVmSizeHelp: false })}
                                          title="Available Virtual Machine sizes"
                                          headers={[
                                            { formatted: 'Type', key: 'displayName' },
                                            { formatted: 'Description', key: 'description' },
                                          ]}
                                          rows={this.state.vmSizeOptions}
                                          rowKey="type"
                                        />
                                        )}

                                  <Typography variant="body1" component="h2">
                                    Select VM Size
                                    <IconButton
                                      onClick={() => this.setState({ showingVmSizeHelp: true })}
                                      aria-label="virtual machine help"
                                      size="large"
                                    >
                                      <HelpIcon />
                                    </IconButton>
                                  </Typography>

                                  <Autocomplete
                                    id="autocomplete-vmsize"
                                    options={this.state.vmSizeOptions}
                                    getOptionLabel={(option) => option.displayName}
                                    defaultValue={this.state.defaultVmSize}
                                    value={this.state.selectedVmSize}
                                    onChange={(event, value) => {
                                      this.setState({ selectedVmSize: value })
                                    }}
                                    renderInput={(params) => (
                                      <TextField {...params} label="VM Size" variant="outlined" fullWidth />
                                    )}
                                  />

                                  {this.state.showingVmTypeHelp
                                        && (
                                        <HelpDialogListed
                                          open={this.state.showingVmTypeHelp}
                                          callback={() => this.setState({ showingVmTypeHelp: false })}
                                          title="Available Virtual Machine types"
                                          headers={[
                                            { formatted: 'Type', key: 'displayName' },
                                            { formatted: 'Description', key: 'description' },
                                          ]}
                                          rows={this.state.vmTypeOptions}
                                          rowKey="type"
                                        />
                                        )}

                                  <Typography variant="body1" component="h2">
                                    Select VM Type
                                    <IconButton
                                      onClick={() => this.setState({ showingVmTypeHelp: true })}
                                      aria-label="virtual machine help"
                                      size="large"
                                    >
                                      <HelpIcon />
                                    </IconButton>
                                  </Typography>

                                  <Autocomplete
                                    id="autocomplete-vmtype"
                                    disabled={!this.state.selectedVmSize}
                                    options={this.state.vmTypeOptions}
                                    getOptionLabel={(option) => option.displayName}
                                    value={this.state.selectedVmType}
                                    onChange={(event, value) => {
                                      this.setState({ selectedVmType: value })
                                    }}
                                    renderInput={(params) => (
                                      <TextField {...params} label="VM Type" variant="outlined" fullWidth />

                                    )}
                                  />
                                  <Typography variant="caption" style={{ color: 'red' }}>
                                    Refer to user specifications, otherwise set as
                                    {' '}
                                    <b>standard</b>
                                  </Typography>
                                  {this.state.showingVmVersionHelp
                                        && (
                                        <HelpDialogListed
                                          open={this.state.showingVmVersionHelp}
                                          callback={() => this.setState({ showingVmVersionHelp: false })}
                                          title="Available Virtual Machine Desktop Versions"
                                          headers={[
                                            { formatted: 'Version', key: 'version' },
                                            { formatted: 'Included Software', key: 'softwareText' },
                                            { formatted: 'Notes', key: 'deprecationText' },
                                          ]}
                                          rows={this.state.vmVersionOptions}
                                          rowKey="type"
                                          message="Desktop versions are released annually and include the most up to date software (security patches are always applied to all desktop versions), plan your migration to the newest version: Previous year versions are no longer available shortly after a new version is released. New major software versions can introduce breaking changes, issues should be reported prior to the previous years version being removed, so give yourself enough time to try out the new version."
                                        />
                                        )}
                                  <Typography variant="body1" component="h2">
                                    Select VM Version
                                    <IconButton
                                      onClick={() => this.setState({ showingVmVersionHelp: true })}
                                      aria-label="virtual machine version help"
                                      size="large"
                                    >
                                      <HelpIcon />
                                    </IconButton>
                                  </Typography>
                                  <Autocomplete
                                    id="autocomplete-vmversion"
                                    options={this.state.vmVersionOptions}
                                    getOptionLabel={(option) => `${option.version}${option.latest ? ' (latest)' : ''}`}
                                    value={this.state.selectedVmVersion}
                                    onChange={(event, value) => {
                                      this.setState({ selectedVmVersion: value })
                                    }}
                                    renderInput={(params) => (
                                      <TextField {...params} label="VM Version" variant="outlined" fullWidth />

                                    )}
                                  />
                                  <Typography variant="caption" style={{ color: 'red' }}>
                                    Refer to user specifications, otherwise set as
                                    {' '}
                                    <b>latest</b>
                                  </Typography>
                                  <Typography variant="body1" component="h2" fontWeight={500}>
                                    Select the Users to assign to
                                    {' '}
                                    {this.state.basicsValues.projectName}
                                    {' '}
                                    [
                                    {this.state.basicsValues.uuid}
                                    ]
                                  </Typography>
                                  <Autocomplete
                                    id="autocomplete-users"
                                    options={this.state.selectableUsers}
                                    value={this.state.users}
                                    variant="outlined"
                                    multiple
                                    filterSelectedOptions
                                    getOptionLabel={(option) => `${option.displayName} (${option.userName}) ${option.organisation ? (`(${option.organisation})`) : ''}`}
                                    onChange={(event, values) => this.setState({ users: values })}
                                    renderInput={(params) => (
                                      <TextField {...params} label="Users" fullWidth />
                                    )}
                                  />
                                </Container>
                              )
                            )
                        }

              {/* PRODUCTS TAB */}
              {
                            this.state.activeStep === 2
                            && (!this.state.selectableProducts ? <SkeletonForm />
                              : (
                                <Container maxWidth="md">
                                  <>

                                    <Typography variant="body1" component="h2" style={{ marginLeft: '20px' }}>
                                      Select Products to Link with Project:
                                      {' '}
                                      {this.state.basicsValues.uuid}
                                    </Typography>

                                    <div style={{ overflow: 'auto', padding: '20px' }}>
                                      <TransferList
                                        {...this.props}
                                        choicesLabel="Unlinked Products"
                                        chosenLabel="Linked Products"
                                        searchBoxLabel="Search unlinked Products"
                                        searchBoxSelectedLabel="Search linked Products"
                                        selectedMessage="Selected Products here will allow Analysts in this Project read-only access to the Products via their desktop"
                                        leftData={this.state.selectableProducts}
                                        rightData={this.state.products}
                                        setChosenDataCallback={(data) => this.setState({ products: data })}
                                        setLeftDataCallback={(data) => this.setState({ selectableProducts: data })}
                                      />
                                    </div>
                                  </>
                                </Container>
                              )
                            )
                        }

              {/* REVIEW TAB */}
              {
                            this.state.activeStep === 3
                            && (
                            <Container maxWidth="xl">
                              <Grid container direction="row" alignItems="stretch" className="details-grid">
                                <Grid item xs={12} lg={6}>
                                  <UnscrollablePaper variant="outlined">
                                    <SectionHeader>
                                      <Typography variant="h6" component="h3">
                                        Basic Attributes
                                      </Typography>
                                    </SectionHeader>

                                    <ComplexAttributeRow keyValuePairs={[
                                      { header: 'Project Name', value: this.state.basicsValues.projectName },
                                      { header: 'Project ID', value: this.props.user.pod !== 'ABS' ? this.state.prefix + this.state.basicsValues.uuid : this.state.basicsValues.uuid },
                                      { header: 'Project Storage Size (TiB)', value: this.state.basicsValues.projectStorageSize },
                                      { header: 'Data Lake Storage Size (TiB)', value: this.state.basicsValues.projectStorageSizeDatalake },
                                      { header: 'Description', value: this.state.basicsValues.description },
                                      { header: 'End Date', value: this.state.basicsValues.endDate, date: true },
                                      { header: 'Default VM Size', value: this.state.basicsValues.defaultVMsize },
                                    ]}
                                    />

                                  </UnscrollablePaper>
                                </Grid>
                                <Grid item xs={12} lg={6}>
                                  <UnscrollablePaper variant="outlined">
                                    <SectionHeader>
                                      <Typography variant="h6" component="h3">
                                        Lead Researcher
                                      </Typography>
                                    </SectionHeader>

                                    <ComplexAttributeRow keyValuePairs={[
                                      { header: 'Contact Name', value: this.state.basicsValues.projectContact },
                                      { header: 'Contact Email', value: this.state.basicsValues.contactEmail },
                                      { header: 'Contact Phone', value: this.state.basicsValues.contactPhone },
                                      { header: 'Organisation', value: this.state.basicsValues.organisation },

                                    ]}
                                    />

                                  </UnscrollablePaper>
                                </Grid>
                                <Grid item xs={12} lg={6} xl={4}>
                                  <ScrollablePaper variant="outlined">
                                    <SectionHeader>
                                      <Typography variant="h6" component="h3">
                                        Project Tags
                                      </Typography>
                                    </SectionHeader>
                                    {this.state.basicsValues.tags
                                      ? this.state.basicsValues.tags.map((tag) => (
                                        <Chip
                                          sx={{ margin: '5px', padding: '5px' }}
                                          key={`${tag.tagKey}-${tag.tagValue}`}
                                          tabIndex={-1}
                                          label={`${tag.tagKey}: ${tag.tagValue}`}
                                        />
                                      ))
                                      : []}
                                  </ScrollablePaper>
                                </Grid>
                                <Grid item xs={12} lg={6} xl={4}>
                                  <ScrollablePaper variant="outlined">
                                    <SectionHeader>
                                      <Typography variant="h6" component="h3">
                                        Users (
                                        {this.state.users.length}
                                        )
                                      </Typography>
                                    </SectionHeader>

                                    {this.state.users && this.state.users.length > 0
                                                && (
                                                <Alert severity="info">
                                                  These users will be assigned
                                                  {' '}
                                                  {this.state.selectedVmSize.displayName}
                                                  {' '}
                                                  {this.state.selectedVmType.displayName}
                                                  {' '}
                                                  {this.state.selectedVmVersion.version}
                                                  {' '}
                                                  virtual machines automatically
                                                </Alert>
                                                )}

                                    <ArrayAttributeRow
                                      header="Users"
                                      columnHeaders={[{ name: 'Username', key: 'userName' }]}
                                      keyValuePairs={this.state.users.map((u) => ({ userName: u.userName }))}
                                      rowKey="userName"
                                    />

                                  </ScrollablePaper>
                                </Grid>
                                <Grid item xs={12} lg={6} xl={4}>
                                  <ScrollablePaper variant="outlined">
                                    <SectionHeader>
                                      <Typography variant="h6" component="h3">
                                        Products
                                        {' '}
                                        {this.state.products ? `(${this.state.products.length})` : ''}
                                      </Typography>
                                    </SectionHeader>

                                    <ArrayAttributeRow
                                      header="Products"
                                      simpleArray
                                      keyValuePairs={this.state.products.map((p) => ({ name: p }))}
                                      columnHeaders={[{ name: 'Product Identifier', key: 'name' }]}
                                      rowKey="name"
                                    />
                                  </ScrollablePaper>
                                </Grid>
                              </Grid>

                            </Container>
                            )
                        }
            </div>
            <Divider variant="middle" sx={{ borderColor: 'rgba(0, 0, 0, 1)' }} />
            <Container maxWidth="md">
              <Box sx={{ width: '100%' }}>
                <>
                  <Box sx={{ display: 'flex', flexDirection: 'row' }}>
                    {this.state.activeStep !== (this.steps.length - 1)
                                        && (
                                        <Button
                                          sx={{ margin: '20px' }}
                                          variant="contained"
                                          onClick={() => {
                                            this.setState({ nextStep: this.steps.length - 1 })
                                            this.steps[this.state.activeStep].nextCallback(this.steps.length - 1)
                                          }}
                                        >
                                          Review + create
                                        </Button>
                                        )}
                    <Box sx={{ flex: '1 1 auto' }} />
                    <Button
                      variant="outlined"
                      disabled={this.state.activeStep === 0}
                      onClick={this.handleBack}
                      sx={{ margin: '20px' }}
                    >
                      Back
                    </Button>
                    <Button
                      sx={{ margin: '20px' }}
                      variant="outlined"
                      onClick={() => {
                        this.setState({ nextStep: this.state.activeStep + 1 })
                        this.steps[this.state.activeStep].nextCallback(this.state.activeStep + 1)
                      }}
                    >
                      {this.state.activeStep === this.steps.length - 1 ? 'Finish' : `Next: ${this.steps[this.state.activeStep + 1].stepName}`}
                    </Button>
                  </Box>
                </>
              </Box>
            </Container>
          </LocalizationProvider>
        )
    )
  }
}

export default wrap(NewProjectScreen)
