import React from 'react'
import PropTypes from 'prop-types'
import TableCell from '@mui/material/TableCell'
import TableContainer from '@mui/material/TableContainer'
import TableRow from '@mui/material/TableRow'
import {
  Typography, TableHead, TableBody, Table,
} from '@mui/material'
import MenuItem from '@mui/material/MenuItem'
import FormControl from '@mui/material/FormControl'
import Select from '@mui/material/Select'
import InputLabel from '@mui/material/InputLabel'
import Box from '@mui/material/Box'
import moment from 'moment'
import { licenseSummariesShape, timeRangeShape } from '../propTypeShapes'

const propTypes = {
  setTableTimeRange: PropTypes.func.isRequired,
  pastData: licenseSummariesShape.isRequired,
  selectedMonth: timeRangeShape.isRequired,
  months: PropTypes.arrayOf(timeRangeShape).isRequired,
  loading: PropTypes.bool.isRequired,
}
function SasSummary({
  setTableTimeRange, pastData, selectedMonth, months, loading,
}) {
  const handleChangeTable = (event) => {
    setTableTimeRange(event.target.value)
  }
  if (!pastData) {
    return false
  }

  const { startDate, endDate } = selectedMonth

  const formattedData = pastData.map((item) => ({
    max: Math.max(...item.data.map((maxNum) => maxNum.max)),
    ...item,
  }))

  let displayData = null
  if (loading) {
    displayData = 'Loading...'
  } else if (pastData.length) {
    displayData = (
      <TableContainer className="summary-container" maxwidth="false">
        <Table>
          <TableHead>
            <TableCell align="center">Pod</TableCell>
            <TableCell align="center">Max</TableCell>
          </TableHead>
          <TableBody>
            {formattedData.map((item, index) => (
              <TableRow key={index.id}>
                <TableCell align="center">{item.podId}</TableCell>
                <TableCell align="center">{item.max}</TableCell>
              </TableRow>
            ))}
          </TableBody>
        </Table>
      </TableContainer>
    )
  } else {
    displayData = (<div style={{ textAlign: 'center', marginTop: '20px' }}>No data to display</div>)
  }
  return (
    <Box
      marginBottom="5px"
      display="flex"
      flexDirection="column"
      alignItems="center"
    >
      <Typography variant="h6">
        {'Summary of Data for Period '}
        {moment(startDate).format('DD/MM/YYYY')}
        {' to '}
        {moment(endDate).format('DD/MM/YYYY')}
      </Typography>
      <FormControl sx={{ m: 1, minWidth: 120 }} size="small">
        <InputLabel id="months">Month</InputLabel>
        <Select
          label="Month"
          value={selectedMonth}
          onChange={handleChangeTable}
        >
          {months.map((m) => (
            <MenuItem key={m.label} value={m}>{m.label}</MenuItem>
          ))}
        </Select>
      </FormControl>
      {displayData}
    </Box>
  )
}
SasSummary.propTypes = propTypes
export default SasSummary
