import PropTypes from 'prop-types'

export const tableHeadersProps = PropTypes.arrayOf(PropTypes.shape(
  {
    id: PropTypes.string.isRequired,
    type: PropTypes.oneOf(
      [
        'array',
        'array-id-link',
        'object',
        'id-link',
        'tag-array',
        'tag-value-array',
        'string',
        'long-string',
        'string-title-case',
        'status',
        'date-time',
        'date-time-verbose',
        'duration',
        'boolean',
        'number',
      ]
    ).isRequired,
    label: PropTypes.string.isRequired,
    statusEnums: PropTypes.arrayOf(PropTypes.string),
    /**
     * Make the row value a link. The resultant link is the linkTo path
     * specified which should be relative to the root of the app, with the row
     * value in the next segment. I.e. if linkTo is 'users' the resultant link
     * will be /users/<the row value>
     */
    linkTo: PropTypes.string,
    /** Hide the header */
    hidden: PropTypes.bool,
    /**
     * Path to append to the link after the row value. I.e. if linkTo is 'users'
     * and linkToSuffix is 'update' the resultant link will be /users/<the
     * row value>/update
     */
    linkToSuffix: PropTypes.func,
  }
))

export const filterProps = PropTypes.shape({
  property: PropTypes.string.isRequired,
  condition: PropTypes.string.isRequired,
  value: PropTypes.string.isRequired,
  displayValue: PropTypes.string,
})

export const orderProps = PropTypes.shape({
  property: PropTypes.string.isRequired,
  direction: PropTypes.string.isRequired,
})

export const customActionProps = PropTypes.shape({
  title: PropTypes.string.isRequired,
  callback: PropTypes.func.isRequired,
})
