import React from 'react';
import flow from 'lodash.flow';
import {
    FormControl,
    InputLabel,
    Grid,
    FormHelperText,
    Button,
    Container,
    Checkbox,
} from "@mui/material";
import { Formik } from 'formik';
import * as Yup from 'yup';
import asBaseScreen from '../../screenWrappers/BaseScreen';
import { withSnackbarsFeature, withConfirmFeature, withLoadingFeature } from '../../screenWrappers/Features';
import { withUser } from '../../screenWrappers/DataProviders';
import SkeletonForm from '../../components/SkeletonForm';

//Screen requires the following data providers and features injected
const wrap = flow([
    withLoadingFeature,
    withConfirmFeature,
    withSnackbarsFeature,
    withUser,
    asBaseScreen
])

/**
 * Using Formik to handle the form data movement
 * Using Yup to handle the form validation (works well with Formik)
 * 
 * @see https://hackernoon.com/react-form-validation-with-formik-and-yup-8b76bda62e10
 * 
 */
export class EditAccountSettingsScreen extends React.Component {


    constructor(props) {
        super(props);
        this.state = {};
    }


    handleSubmitUpdate = (values) => {

        //Since the backend accepts different values......
        const user = {
            userName: this.props.userData.userName,
            emailOptOut: values.emailOptOut
        }


        this.props.askForConfirmationListener('Are you sure you want to update these settings?',
            async () => {
                await this.props.datalabFacade.updateUserSelfService(user)
                this.props.showSnackbarSuccess(`Update account settings succeeded`);
            },
            { redirect: `/MyAccount/${this.props.userData.userName}` }
        )
    }

    render() {

        return !this.props.userData ? <SkeletonForm /> :
        <Container maxWidth="md">

            <Formik
                validateOnChange={false}
                validateOnBlur={false}
                initialValues={{
                    emailOptOut: this.props.userData.emailOptOut ? this.props.userData.emailOptOut : false
                }}

                onSubmit={(values) => {
                    this.handleSubmitUpdate(values)
                }}


                validationSchema={Yup.object().shape({
                    emailOptOut: Yup.boolean()
                        .required()
                        .label('Email opt out')
                })}
            >
                {(props) => {

                    const {
                        values,
                        touched,
                        errors,
                        handleSubmit,
                        setFieldValue
                    } = props;

                    return (
                        <form onSubmit={handleSubmit}>
                            <Grid container spacing={3} alignItems="center" direction="column">
                                <Grid container item className="formRow" spacing={1} alignItems="center" justifyContent="center" direction="row">
                                   
                                        <Grid item xs={6}>
                                            <InputLabel style={{ float: "right" }} htmlFor="emailOptOut">Opt out of reminder emails</InputLabel>
                                        </Grid>
                                        <Grid item xs={6}>
                                            <FormControl>
                                                <Checkbox
                                                    style={{ width: '42px' }}
                                                    checked={values.emailOptOut}
                                                    onChange={e => setFieldValue('emailOptOut', !values.emailOptOut)}
                                                    aria-describedby="helperEmailOptOut"
                                                />
                                                <FormHelperText id="helperEmailOptOut"
                                                    error={errors.emailOptOut && touched.emailOptOut}>
                                                    {(errors.emailOptOut && touched.emailOptOut) ?
                                                        errors.emailOptOut :
                                                        "Don't send automatic reminder emails (when your machine is going to be shut down for example)"
                                                    }
                                                </FormHelperText>
                                            </FormControl>
                                        </Grid>
                                    
                                </Grid>
                            </Grid>

                            <div className="action-button-container">

                                <Button type="submit" variant="contained" color='primary'>
                                    Save
                                    </Button>
                            </div>

                        </form>
                    );
                }}
            </Formik>
        </Container>;

    }

}

export default wrap(EditAccountSettingsScreen)

