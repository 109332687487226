import React from 'react'
import Button from '@mui/material/Button'
import Dialog from '@mui/material/Dialog'
import DialogActions from '@mui/material/DialogActions'
import DialogContent from '@mui/material/DialogContent'
import DialogTitle from '@mui/material/DialogTitle'
import Table from '@mui/material/Table'
import TableBody from '@mui/material/TableBody'
import TableCell from '@mui/material/TableCell'
import TableHead from '@mui/material/TableHead'
import TableRow from '@mui/material/TableRow'
import Paper from '@mui/material/Paper'
import Typography from '@mui/material/Typography'
import { Link } from '@mui/material'

// A dialog that display information in a list
export default function HelpDialog(props) {
  const [open] = React.useState(props.open)

  function handleYes() {
    props.callback(true)
  }

  const headers = props.headers.map((header) => <TableCell key={header.key}>{header.formatted}</TableCell>)

  return (
    <div>
      <Dialog
        open={open}
        aria-labelledby="alert-dialog-title"
        aria-describedby="alert-dialog-description"
      >
        <DialogTitle id="alert-dialog-title">{props.title}</DialogTitle>
        <DialogContent>
          <Typography variant="caption" component="p">
            {props.message}
          </Typography>
          <Paper style={{ marginTop: '20px' }}>
            <Table aria-label="simple table">
              <TableHead>
                <TableRow key="header">
                  {headers}
                </TableRow>
              </TableHead>
              <TableBody>
                {props.rows.map((row) => (
                  <TableRow key={row[props.rowKey]}>

                    {props.headers.map((header) => (

                      <TableCell key={row[props.rowKey] + header.key} component="th" scope="row">

                        {header.linkKey && row[header.linkKey]
                          ? (
                            <Link
                              target="_blank"
                              href={row[header.linkKey]}
                            >
                              {row[header.key].toString()}
                            </Link>
                          )
                          : <p>{row[header.key].toString()}</p>}
                      </TableCell>
                    ))}

                  </TableRow>
                ))}
              </TableBody>
            </Table>
          </Paper>
        </DialogContent>
        {!props.hideButtons
          && (
          <DialogActions>
            <Button onClick={handleYes} color="primary" autoFocus>
              OK
            </Button>
          </DialogActions>
          )}
      </Dialog>
    </div>
  )
}
