import React, { useState } from 'react'
import PropTypes from 'prop-types'
import {
  LineChart, Line, XAxis, YAxis, CartesianGrid, Tooltip, Legend, ResponsiveContainer,
} from 'recharts'
import {
  Typography, TableContainer, TableRow, TableCell, Table, TableHead, TableBody,
} from '@mui/material'
import Box from '@mui/material/Box'
import MenuItem from '@mui/material/MenuItem'
import FormControl from '@mui/material/FormControl'
import Select from '@mui/material/Select'
import InputLabel from '@mui/material/InputLabel'
import { licenseSummariesShape, timeRangeShape } from '../propTypeShapes'

const lineGraphColours = [
  '#8338ec', '#fb5607', '#3a86ff', '#ffbe0b', '#7cb518', '#ff006e', '#faf255', '#ffaf87',
]

const propTypes = {
  setTimeRange: PropTypes.func.isRequired,
  setTableTimeRange: PropTypes.func.isRequired,
  pastData: licenseSummariesShape.isRequired,
  pastDataTable: licenseSummariesShape.isRequired,
  timeRange: timeRangeShape.isRequired,
  timeRanges: PropTypes.arrayOf(timeRangeShape).isRequired,
  selectedMonth: timeRangeShape.isRequired,
  months: PropTypes.arrayOf(timeRangeShape).isRequired,
}
function PastSasGraph({
  setTimeRange,
  setTableTimeRange,
  pastData,
  pastDataTable,
  timeRange,
  timeRanges,
  selectedMonth,
  months,
}) {
  const [podId, setPodId] = useState('ABS')

  const handleChangeGraph = (event) => {
    setTimeRange(event.target.value)
  }

  const handleChangeTable = (event) => {
    setTableTimeRange(event.target.value)
  }

  const handleChangePodId = (event) => {
    setPodId(event.target.value)
  }
  if (!pastDataTable || !pastData) {
    return false
  }

  const dateOptions = {
    day: 'numeric',
    month: 'numeric',
    year: 'numeric',
  }

  const startDate = new Date(timeRange.startDate)
  const endDate = new Date(timeRange.endDate)

  const tableData = pastDataTable.find((pod) => pod.podId === podId) || { data: [] }

  return (
    <Box
      marginBottom="5px"
      display="flex"
      flexDirection="column"
      alignItems="center"
    >
      <Typography variant="h6">Max Number of Licenses Used By Each Pod Per Day</Typography>
      <div className="past-graph-container">
        <ResponsiveContainer width="100%" height="100%">
          <LineChart
            width={600}
            height={400}
            margin={{
              top: 10, right: 50, left: 5, bottom: 10,
            }}
          >
            <CartesianGrid strokeDasharray="3 3" />
            <XAxis
              dataKey="date"
              type="category"
              allowDuplicatedCategory={false}
              fontSize={13}
              padding={{ left: 15, right: 15 }}
            />
            <YAxis dataKey="max" fontSize={13} />
            <Tooltip />
            <Legend />
            {pastData.map((s, index) => (
              <Line
                dataKey="max"
                type="monotone"
                isAnimationActive={false}
                data={s.data}
                name={s.podId}
                key={s.podId}
                stroke={pastData.length <= 1 ? '#8884d8' : lineGraphColours[index % lineGraphColours.length]}
              />
            ))}
          </LineChart>
        </ResponsiveContainer>
        <div className="date-range">
          <FormControl variant="outlined" sx={{ ml: '10px', width: '200px' }}>
            <Select
              id="timeRange"
              value={timeRange}
              onChange={handleChangeGraph}
            >
              {timeRanges.map((t) => (
                <MenuItem key={t.label} value={t} label={t.label}>{t.label}</MenuItem>
              ))}
            </Select>
          </FormControl>
          <Typography variant="body2" marginTop={2}>
            {`Graph data generated for period: ${startDate.toLocaleDateString('en-GB', dateOptions)} 
            to ${endDate.toLocaleDateString('en-GB', dateOptions)}`}
          </Typography>
        </div>
      </div>
      <div className="date-pod-selector">
        <FormControl sx={{ m: 1, minWidth: 120 }} size="small">
          <InputLabel id="pod-ids">Pod</InputLabel>
          <Select
            label="Pod"
            defaultValue={podId}
            onChange={handleChangePodId}
          >
            {pastData.map((pod) => (<MenuItem value={pod.podId} key={pod.podId}>{pod.podId}</MenuItem>))}
          </Select>
        </FormControl>
        <FormControl sx={{ m: 1, minWidth: 120 }} size="small">
          <InputLabel id="months">Month</InputLabel>
          <Select
            label="Month"
            value={selectedMonth}
            onChange={handleChangeTable}
          >
            {months.map((m) => (
              <MenuItem key={m.label} value={m}>{m.label}</MenuItem>
            ))}
          </Select>
        </FormControl>
      </div>
      <TableContainer>
        <Table aria-label="past-sas-licenses">
          <TableHead>
            <TableRow>
              <TableCell align="center">Date</TableCell>
              <TableCell align="center">Max Licenses Used</TableCell>
            </TableRow>
          </TableHead>
          <TableBody>
            {tableData.data.map((d) => (
              <TableRow key={d.date}>
                <TableCell align="center">{d.date}</TableCell>
                <TableCell align="center">{d.max}</TableCell>
              </TableRow>
            ))}
          </TableBody>
        </Table>
      </TableContainer>
    </Box>
  )
}

PastSasGraph.propTypes = propTypes
export default PastSasGraph
