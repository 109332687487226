export default {
  DATALAB: {
    name: 'DATALAB',
    primaryColor: '#002f70',
    secondaryColor: '#00a3ea',
  },
  SEAD: {
    name: 'SEAD',
    primaryColor: '#005745',
    secondaryColor: '#a17d73',
  },
}
