import React from 'react'
import { useLocation, matchRoutes, useParams } from 'react-router-dom'
import PropTypes from 'prop-types'
import RouterBreadcrumbs from '../components/RouterBreadcrumbs'
import type { Paths } from '../Router'

interface AsBaseScreenProps {
  routes: Paths
}

/**
 * This is a Higher Order Component
 * @see https://reactjs.org/docs/higher-order-components.html
 * Takes a standard screen such as ProjectsScreen and returns it as a base screen - adding some
 * additional features such as Error Boundaries and Breadcrumbs
 * @param WrappedComponent - the component to wrap as a BaseScreen component
 * @returns the BaseScreen component
 */
export default function asBaseScreen<P extends object>(WrappedComponent: React.ComponentType<P>) {
  /**
   * The BaseScreen component adds breadcrumbs to the provided component
   * @param props - See prop types
   * @returns the BaseScreen component
   */
  function BaseScreen(props: P & AsBaseScreenProps) {
    const { routes } = props
    const location = useLocation()
    const params = useParams()
    const [{ route }] = matchRoutes(routes, location) ?? [{ route: null }]
    const crumbs = routes
      .filter(({ path }) => route?.path.includes(path))
      .map(({ path, name, ...rest }) => ({
        path: Object.keys(params).length
          ? Object.keys(params).reduce((pth, param) => pth.replace(`:${param}`, params[param] ?? ''), path)
          : path,
        name: Object.keys(params).includes(name)
          ? params[name]
          : name,
        ...rest,
      }))

    return (
      <>
        <RouterBreadcrumbs crumbs={crumbs} {...props} />
        <WrappedComponent {...props} />
      </>
    )
  }
  BaseScreen.propTypes = {
    routes: PropTypes.arrayOf(
      PropTypes.shape(
        { path: PropTypes.string }
      )
    ).isRequired,
  }
  return BaseScreen
}
