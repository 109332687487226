import React from 'react';
import Button from '@mui/material/Button';
import Dialog from '@mui/material/Dialog';
import DialogActions from '@mui/material/DialogActions';
import DialogContent from '@mui/material/DialogContent';
import DialogContentText from '@mui/material/DialogContentText';
import DialogTitle from '@mui/material/DialogTitle';

export default function AlertDialog(props) {
  let [open] = React.useState(props.open);

  function handleYes() {
    props.callback(true);

  }

  function handleNo() {
    props.callback(false);
  }

  return (
    <div>
      <Dialog
        open={open}
        aria-labelledby="alert-dialog-title"
        aria-describedby="alert-dialog-description"
      >
        <DialogTitle id="alert-dialog-title">{props.title}</DialogTitle>
        <DialogContent>
          <DialogContentText id="alert-dialog-description">
            {props.content}
          </DialogContentText>
          {props.additionalComponent &&
            props.additionalComponent
          }
        </DialogContent>
        {!props.hideButtons &&
          props.okayConfirm ? 
          <DialogActions>
            <Button onClick={handleYes} color="primary" autoFocus>
              OK
          </Button>
          </DialogActions>
          :
          <DialogActions>
            <Button onClick={handleNo} color="primary">
              No
          </Button>
            <Button onClick={handleYes} color="primary" autoFocus>
              Yes
          </Button>
          </DialogActions>
        }
      </Dialog>
    </div>
  );
}
