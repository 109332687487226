import React from 'react';
import {
    Typography,
    Table,
    TableHead,
    TableRow,
    TableCell,
    TableBody,
    Link,
    CircularProgress
} from "@mui/material";
import { useNavigate } from 'react-router-dom';
import format from 'date-fns/format';

/**
 * Displays an array attribute in a row for use in View pages (View Project, View User etc.) using a table format
 * Specify the "lazy" prop with a function to fetch the data to load the data lazily (after page load)
 * Specify the "simpleArray" prop if the data is just a single array (not an object array) - you should only pass one column header in this case
 */
export default function ArrayAttributeRow(props) {

    const [keyValuePairs, setKeyValuePairs] = React.useState(props.keyValuePairs);
    const [lazyHandled, setLazyHandled] = React.useState(false);
    const [loading, setLoading] = React.useState(true);
    const navigate = useNavigate();

    //If the refresh timestamp changes (from an upstream component changing it) then reload the lazy data
    React.useEffect(() => {
        setLazyHandled(false)
        setLoading(true)
        loadLazyValues()
        // eslint-disable-next-line
    }, [props.refreshTimestamp, props.keyValuePairs]);

    async function loadLazyValues() {

        if (!lazyHandled) {
            setLazyHandled(true);
            if (props.lazy) {
                let data = await props.lazy();
                if (props.simpleArray) {
                    data = data.map(element => { return { [props.columnHeaders[0].key]: element } })
                }
                setKeyValuePairs(data);
            }
        }
        setLoading(false);
    }

    return (
        <React.Fragment>

            {/* render any buttons applicable for this block */}
            < div className="action-button-container" >
                {props.buttons}
            </div>

            <Table className="attribute-table">
                <TableHead>
                    <TableRow>
                        {props.columnHeaders.map(element => {
                            return (
                                <TableCell key={element.name} className="table-header">{element.name}</TableCell>
                            )
                        })}
                    </TableRow>
                </TableHead>
                {loading ?
                    <TableBody>
                        <TableRow key="loading">
                            <TableCell key="loading" component="th" scope="row">
                                <CircularProgress size="24px" />
                            </TableCell>
                        </TableRow>
                    </TableBody>
                    :
                    <React.Fragment>
                        <TableBody>
                            {keyValuePairs && keyValuePairs.length > 0 ?

                                keyValuePairs.map(element => {

                                    return (
                                        <TableRow key={element[props.rowKey]}>
                                            {Object.keys(element).map(key => {

                                                if (props.columnHeaders.find(h => h.key === key).linkTo) {
                                                    return <TableCell key={key} component="th" scope="row">
                                                        {/* if linkTo present then make the entry a link */}
                                                        <Link href="#" onClick={() => navigate(`/${props.columnHeaders.find(h => h.key === key).linkTo}/${element[key]}`)}>{element[key]}</Link>
                                                    </TableCell>
                                                } else if (props.columnHeaders.find(h => h.key === key).boolean) {
                                                    return <TableCell key={key} component="th" scope="row">
                                                        {element[key] ?
                                                            <i className="table-row-yes fas fa-check" />
                                                            :
                                                            <i className="table-row-no fas fa-times" />
                                                        }
                                                    </TableCell>
                                                } else if (props.columnHeaders.find(h => h.key === key).currency) {
                                                    return <TableCell key={key} component="th" scope="row">
                                                        {
                                                            new Intl.NumberFormat("en-US", {
                                                                style: "currency",
                                                                currency: "AUD"
                                                            }).format(element[key])
                                                        }
                                                    </TableCell>
                                                } else if (props.columnHeaders.find(h => h.key === key).dateTime) {
                                                    return <TableCell key={key} component="th" scope="row">
                                                        {format(new Date(element[key]), 'dd/MM/yyyy hh:mm a')}
                                                    </TableCell>
                                                } else {
                                                    return <TableCell key={key} component="th" scope="row">
                                                        {element[key]}
                                                    </TableCell>
                                                }
                                            })}
                                        </TableRow>
                                    )

                                })
                                :
                                //No data
                                <TableRow key="no-data">
                                    <TableCell component="th" scope="row">
                                        No {props.header}
                                    </TableCell>
                                </TableRow>
                            }
                        </TableBody>
                    </React.Fragment>
                }
            </Table>


            {
                props.footNote &&
                <Typography style={{ padding: '15px' }} variant="caption" component="p">
                    {props.footNote}
                </Typography>
            }


        </React.Fragment >

    );
}