import React from 'react'
import PropTypes from 'prop-types'
import { styled } from '@mui/material/styles'
import { connect } from 'react-redux'
import { BrowserRouter as Router } from 'react-router-dom'
import CssBaseline from '@mui/material/CssBaseline'
import { SnackbarProvider } from 'notistack'
import { Alert } from '@mui/material'
import createCache from '@emotion/cache'
import { CacheProvider } from '@emotion/react'
import AppRouter from './Router'
import NavBar from './components/NavBar'
import SideNav from './components/SideNav'
import '@trendmicro/react-sidenav/dist/react-sidenav.css'
import SpinnerIndeterminate from './components/SpinnerIndeterminate'
import '../resources/styles/main.css'
import 'bootstrap/dist/css/bootstrap.css'
import AuthenticationHandler from './util/AuthenticationHandler'
import AsyncHandler from './dataService/AsyncHandler'
import DatalabFacade from './dataService/DatalabFacade'
import IdleActivityTimer from './components/IdleActivityTimer'
import EnvironmentWatermark from './components/EnvironmentWatermark'
import Theme from './themes/Theme'
import PlatformThemes from './themes/PlatformThemes'
import { getRuntimeConfig } from './util/Config'
import { FeaturesProvider } from './hooks/useFeatures'
import { LoggedInUserProvider } from './hooks/useLoggedInUser'
import ErrorBoundary from './components/ErrorBoundary'

const propTypes = {
  dispatch: PropTypes.func.isRequired,
}

const Root = styled('div')(() => ({
  display: 'flex',
}))

const Main = styled('main')(({ theme }) => ({
  flexGrow: 1,
  height: '100vh',
  display: 'flex',
  flexDirection: 'column',
  overflow: 'hidden',
  backgroundColor: theme.palette.background.default,
}))

const cache = createCache({
  key: 'css',
  nonce: document.head.querySelector('[property~=csp-nonce][content]').content,
})

const config = getRuntimeConfig()

/**
 * This is main entry point to the application
 *
 * TODO: Logging requests and queries (to an external logger)
 * TODO: IE compatibility, works in Chrome, Edge, Firefox - not a priority
 * @author Ryan Magor
 */
class App extends React.Component {
  // see https://www.robinwieruch.de/react-warning-cant-call-setstate-on-an-unmounted-component/
  _isMounted = false

  constructor(props) {
    super(props)
    const { dispatch } = this.props
    this.authenticationHandler = new AuthenticationHandler({
      authChangeCallback: this.handleAuthChange.bind(this),
    })
    this.datalabFacade = new DatalabFacade(this.authenticationHandler, dispatch)
    this.notistackRef = React.createRef()

    // Set initial state
    this.state = {
      loading: true,
      isAuthenticated: false,
      user: null,
      bannerMsg: null,
    }
  }

  componentDidMount() {
    this._isMounted = true
  }

  componentWillUnmount() {
    this._isMounted = false
  }

  /**
   * Callback handler passed to the authentication handler - it should update this if its auth state changes
   * @param {*} user - the user or null
   */
  handleAuthChange(user) {
    if (this._isMounted) {
      const isAuthenticated = user != null

      this.setState({
        isAuthenticated,
        user,
        bannerMsg: null,
        loading: false,
        navbarOpen: localStorage.getItem('navbar-expanded') !== 'false',
      })

      if (isAuthenticated) {
        this.datalabFacade.getBannerMessage(user.pod).then(({ message }) => {
          this.setState({ bannerMsg: message })
        })
      }
    }
  }

  handleNavbarOpen = () => {
    localStorage.setItem('navbar-expanded', true)
    this.setState({ navbarOpen: true })
  }

  handleNavbarClosed = () => {
    localStorage.setItem('navbar-expanded', false)
    this.setState({ navbarOpen: false })
  }

  render() {
    const {
      bannerMsg,
      user,
      isAuthenticated,
      loading,
      navbarOpen,
    } = this.state

    /**
     * An array of all alerts to be shown in the UI.
     * Only alerts with `visible: true` and a non-empy mesage will appear
     * @member {object[]} alerts
     */
    const alerts = [
      {
        name: 'maintenance',
        type: 'warning',
        message: config.MAINTENANCE_MESSAGE,
        visible: true,
      },
      {
        name: 'banner',
        type: 'info',
        message: bannerMsg,
        visible: isAuthenticated,
      },
    ]

    // Theme is determined by platform (Datalab/ABS Pod or all other SEAD Pods)
    let theme
    if (user) {
      theme = user.pod === 'ABS' ? PlatformThemes.DATALAB : PlatformThemes.SEAD
    } else {
      theme = PlatformThemes.DATALAB
    }

    const commonProps = {
      isAuthenticated,
      user,
      authButtonMethod: this.authenticationHandler.login.bind(this.authenticationHandler),
      datalabFacade: this.datalabFacade,
    }

    return (
      <CacheProvider value={cache}>
        <Router>
          <CssBaseline />
          <Theme platformTheme={theme}>
            {(loading || !isAuthenticated)
              ? <SpinnerIndeterminate />
              : (
                <SnackbarProvider
                  maxSnack={10}
                  ref={this.notistackRef}
                >
                  <ErrorBoundary>
                    <FeaturesProvider>
                      <LoggedInUserProvider user={user}>
                        <EnvironmentWatermark />
                        <Root>
                          <AsyncHandler datalabFacade={this.datalabFacade} />
                          <IdleActivityTimer
                            logout={this.authenticationHandler.logout}
                          />
                          <NavBar
                            color="light"
                            isAuthenticated={isAuthenticated}
                            authButtonMethod={isAuthenticated
                              ? this.authenticationHandler.logout
                              : this.authenticationHandler.login}
                            user={user}
                          />
                          <CssBaseline />
                          <SideNav
                            user={user}
                            open={navbarOpen}
                            openCallback={this.handleNavbarOpen}
                            closeCallback={this.handleNavbarClosed}
                          />
                          <Main>
                            {alerts.map((alert) => (
                              alert.visible && alert.message && alert.message !== ''
                              && (
                                <Alert severity={alert.type} key={alert.name}>
                                  {alert.message}
                                </Alert>
                              )
                            ))}

                            <AppRouter roles={user?.roles || []} commonProps={commonProps} />
                          </Main>
                        </Root>
                      </LoggedInUserProvider>
                    </FeaturesProvider>
                  </ErrorBoundary>
                </SnackbarProvider>
              )}
          </Theme>
        </Router>
      </CacheProvider>
    )
  }
}

App.propTypes = propTypes
export default connect()((App))
