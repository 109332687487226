import React from 'react';
import {
  Typography,
  Container,
  Grid,
  Button

} from "@mui/material";
import { withRouter } from '../../screenWrappers/withRouter'

export default
  withRouter(class PageNotFoundScreen extends React.Component {
    render() {

      return (
        <Container sx={{margin: 'auto'}} maxWidth={false}>
          <Grid container spacing={3} alignItems="center" direction="column">

            <Grid container className="formRow" spacing={1} alignItems="center" justifyContent="center" direction="row">
              <Grid item>
                <Typography variant="body1" component="p">
                  Page not found
                </Typography>
              </Grid>
            </Grid>

            <Grid container className="formRow" spacing={1} alignItems="center" justifyContent="center" direction="row">
              <Grid item>
                <Button variant="outlined" color='primary' onClick={() => this.props.navigate(`/`)}>
                  Return home
                </Button>
              </Grid>
            </Grid>

          </Grid>
        </Container>
      );

    }

  }
  )
