import React from 'react'
import flow from 'lodash.flow'
import asBaseScreen from '../../screenWrappers/BaseScreen'
import { withSnackbarsFeature, withLoadingFeature } from '../../screenWrappers/Features'
import PaginatedDataTable from '../../components/PaginatedDataTable'

const wrap = flow([
  asBaseScreen,
  withSnackbarsFeature,
  withLoadingFeature,
])

class ReportsScreen extends React.Component {
  constructor(props) {
    super(props)
    this.state = {
    }
  }

  async handleDownloadReport(fileName) {
    this.props.startLoading()
    const blob = await this.props.datalabFacade.getReport(fileName)
    window.open(blob.reportUrl)
    this.props.stopLoading()
  }

  render() {
    return (
      <>
        <div className="action-button-container" />

        <PaginatedDataTable
          podId={this.props.user.pod}
          tableCaption="List of DataLab Reports"
          datalabFacade={this.props.datalabFacade}
          collectionName="reports"
          headers={[{ id: 'reportType', type: 'string-title-case', label: 'Type' },
            { id: 'frequency', type: 'string', label: 'Frequency' },
            { id: 'description', type: 'long-string', label: 'Description' },
            { id: 'dateAdded', type: 'date-time-verbose', label: 'Date generated' },
            { id: 'fileName', type: 'string', label: 'File name' },
          ]}
          defaultSort={{ property: 'dateAdded', direction: 'desc' }}
          onRowChangeCallback={() => { }}
          rowKey="fileName"
          limitCount={1000}
          csvExportFileName="datalab_reports"
          downloadable={this.handleDownloadReport.bind(this)}
        />
      </>
    )
  }
}

export default wrap(ReportsScreen)
