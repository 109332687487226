import React from 'react';
import Skeleton from '@mui/material/Skeleton';
import {
  Container,
} from "@mui/material";

export default function SkeletonArrayRow(props) {
  return (
    <Container style={{paddingTop: "50px", height: '40vh'}}>
      <Skeleton height={60}/>
      <Skeleton height={60}/>
      <Skeleton height={60}/>
      <Skeleton height={60}/>
    </Container>
  );
}