import {
  Link,
} from 'react-router-dom'
import { styled } from '@mui/material/styles'

export default styled(Link)((
  {
    theme,
  }
) => ({
  color: theme.palette.primary.main, fontSize: '14px', fontWeight: '450',
}
))
