import React from 'react'
import flow from 'lodash.flow'
import {
  Button,
  Tabs,
  Tab,
} from '@mui/material'
import asBaseScreen from '../../screenWrappers/BaseScreen'
import PaginatedDataTable from '../../components/PaginatedDataTable'
import { Role } from '../../enums/Role'
import { withRouter } from '../../screenWrappers/withRouter'
import RequiredRoleBoundary from '../../components/RequiredRoleBoundary'

// Screen requires the following data providers and features injected
const wrap = flow([
  withRouter,
  asBaseScreen,
])

class PackageManagementScreen extends React.Component {
  constructor(props) {
    super(props)
    this.state = {
      tab: 0,
    }
    this.datalabFacade = props.datalabFacade
  }

  async componentDidMount() {
    const cachedTab = window.sessionStorage.getItem('packagesTab')
    if (cachedTab) {
      this.setState({ tab: Number(cachedTab) })
      await this.loadData(Number(cachedTab))
    }
    await this.loadData(this.state.tab)
  }

  async handleRefresh() {
    await this.loadData(this.state.tab)
  }

  async loadData(tab) {
    this.setState({ loading: true })
    switch (tab) {
      case 0:
        // const rPackages = await this.datalabFacade.rPackages(this.props.user.pod)
        this.setState({
          loading: false,
          refreshDataTimestamp: new Date(),
        })
        break
      case 1:
        // const pythonPackages = await this.datalabFacade.pythonPackages(this.props.user.pod)
        this.setState({
          loading: false,
          refreshDataTimestamp: new Date(),
        })
        break
      default:
    }
  }

  handleChange = (event, newValue) => {
    this.setState({ tab: newValue })
    window.sessionStorage.setItem('packagesTab', newValue)
    this.loadData(newValue)
  }

  render() {
    return (
      <RequiredRoleBoundary
        allowedRoles={[Role.LIBRARY_ADMIN]}
        functionProps={[
          'onClick',
        ]}
      >
        <Tabs
          value={this.state.tab}
          onChange={this.handleChange}
          style={{ borderBottom: '1px solid #c8c8c8' }}
          indicatorColor="primary"
          textColor="primary"
          variant="scrollable"
        >
          <Tab label="R Packages" />
          <Tab label="Python Packages" />
        </Tabs>
        {this.state.tab === 0
                    && (
                    <>
                      <div className="action-button-container" style={{ marginBottom: '0px' }}>
                        <Button
                          variant="contained"
                          color="primary"
                          onClick={() => this.props.navigate('/packageManagement/addRPackages')}
                        >
                          Add R Packages&nbsp;&nbsp;
                          <i className="fas fa-plus-circle" />
                        </Button>
                      </div>
                      <div style={{ marginTop: '20px' }}>
                        <PaginatedDataTable
                          podId={this.props.user.pod}
                          tableCaption="List of Approved R Packages"
                          datalabFacade={this.props.datalabFacade}
                          collectionName="packages"
                          queryFilters={[{ property: 'type', condition: '$eq', value: 'r' }]}
                          headers={[{ id: 'name', type: 'string', label: 'name' },
                            { id: 'version', type: 'string', label: 'version' }]}
                          defaultSort={{ property: 'name', direction: 'asc' }}
                          onRowChangeCallback={() => { }}
                          rowKey="name"
                          csvExportFileName="datalab_RPackages"
                        />
                      </div>
                    </>
                    )}

        {this.state.tab === 1
                    && (
                    <>
                      <div className="action-button-container" style={{ marginBottom: '0px' }}>
                        <Button
                          variant="contained"
                          color="primary"
                          onClick={() => this.props.navigate('/packageManagement/addPythonPackages')}
                        >
                          Add Python Packages&nbsp;&nbsp;
                          <i className="fas fa-plus-circle" />
                        </Button>
                      </div>
                      <div style={{ marginTop: '20px' }}>
                        <PaginatedDataTable
                          podId={this.props.user.pod}
                          tableCaption="List of Approved Python Packages"
                          datalabFacade={this.props.datalabFacade}
                          collectionName="packages"
                          queryFilters={[{ property: 'type', condition: '$eq', value: 'python' }]}
                          headers={[{ id: 'name', type: 'string', label: 'name' },
                            { id: 'version', type: 'string', label: 'version' }]}
                          defaultSort={{ property: 'name', direction: 'asc' }}
                          onRowChangeCallback={() => { }}
                          rowKey="name"
                          csvExportFileName="datalab_PythonPackages"
                        />
                      </div>
                    </>
                    )}

      </RequiredRoleBoundary>
    )
  }
}

export default wrap(PackageManagementScreen)
