import React from 'react'
import { Typography } from '@mui/material'
import Link from './Link'

function Breadcrumbs({ crumbs }) {
  return (
    <div style={{
      margin: '5px 20px', alignItems: 'center', justifyContent: 'space-between', display: 'flex',
    }}
    >
      <div style={{ display: 'flex', alignItems: 'center' }}>
        {/* Link back to any previous steps of the breadcrumb. */}
        {crumbs.map(({ name, path }, key) => (key + 1 === crumbs.length ? (
          <Typography sx={{ fontSize: '20px', fontWeight: '450' }} key={key}>
            {name}
          </Typography>
        ) : (
          path !== '/'
                        && (
                        <div key={key}>
                          <Link to={path}>
                            {' '}
                            {name}
                          </Link>
                          <i
                            style={{ marginLeft: '5px', marginRight: '5px' }}
                            className="spacer fas fa-caret-right"
                          />
                        </div>
                        )

        )))}
      </div>
    </div>
  )
}
export default Breadcrumbs
