import PackageManagementScreen from '../screens/admin/PackageManagementScreen'
import AddRPackagesScreen from '../screens/admin/AddRPackageScreen'
import AddPythonPackageScreen from '../screens/admin/AddPythonPackageScreen'
import CommonRoutes from './CommonRoutes'

/**
 * Paths applicable to Analysts, beware that these need to be ordered correctly for breadcrumbs to work
 * ie users/ should be before users/:userName
 */
export default [
  {
    path: '/packageManagement',
    name: 'Package Management',
    Component: PackageManagementScreen,
  },
  {
    path: '/packageManagement/addRPackages',
    name: 'Add R Packages',
    Component: AddRPackagesScreen,
  },
  {
    path: '/packageManagement/addPythonPackages',
    name: 'Add Python Packages',
    Component: AddPythonPackageScreen,
  },
].concat(CommonRoutes)
