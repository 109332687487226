import React from 'react'
import { ThemeProvider, createTheme } from '@mui/material/styles'
import PropTypes from 'prop-types'

const propTypes = {
  platformTheme: PropTypes.shape({
    name: PropTypes.string.isRequired,
    primaryColor: PropTypes.string.isRequired,
    secondaryColor: PropTypes.string.isRequired,
  }).isRequired,
  children: PropTypes.element.isRequired,
}

function Theme({ platformTheme, children }) {
  const muiTheme = createTheme(({
    palette: {
      primary: {
        main: platformTheme.primaryColor,
      },
      secondary: {
        main: platformTheme.secondaryColor,
      },
      info: {
        main: platformTheme.primaryColor,
      },
      type: 'light',
      status: {
        danger: 'orange',
      },
      background: {
        default: '#fafafa',
      },
    },
    typography: {
      fontSize: 14,
    },
    components: {
      // Name of the component
      MuiLink: {
        styleOverrides: {
          root: {
            '&:hover': {
              color: platformTheme.secondaryColor,
            },
          },
        },
      },
    },
  }))

  return (
    <ThemeProvider theme={muiTheme}>
      {children}
    </ThemeProvider>
  )
}

Theme.propTypes = propTypes
export default Theme
