import React, { useState } from 'react';
import {
  IconButton,
  Button,
  Dialog,
  DialogActions,
  DialogContent,
  DialogTitle,
  FormControlLabel,
  Checkbox
} from '@mui/material'
import CloseIcon from '@mui/icons-material/Close';

export default function TutorialDialog({ open, closeCallback, title, pages, dontShowAgainCheckbox }) {
  let [dontShowAgainChecked, setDontShowAgainChecked] = useState(false);
  let [currentPage, setCurrentPage] = useState(0)

  function handleYes() {
    closeCallback(dontShowAgainChecked);
  }

  function handleNext() {
    setCurrentPage(currentPage + 1);
  }

  function handleBack() {
    setCurrentPage(currentPage - 1);
  }

  return (
    <Dialog
      maxWidth="md"
      fullWidth
      fullHeight
      open={open}
    >
      <DialogTitle>{title}<IconButton
          aria-label="close"
          onClick={handleYes}
          sx={{
            position: 'absolute',
            right: 8,
            top: 8,
            color: (theme) => theme.palette.grey[500],
          }}
        >
          <CloseIcon />
        </IconButton></DialogTitle>
      <DialogContent>
        {pages[currentPage]}
      </DialogContent>
      <DialogActions>
        {dontShowAgainCheckbox &&
          <FormControlLabel control={<Checkbox checked={dontShowAgainChecked} onChange={e => setDontShowAgainChecked(e.target.checked)} />} label="Don't show this again" />
        }
        {currentPage > 0 &&
          <Button onClick={handleBack} color="primary" autoFocus>
            Back
          </Button>
        }
        {currentPage === pages.length - 1 ?
          <React.Fragment>
            <Button onClick={handleYes} color="primary" autoFocus>
              Got it
            </Button>
          </React.Fragment>
          :
          <Button onClick={handleNext} color="primary" autoFocus>
            Next
          </Button>
        }
      </DialogActions>
    </Dialog>
  );
}
