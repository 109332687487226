"use strict";
Object.defineProperty(exports, "__esModule", { value: true });
exports.tagInstanceDtoSchema = void 0;
const zod_1 = require("zod");
/** Tag instances can only exist as child properties of other entities */
exports.tagInstanceDtoSchema = zod_1.z.object({
    /** The identifier of the tag class ie. 'Organisation Type' */
    tagKey: zod_1.z.string(),
    /** The value of the Tag instance ie. 'University' */
    tagValue: zod_1.z.string(),
});
