import React from 'react';
import {
    Box,
    Button,
    Drawer
} from '@mui/material';
import ReactMarkdown from 'react-markdown'
import remarkGemoji from 'remark-gemoji'
import CloseIcon from '@mui/icons-material/Close';
import WhatsNewMarkdown from '../../whatsnew.md'

/**
 * Component displays information about whats new in the system as a slide out blade on the right of the screen
 * @param {object} props
 * @returns
 */
function DrawerWhatsNew(props) {

    let [ content, setContent] = React.useState({md: ""});

    React.useEffect(()=> {
        fetch(WhatsNewMarkdown)
            .then((res) => res.text())
            .then((md) => {
                setContent({ md })
            })
    }, [])

    return (

        <Drawer
            anchor='right' //Determines where on the screen the drawer will appear
            open={props.open}
        >
            <Box
                sx={{ padding: 5, width: '800px' }}
                role="presentation"
                className="md-content"
            >
                <Button variant="text" startIcon={<CloseIcon />} onClick={props.closeCallback} sx={{ float: 'right' }}>
                    Close
                </Button>
                <ReactMarkdown children={content.md} remarkPlugins={[remarkGemoji]}/>
            </Box>
        </Drawer>

    )
}

export default DrawerWhatsNew;
