import React, {
  useContext, createContext, useMemo,
} from 'react'
import PropTypes from 'prop-types'
import { appUserShape } from '../propTypeShapes'

const LoggedInUserContext = createContext()

const propTypes = {
  user: appUserShape,
  children: PropTypes.arrayOf(PropTypes.element).isRequired,
}

const defaultProps = {
  user: undefined,
}

function LoggedInUserProvider({ user, children, ...rest }) {
  const providerValue = useMemo(() => ({ user }), [])
  return (
    // eslint-disable-next-line react/jsx-props-no-spreading -- props spreading is used inside HOC
    <LoggedInUserContext.Provider value={providerValue} {...rest}>
      {children}
    </LoggedInUserContext.Provider>
  )
}

LoggedInUserProvider.propTypes = propTypes
LoggedInUserProvider.defaultProps = defaultProps

const useLoggedInUser = () => {
  const context = useContext(LoggedInUserContext)
  if (context === undefined) {
    throw new Error('useLoggedInUser must be used within a LoggedInUserProvider')
  }
  return context
}

export {
  LoggedInUserProvider,
  useLoggedInUser,
}
