import React from 'react'
import { styled } from '@mui/material/styles'
import flow from 'lodash.flow'
import {
  Button,
  IconButton,
  Typography,
  TextField,
  Container,
} from '@mui/material'
import Autocomplete from '@mui/material/Autocomplete'
import HelpIcon from '@mui/icons-material/Help'
import PropTypes from 'prop-types'
import asBaseScreen from '../../screenWrappers/BaseScreen'
import HelpDialog from '../../components/HelpDialogListed'
import { withProject, withAllUsers, withAllVmOptionsConfig } from '../../screenWrappers/DataProviders'
import { useSnackbars } from '../../hooks/useSnackbars'
import { useFeatures } from '../../hooks/useFeatures'
import SkeletonForm from '../../components/SkeletonForm'
import {
  appUserShape, vmOptionConfigShape, projectShape, userShape,
} from '../../propTypeShapes'
import DatalabFacade from '../../dataService/DatalabFacade'

const PREFIX = 'AssignUserScreen'

const classes = {
  formLabel: `${PREFIX}-formLabel`,
}

const Root = styled('div')(() => ({
  [`& .${classes.formLabel}`]: {
    '& .MuiFormLabel-root': {
      marginTop: '3px',

    },
  },
}))

// Screen requires the following data providers and features injected
const wrap = flow([
  withProject,
  withAllUsers,
  withAllVmOptionsConfig,
  asBaseScreen,
])

const propTypes = {
  user: appUserShape.isRequired,
  datalabFacade: PropTypes.instanceOf(DatalabFacade).isRequired,
  vmOptionsConfig: PropTypes.arrayOf(vmOptionConfigShape),
  project: projectShape,
  users: PropTypes.arrayOf(userShape),
}

const defaultProps = {
  vmOptionsConfig: null,
  project: null,
  users: null,
}

/**
 * Allows assigning a user to a project
 */
function AssignUserScreen({
  user, datalabFacade, vmOptionsConfig, project, users,
}) {
  const [loading, setLoading] = React.useState(true)
  const [selectableUsers, setSelectableUsers] = React.useState([])
  const [selectedUsers, setSelectedUsers] = React.useState([])
  const [selectedVmSize, setSelectedVmSize] = React.useState(undefined)
  const [selectedVmType, setSelectedVmType] = React.useState(undefined)
  const [selectedVmVersion, setSelectedVmVersion] = React.useState(undefined)
  const [vmSizeOptions, setVmSizeOptions] = React.useState([])
  const [vmTypeOptions, setVmTypeOptions] = React.useState([])
  const [vmVersionOptions, setVmVersionOptions] = React.useState([])

  const [showingVmSizeHelp, setShowingVmSizeHelp] = React.useState(false)
  const [showingVmTypeHelp, setShowingVmTypeHelp] = React.useState(false)
  const [showingVmVersionHelp, setShowingVmVersionHelp] = React.useState(false)

  const { showSnackbarInProgress } = useSnackbars()
  const { setConfirm } = useFeatures()

  React.useEffect(() => {
    if (loading && vmOptionsConfig && project && users) {
      const sizeOptions = vmOptionsConfig.filter((x) => x.configType === 'vmSize')
      const typeOptions = vmOptionsConfig.filter((x) => x.configType === 'vmType')
      const versionOptions = vmOptionsConfig.filter(
        (x) => x.configType === 'vmVersion' && x.availableForRebuild
      )
      const defaultVmSize = sizeOptions.find(
        (vmSizeOption) => vmSizeOption.type === project.defaultVMsize
      )
      defaultVmSize.displayName += ' (Default for this Project)'
      const defaultVmType = typeOptions.find((vmTypeOption) => vmTypeOption.type === 'standard')
      const defaultVmVersion = versionOptions.find((e) => e.latest)
      setLoading(false)
      setSelectableUsers(users
        // Filter active users that are not already assigned to the project
        .filter((u) => u.status === 'ACTIVE' && !project.users.includes(u.userName)))
      setSelectedVmSize(defaultVmSize)
      setSelectedVmType(defaultVmType)
      setSelectedVmVersion(defaultVmVersion)
      setVmSizeOptions(sizeOptions)
      setVmTypeOptions(typeOptions)
      setVmVersionOptions(versionOptions)
    }
  }, [vmOptionsConfig, project, users])

  const handleSubmit = () => {
    if (selectedUsers.length === 1) {
      setConfirm({
        message:
        'Are you sure you want to assign this user?',
        callback: async () => {
          await datalabFacade.assignUserToProject(
            selectedUsers[0].userName,
            project.uuid,
            selectedVmSize.type,
            selectedVmType.type,
            selectedVmVersion.version,
            user.pod
          )
          showSnackbarInProgress(
            `Assign User (${selectedUsers[0].userName} -> ${project.uuid}) in progress...`
          )
        },
        additionalOptions: {
          redirect: `/projects/${project.uuid}`,
        },
      })
    } else {
      setConfirm({
        message:
        'Are you sure you want to assign these Users?',
        callback: async () => {
          await datalabFacade.assignUsersToProject(
            selectedUsers.map((u) => u.userName),
            project.uuid,
            selectedVmSize.type,
            selectedVmType.type,
            selectedVmVersion.version,
            user.pod
          )
          showSnackbarInProgress(
            `Assign Users (${selectedUsers.length} Users -> ${project.uuid}) in progress...`
          )
        },
        additionalOptions: {
          redirect: `/projects/${project.uuid}`,
        },
      })
    }
  }

  return loading ? <SkeletonForm />
    : (
      <Root className="details-grid">
        <Container maxWidth="md">
          {showingVmSizeHelp
            && (
              <HelpDialog
                open={showingVmSizeHelp}
                callback={() => setShowingVmSizeHelp(false)}
                title="Available Virtual Machine sizes"
                headers={[
                  { formatted: 'Type', key: 'displayName' },
                  { formatted: 'Description', key: 'description' },
                ]}
                rows={vmSizeOptions}
                rowKey="type"
              />
            )}

          <Typography variant="body1" component="h2">
            Select VM Size
            <IconButton
              onClick={() => setShowingVmSizeHelp(true)}
              aria-label="virtual machine help"
              size="large"
            >
              <HelpIcon />
            </IconButton>
          </Typography>

          <Autocomplete
            id="autocomplete-vmsize"
            options={vmSizeOptions}
            getOptionLabel={(option) => option.displayName}
            value={selectedVmSize}
            onChange={(event, value) => setSelectedVmSize(value)}
            renderInput={(params) => (
              <TextField
                // eslint-disable-next-line react/jsx-props-no-spreading
                {...params}
                label="VM Size"
                variant="outlined"
                fullWidth
                className={classes.formLabel}
              />
            )}
          />

          {showingVmTypeHelp
            && (
              <HelpDialog
                open={showingVmTypeHelp}
                callback={() => setShowingVmTypeHelp(false)}
                title="Available Virtual Machine types"
                headers={[
                  { formatted: 'Type', key: 'displayName' },
                  { formatted: 'Description', key: 'description' },
                ]}
                rows={vmTypeOptions}
                rowKey="type"
              />
            )}

          <Typography variant="body1" component="h2">
            Select VM Type
            <IconButton
              onClick={() => setShowingVmTypeHelp(true)}
              aria-label="virtual machine help"
              size="large"
            >
              <HelpIcon />
            </IconButton>
          </Typography>

          <Autocomplete
            id="autocomplete-vmtype"
            options={vmTypeOptions}
            getOptionLabel={(option) => option.displayName}
            value={selectedVmType}
            onChange={(event, value) => setSelectedVmType(value)}
            renderInput={(params) => (
              <TextField
                // eslint-disable-next-line react/jsx-props-no-spreading
                {...params}
                label="VM Type"
                variant="outlined"
                fullWidth
                className={classes.formLabel}
              />
            )}
          />
          <Typography variant="caption" style={{ color: 'red' }}>
            Refer to user specifications, otherwise set as
            {' '}
            <b>standard</b>
          </Typography>

          {showingVmVersionHelp
            && (
              <HelpDialog
                open={showingVmVersionHelp}
                callback={() => setShowingVmVersionHelp(false)}
                title="Available Virtual Machine Desktop Versions"
                headers={[
                  { formatted: 'Version', key: 'version' },
                  { formatted: 'Included Software', key: 'softwareText' },
                  { formatted: 'Notes', key: 'deprecationText' },
                ]}
                rows={vmVersionOptions}
                rowKey="type"
                message="Desktop versions are released annually and include the most up to date software (security patches are always applied to all desktop versions), plan your migration to the newest version: Previous year versions are no longer available shortly after a new version is released. New major software versions can introduce breaking changes, issues should be reported prior to the previous years version being removed, so give yourself enough time to try out the new version."
              />
            )}
          <Typography variant="body1" component="h2">
            Select VM Version
            <IconButton
              onClick={() => setShowingVmVersionHelp(true)}
              aria-label="virtual machine version help"
              size="large"
            >
              <HelpIcon />
            </IconButton>
          </Typography>
          <Autocomplete
            id="autocomplete-vmversion"
            options={vmVersionOptions}
            getOptionLabel={(option) => `${option.version}${option.latest ? ' (latest)' : ''}`}
            value={selectedVmVersion}
            onChange={(event, value) => setSelectedVmVersion(value)}
            renderInput={(params) => (
              <TextField
                // eslint-disable-next-line react/jsx-props-no-spreading
                {...params}
                label="VM Version"
                variant="outlined"
                fullWidth
                className={classes.formLabel}
              />
            )}
          />
          <Typography variant="caption" style={{ color: 'red' }}>
            Refer to user specifications, otherwise set as
            {' '}
            <b>latest</b>
          </Typography>
          <Typography variant="body1" component="h2" fontWeight={500}>
            Select the Users to assign to
            {' '}
            {project.projectName}
            {' '}
            [
            {project.uuid}
            ]
          </Typography>
          <Autocomplete
            id="autocomplete-users"
            options={selectableUsers}
            variant="outlined"
            multiple
            filterSelectedOptions
            getOptionLabel={(option) => `${option.displayName} (${option.userName}) ${option.organisation
              ? (`(${option.organisation})`)
              : ''}`}
            onChange={(event, values) => setSelectedUsers(values)}
            renderInput={(params) => (
              <TextField
                // eslint-disable-next-line react/jsx-props-no-spreading
                {...params}
                label="Users"
                fullWidth
              />
            )}
          />

          <div className="action-button-container">
            <Button
              type="submit"
              variant="contained"
              color="primary"
              disabled={selectedUsers.length < 1 || !selectedVmSize || !selectedVmType || !selectedVmVersion}
              onClick={handleSubmit}
            >
              Assign User(s)
            </Button>
          </div>
        </Container>
      </Root>
    )
}

AssignUserScreen.propTypes = propTypes
AssignUserScreen.defaultProps = defaultProps

export default wrap(AssignUserScreen)

