"use strict";
Object.defineProperty(exports, "__esModule", { value: true });
exports.packageDtoSchema = void 0;
const zod_1 = require("zod");
/** Database fields set by user input that can be queried via the api */
const packageInputFields = zod_1.z.object({});
/** Generated database fields that can be queried via the api */
const generatedFields = zod_1.z.object({
    type: zod_1.z.enum(['r', 'python']),
    name: zod_1.z.string(),
    version: zod_1.z.string(),
});
/** All database fields that are exposed via API */
const packageDtoSchema = packageInputFields.merge(generatedFields);
exports.packageDtoSchema = packageDtoSchema;
