import React, { cloneElement } from 'react'
import {
  Typography,
} from '@mui/material'
import PropTypes from 'prop-types'
import { useFeatures } from '../hooks/useFeatures'
import { useLoggedInUser } from '../hooks/useLoggedInUser'
import { Role } from '../enums/Role'
import { RoleDisplayName } from '../enums/RoleDisplayName'
import { recursiveMap } from '../util/ReactUtils'

const propTypes = {
  allowedRoles: PropTypes.arrayOf(PropTypes.string).isRequired,
  functionProps: PropTypes.arrayOf(PropTypes.string).isRequired,
  children: PropTypes.node.isRequired,
}

/* Extends function props in the list above of all child components to validate the users role. You can
 * pass an array of Roles that the component should allow before running the onClick callback. Note
 * this is only frontend validation, you must also properly authorise any backend actions
 */
function RequiredRoleBoundary({
  allowedRoles, functionProps, children,
}) {
  const { setConfirm } = useFeatures()
  const { user } = useLoggedInUser()

  const handleOnClick = (args, originalOnClick = () => {}) => {
    const hasRole = user.roles.some(
      (userRole) => userRole === Role.SYSADMIN || allowedRoles.includes(userRole)
    )
    if (hasRole) {
      originalOnClick(...args)
    } else {
      setConfirm({
        message:
      "Sorry, you don't have the right permissions to do this!",
        additionalOptions: {
          okayConfirm: true,
          additionalComponent: (
            <>
              <Typography variant="subtitle2">
                {`Required role(s): ${
                  allowedRoles.map((e) => RoleDisplayName[e]).join(' or ')}.`}
              </Typography>
              <Typography variant="body2">
                {`Your role(s): ${user.roles.map((e) => RoleDisplayName[e]).join(', ')}.`}
              </Typography>
              <Typography variant="body2">
                If you believe you should have this permission, contact your Pod Owner.
              </Typography>
            </>
          )
          ,
        },
      })
    }
  }
  return (
    <>
      {recursiveMap(children, (child) => cloneElement(
        child,
        Object.fromEntries(functionProps.filter((p) => child.props[p])
          .map((p) => [p, (...args) => handleOnClick(args, child.props[p])]))
      ))}
    </>
  )
}

RequiredRoleBoundary.propTypes = propTypes
export default RequiredRoleBoundary
