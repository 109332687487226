import CommonRoutes from './CommonRoutes';
import LicensedSoftwareScreen from '../screens/billingadmin/LicensedSoftwareScreen';
import ReportsScreen from '../screens/billingadmin/ReportsScreen';

/**
 * Paths applicable to Billing Admins, beware that these need to be ordered correctly for breadcrumbs to work
 * ie users/ should be before users/:userName
 */
export default [
    {
        path: "/licencedSoftware",
        name: "Software Summary",
        Component: LicensedSoftwareScreen
    },
    {
        path: "/reports",
        name: "Reports",
        Component: ReportsScreen
    },
].concat(CommonRoutes);
