const moment = require('moment-timezone');


export function toTitleCase(str) {
    return str.replace(
        /\w\S*/g,
        function (txt) {
            return txt.charAt(0).toUpperCase() + txt.substr(1).toLowerCase();
        }
    );
}

export function objectToString(object) {
    let formattedString = "";
    Object.keys(object).forEach(element => {
        formattedString += element + ": " + object[element] + "\n";
        formattedString = toTitleCase(formattedString);
    });

    return formattedString;
}

export function camelCaseToTitleCase(str) {
    return toTitleCase(str.replace(/([A-Z])/g, ' $1').trim());
}

export function millisToHoursMinutes(millis) {
    let h, m;
    h = Math.floor(millis / 1000 / 60 / 60);
    m = Math.floor((millis / 1000 / 60 / 60 - h) * 60);
    return `${h > 0 ? `${h} hours` : ''} ${m} minutes`;
}

//Given the hour of the day in Eastern states(adjusts for daylight saving), format in local time
export function hourInLocalTime(hour) {
    let m = moment().tz('Australia/Sydney')
    m.set({ hour: hour, minute: 0, second: 0, millisecond: 0 })
    let localHour = m.tz(moment.tz.guess()).format("h:mm A")
    return localHour
}

//Given a Date returns either "Tonight" or the weekday night ie "Thursday night"
export function dateFutureDayOrTonight(dateValue) {
    let str = 'Tonight 10PM AEST'
    if (!dateValue) {
        return str
    } else {
        const now = new Date();
        if (dateValue < now) {
            return str
        } else {
            return `${dateValue.toLocaleDateString('default', { weekday: 'long' })} night 10PM AEST`;
        }
    }

}