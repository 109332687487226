import React from 'react';
import CircularProgress from '@mui/material/CircularProgress';

export default function SpinnerIndeterminate(props) {
  return (
    <div align="center">
      <CircularProgress className="spinner-indeterminate" />
      <p>{props.infoMessage}</p>
    </div>
  );
}