import React from 'react'
import flow from 'lodash.flow'
import Skeleton from '@mui/material/Skeleton'
import PropTypes from 'prop-types'
import { useNavigate } from 'react-router-dom'
import { Button } from '@mui/material'
import PaginatedDataTable from '../../components/PaginatedDataTable'
import asBaseScreen from '../../screenWrappers/BaseScreen'
import { Role } from '../../enums/Role'
import { withAllTags } from '../../screenWrappers/DataProviders'
import { withRouter } from '../../screenWrappers/withRouter'
import RequiredRoleBoundary from '../../components/RequiredRoleBoundary'
import { appUserShape, tagShape } from '../../propTypeShapes'
import DatalabFacade from '../../dataService/DatalabFacade'

// Screen requires the following data providers and features injected
const wrap = flow([
  withRouter,
  withAllTags,
  asBaseScreen,
])

const propTypes = {
  user: appUserShape.isRequired,
  datalabFacade: PropTypes.instanceOf(DatalabFacade).isRequired,
  tags: PropTypes.arrayOf(tagShape),
}

const defaultProps = {
  tags: null,
}

/**
 * Lists all users in a table
 */
function UsersScreen({ user, datalabFacade, tags }) {
  const [loading, setLoading] = React.useState(true)
  const navigate = useNavigate()

  React.useEffect(() => {
    if (tags) {
      setLoading(false)
    }
  }, [tags])

  const handleRowSelected = (rowId) => {
    navigate(`/users/${rowId}`)
  }

  const handleNewBtnClick = () => {
    navigate('/users/newUser')
  }

  return (
    loading
      ? (
        <>
          {[...Array(15)].map((n, index) => (
            <Skeleton
                // eslint-disable-next-line react/no-array-index-key
              key={index}
              height={50}
              style={{ margin: '20px' }}
            />
          ))}
        </>
      )

      : (
        <RequiredRoleBoundary
          allowedRoles={[Role.USER_ADMIN]}
          functionProps={[
            'onClick',
          ]}
        >

          <div className="action-button-container">
            <Button
              variant="contained"
              color="primary"
              onClick={handleNewBtnClick}
            >
              New User&nbsp;&nbsp;
              <i className="fas fa-plus-circle" />
            </Button>
          </div>

          <PaginatedDataTable
            tableCaption="List of DataLab Users"
            datalabFacade={datalabFacade}
            collectionName="users"
            podId={user.pod}
            headers={[{
              id: 'userName', type: 'string', label: 'Username', linkTo: 'users',
            },
            { id: 'displayName', type: 'string', label: 'Display Name' },
            { id: 'organisation', type: 'string', label: 'Organisation' },
            {
              id: 'status',
              type: 'status',
              label: 'Status',
              statusEnums: ['ACTIVE', 'DISABLED', 'INACTIVE'],
            },
            { id: 'registeredDate', type: 'date-time', label: 'Registered Date' },
            { id: 'contactEmail', type: 'string', label: 'Email' },
            { id: 'phone', type: 'string', label: 'Phone' },
            { id: 'activeVm', type: 'string', label: 'Active VM' },
            { id: 'tags', type: 'tag-array', label: 'Tags' },
            ]}
            defaultSort={{ property: 'registeredDate', direction: 'desc' }}
            defaultFilter={{
              property: 'status', condition: '$ne', value: 'INACTIVE', displayValue: 'INACTIVE',
            }}
            onRowChangeCallback={handleRowSelected}
            fileName="datalab_users.csv"
            csvExportFileName="datalab_users"
            rowKey="userName"
            tags={tags}
          />
        </RequiredRoleBoundary>
      )
  )
}

UsersScreen.propTypes = propTypes
UsersScreen.defaultProps = defaultProps
export default wrap(UsersScreen)
