import React from 'react';
import { styled } from '@mui/material/styles';
import CheckCircleIcon from '@mui/icons-material/CheckCircle';
import {
  Grid,
  Card,
  Button,
  CardHeader,
  Checkbox,
  Divider,
  List,
  ListItem,
  ListItemIcon,
  ListItemText,
  TextField,
  Typography,
  Dialog,
  DialogActions,
  DialogContent,
  DialogTitle
} from "@mui/material";
import IconButton from '@mui/material/IconButton';
import Cancel from '@mui/icons-material/Clear';

const PREFIX = 'TransferList';

const classes = {
  root: `${PREFIX}-root`,
  grid: `${PREFIX}-grid`,
  centergrid: `${PREFIX}-centergrid`,
  buttonContainer: `${PREFIX}-buttonContainer`,
  cardHeader: `${PREFIX}-cardHeader`,
  list: `${PREFIX}-list`,
  button: `${PREFIX}-button`,
  bulkButton: `${PREFIX}-bulkButton`,
  searchBox: `${PREFIX}-searchBox`
};

// TODO jss-to-styled codemod: The Fragment root was replaced by div. Change the tag if needed.
const Root = styled('div')((
  {
    theme
  }
) => ({
  [`& .${classes.root}`]: {
    margin: 'auto',
    width: "100%"
  },

  [`& .${classes.grid}`]: {
    width: "40%"
  },

  [`& .${classes.centergrid}`]: {
    width: "20%"
  },

  [`& .${classes.buttonContainer}`]: {
    marginTop: "50px"
  },

  [`& .${classes.cardHeader}`]: {
    padding: theme.spacing(1, 2),
  },

  [`& .${classes.list}`]: {
    width: "100%",
    height: "auto",
    backgroundColor: theme.palette.background.paper,
    overflow: 'auto',
  },

  [`& .${classes.button}`]: {
    margin: theme.spacing(0.5, 0),
  },

  [`& .${classes.bulkButton}`]: {
    margin: "auto"
  },

  [`& .${classes.searchBox}`]: {
    width: "100%"
  }
}));

function not(a, b) {
  return a.filter((value) => b.indexOf(value) === -1);
}

function intersection(a, b) {
  return a.filter((value) => b.indexOf(value) !== -1);
}

function union(a, b) {
  return [...a, ...not(b, a)];
}

export default function TransferList(props) {

  const [checked, setChecked] = React.useState([]);
  const [left, setLeft] = React.useState(props.leftData);
  const [filteredLeft, setFilteredLeft] = React.useState(props.leftData);
  const [filteredRight, setFilteredRight] = React.useState(props.rightData);
  const [right, setRight] = React.useState(props.rightData);
  const [filterLeftQuery, setFilterLeftQuery] = React.useState("");
  const [filterRightQuery, setFilterRightQuery] = React.useState("");
  const [csvSelectOpen, setCsvSelectOpen] = React.useState(false);
  const [csvString, setCsvString] = React.useState("");
  //To flag items that were initially in the right column when rendered
  const [initialRight] = React.useState(props.rightData);

  React.useEffect(() => {
    props.setChosenDataCallback(right)
    // eslint-disable-next-line
  }, [right]);

  React.useEffect(() => {
    if(props.setLeftDataCallback){
      props.setLeftDataCallback(left)
    }
   
    // eslint-disable-next-line
  }, [left]);

  const leftChecked = intersection(checked, left);
  const rightChecked = intersection(checked, right);

  const handleToggle = (value) => () => {
    const currentIndex = checked.indexOf(value);
    const newChecked = [...checked];

    if (currentIndex === -1) {
      newChecked.push(value);
    } else {
      newChecked.splice(currentIndex, 1);
    }

    setChecked(newChecked);
  };

  const numberOfChecked = (items) => intersection(checked, items).length;

  const handleToggleAll = (items) => () => {
    if (numberOfChecked(items) === items.length) {
      setChecked(not(checked, items));
    } else {
      setChecked(union(checked, items));
    }
  };

  const handleCheckedRight = () => {
    setRight(right.concat(leftChecked));
    setFilteredRight(right.concat(leftChecked));
    setLeft(not(left, leftChecked));
    setChecked(not(checked, leftChecked));
    setFilterRightQuery("");
  };

  const handleCheckedLeft = () => {
    setLeft(left.concat(rightChecked));
    setFilteredLeft(left.concat(rightChecked));
    setRight(not(right, rightChecked));
    setChecked(not(checked, rightChecked));
    setFilterLeftQuery("");
  };

  const handleFilterLeftChange = (event) => {
    setFilteredLeft(left.filter(element => element.toLowerCase().includes(event.target.value.toLowerCase())));
    setFilterLeftQuery(event.target.value);
  };

  const handleFilterRightChange = (event) => {
    setFilteredRight(right.filter(element => element.toLowerCase().includes(event.target.value.toLowerCase())));
    setFilterRightQuery(event.target.value);
  };

  const cancelFilterLeft = () => {
    setFilteredLeft(left)
    setFilterLeftQuery("")
  }

  const cancelFilterRight = () => {
    setFilteredRight(right)
    setFilterRightQuery("")
  }

  const handleCsvSelect = () => {
    setCsvSelectOpen(true)
  };

  const handleSubmitCsvSelect = () => {
    const names = csvString.split(',').map(e => e.trim())
    const matches = props.leftData.concat(props.rightData).filter(e => names.includes(e))
    const nonMatches = names.filter(e => !matches.includes(e))
    setChecked(matches)
    setCsvSelectOpen(false)
    if (nonMatches.length) {
      props.askForConfirmationListener(`The following product names do not exist: ${nonMatches.join(', ')}`, () => { }, { okayConfirm: true })
    }
  };

  const customList = (title, items, totalItemCount) => (
    <Card elevation={0} variant="outlined">
      <CardHeader
        className={classes.cardHeader}
        avatar={
          <Checkbox
            onClick={handleToggleAll(items)}
            checked={numberOfChecked(items) === items.length && items.length !== 0}
            indeterminate={numberOfChecked(items) !== items.length && numberOfChecked(items) !== 0}
            disabled={items.length === 0}
            inputProps={{ 'aria-label': 'all items selected' }}
          />
        }
        title={title}
        subheader={`${numberOfChecked(items)}/${totalItemCount} selected`}
      />

      <Divider />

      <div style={{ overflow: 'auto', maxHeight: '280px' }}>
        <List className={classes.list} dense component="div" role="list">
          {items.map((value) => {
            const labelId = `transfer-list-all-item-${value}-label`;

            return (
              <ListItem key={value} role="listitem" button onClick={handleToggle(value)} style={{backgroundColor: initialRight.indexOf(value) !== -1 ? '#fbeeee' : 'none'}}>
                <ListItemIcon>
                  <Checkbox
                    checked={checked.indexOf(value) !== -1}
                    tabIndex={-1}
                    disableRipple
                    inputProps={{ 'aria-labelledby': labelId }}
                  />
                </ListItemIcon>
                <ListItemText id={labelId} primary={value} />
              </ListItem>
            );
          })}
          <ListItem />
        </List>
      </div>
    </Card>
  );

  const CsvSelect = () => {
    return (<Dialog
      maxWidth="lg"
      open={csvSelectOpen}
      aria-labelledby="alert-dialog-title"
      aria-describedby="alert-dialog-description"
    >
      <DialogTitle id="alert-dialog-title">CSV Select</DialogTitle>
      <DialogContent style={{ width: "1200px" }}>
        <TextField
          id="searchBox-product"
          style={{ width: "100%" }}
          value={csvString}
          placeholder="Product names seperated by a comma"
          autoFocus
          multiline
          onChange={(event) => {
            setCsvString(event.target.value)
          }}
          onKeyDown={(e) => {
            if (e.key === 'Enter') {
              handleSubmitCsvSelect()
            }
          }}
        />
      </DialogContent>
      <DialogActions>
        <Button variant="text" color='primary' onClick={() => { document.getElementById('searchBox-product').focus(); setCsvString(""); }}>
          Clear
          </Button>
        <Button variant="text" color='primary' onClick={() => { setCsvSelectOpen(false); }}>
          Cancel
          </Button>
        <Button onClick={handleSubmitCsvSelect} color="primary" autoFocus variant="outlined">
          OK
          </Button>
      </DialogActions>
    </Dialog>)
  };

  return (
    <Root>
      {CsvSelect()}
      <Grid container spacing={2} justifyContent="center" alignItems="flex-start" className={classes.root}>

      <Grid item className={classes.grid}/>
      <Grid item className={classes.centergrid}/>

      <Grid item className={classes.grid}>
          <Grid container direction="row" alignItems="center" justifyContent="flex-start">
            <CheckCircleIcon style={{ width: "20%", fill: "green" }} />
            <Typography style={{ width: "80%", textAlign: "center" }} variant="caption" component="p">
              {props.selectedMessage}
            </Typography>
          </Grid>
        </Grid>
        <Grid item className={classes.grid}>
          <TextField
            className={classes.searchBox}
            id="search-box"
            placeholder={props.searchBoxLabel}
            value={filterLeftQuery}
            onChange={handleFilterLeftChange}
            variant="outlined"
            InputProps={{
              endAdornment:
                  <React.Fragment>
                      {filterLeftQuery !== "" &&
                          <IconButton
                            style={{
                                padding: "5px"
                            }}
                            aria-label="cancel quick search"
                            onClick={cancelFilterLeft}
                            size="large">
                              <Cancel />
                          </IconButton>
                      }
                  </React.Fragment>
          }}
          />
        </Grid>
        <Grid item className={classes.centergrid}></Grid>
        <Grid item className={classes.grid}>
          <TextField
            className={classes.searchBox}
            id="search-box"
            placeholder={props.searchBoxSelectedLabel}
            value={filterRightQuery}
            onChange={handleFilterRightChange}
            variant="outlined"
            InputProps={{
              endAdornment:
                  <React.Fragment>
                      {filterRightQuery !== "" &&
                          <IconButton
                            style={{
                                padding: "5px"
                            }}
                            aria-label="cancel quick search"
                            onClick={cancelFilterRight}
                            size="large">
                              <Cancel />
                          </IconButton>
                      }
                  </React.Fragment>
          }}
          />
        </Grid>

        <Grid item className={classes.grid}>
          {customList(props.choicesLabel, left.filter(element => filteredLeft.includes(element)).sort((a, b) => a.localeCompare(b)),left.length)}
        </Grid>
        <Grid item className={classes.centergrid}>
          <Grid container direction="column" alignItems="center" className={classes.buttonContainer}>
            <Button
              variant="outlined"
              size="small"
              className={classes.button}
              onClick={handleCheckedRight}
              disabled={leftChecked.length === 0}
              aria-label="move selected right"
            >
              &gt;
          </Button>
            <Button
              variant="outlined"
              size="small"
              className={classes.button}
              onClick={handleCheckedLeft}
              disabled={rightChecked.length === 0}
              aria-label="move selected left"
            >
              &lt;
          </Button>
            <Button
              variant="outlined"
              color="primary"
              size="small"
              className={classes.button}
              onClick={handleCsvSelect}
              aria-label="select with csv"
            >
              CSV Select
          </Button>
          </Grid>
        </Grid>
        <Grid item className={classes.grid}>
          {customList(props.chosenLabel, right.filter(element => filteredRight.includes(element)).sort((a, b) => a.localeCompare(b)), right.length)}
        </Grid>
      </Grid>
    </Root>
  );
}