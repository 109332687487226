import React from 'react'
import flow from 'lodash.flow'
import { AdapterDateFns } from '@mui/x-date-pickers/AdapterDateFns'
import { LocalizationProvider } from '@mui/x-date-pickers/LocalizationProvider'
import {
  Button,
  Container,
  Box,
  Divider,
} from '@mui/material'
import PropTypes from 'prop-types'
import ProjectDetailsForm from '../../components/ProjectDetailsForm'
import SkeletonForm from '../../components/SkeletonForm'
import { UserFixableError } from '../../util/Exceptions'
import asBaseScreen from '../../screenWrappers/BaseScreen'
import {
  withAllVmOptionsConfig, withAllOrganisations, withAllTags, withProject, withAllUsers,
} from '../../screenWrappers/DataProviders'
import { withConfirmFeature, withLoadingFeature, withSnackbarsFeature } from '../../screenWrappers/Features'
import DatalabFacade from '../../dataService/DatalabFacade'

// Screen requires the following data providers and features injected
const wrap = flow([
  withLoadingFeature,
  withConfirmFeature,
  withSnackbarsFeature,
  withAllVmOptionsConfig,
  withAllOrganisations,
  withAllTags,
  withAllUsers,
  withProject,
  asBaseScreen,
])

const propTypes = {
  datalabFacade: PropTypes.instanceOf(DatalabFacade).isRequired,
  vmOptionsConfig: PropTypes.string.isRequired,
  organisations: PropTypes.string.isRequired,
  tags: PropTypes.string.isRequired,
  project: PropTypes.string.isRequired,
  user: PropTypes.string.isRequired,
  users: PropTypes.string.isRequired,
  askForConfirmationListener: PropTypes.string.isRequired,
  showSnackbarSuccess: PropTypes.string.isRequired,
}

/**
 * Using Formik to handle the form data movement
 * Using Yup to handle the form validation (works well with Formik)
 * @see https://hackernoon.com/react-form-validation-with-formik-and-yup-8b76bda62e10
 *
 * Handles both new and updating scenarios, updating boolean prop passed to component
 */
export class NewProjectScreen extends React.Component {
  constructor(props) {
    const { datalabFacade } = props
    super(props)
    this.datalabFacade = datalabFacade
    this.state = {
      loading: true,
      vmSizeOptions: undefined,
    }
    this.formRef = React.createRef()
  }

  async componentDidUpdate() {
    const {
      vmOptionsConfig,
      organisations,
      users,
      project,
      tags,
    } = this.props

    const { loading } = this.state

    if (
      loading
      && vmOptionsConfig
      && organisations
      && tags
      && project
      && users
    ) {
      const selectableOrganisations = organisations.map((o) => o.organisationName)
      const vmSizeOptions = vmOptionsConfig.filter((x) => x.configType === 'vmSize')
      const vmTypeOptions = vmOptionsConfig.filter((x) => x.configType === 'vmType')

      // if no vmSizeOptions, throw an error (lets not register any projects if theres no vm options...)
      if (vmSizeOptions.length === 0) {
        throw new UserFixableError(
          'Apologies. There are no VM size options available and so Projects cannot be created/updated at this time.'
        )
      }
      if (vmTypeOptions.length === 0) {
        throw new UserFixableError('There a no Virtual Machine type options available at the moment')
      }
      const defaultVmSize = vmSizeOptions.find((vmSizeOption) => vmSizeOption.type === 'small')

      const defaultEndDate = new Date()
      defaultEndDate.setMonth(defaultEndDate.getMonth() + 2)

      const selectableUsers = users.filter((user) => user.status === 'ACTIVE')

      const initialBasicsValues = {
        uuid: '',
        projectName: '',
        projectStorageSize: 1,
        projectStorageSizeDatalake: 1,
        description: '',
        projectContact: '',
        contactEmail: '',
        contactPhone: '',
        endDate: defaultEndDate,
        defaultVMsize: vmSizeOptions[0].type,
        organisation: '',
        tags: [],
      }

      // set the initial values to the existing project
      Object.keys(initialBasicsValues).forEach((k) => { initialBasicsValues[k] = project[k] })
      if (!defaultVmSize) {
        throw new UserFixableError(
          'The default Virtual Machine size for this Project is no longer available, please update in Project details'
        )
      }

      this.setState({
        vmSizeOptions,
        selectableOrganisations,
        loading: false,
        basicsValues: initialBasicsValues,
        selectableUsers,
      })
    }
  }

  handleSubmit = (values) => {
    const { project, askForConfirmationListener, showSnackbarSuccess } = this.props

    askForConfirmationListener(
      'Are you sure you want to update this Project?',
      async () => {
        await this.datalabFacade.updateProject(values)
        showSnackbarSuccess(`Update Project (${project.uuid}) succeeded`)
      },
      { redirect: `/projects/${project.uuid}` }
    )
  }

  handleSubmitBasicsForm = () => {
    if (this.formRef.current) {
      this.formRef.current.handleSubmit()
    }
  }

  render() {
    const {
      tags,
      datalabFacade,
      user,
    } = this.props
    const {
      loading,
      vmSizeOptions,
      basicsValues,
      selectableUsers,
      selectableOrganisations,
    } = this.state
    return (
      loading
        ? <SkeletonForm />
        : (
          <LocalizationProvider dateAdapter={AdapterDateFns}>
            <div className="details-grid">
              <ProjectDetailsForm
                updating
                vmSizeOptions={vmSizeOptions}
                initialValues={basicsValues}
                selectableUsers={selectableUsers}
                selectableOrganisations={selectableOrganisations}
                selectableTags={tags}
                formRef={this.formRef}
                // Use useCallback when refactoring to functional component
                // eslint-disable-next-line react/jsx-no-bind
                handleSubmit={this.handleSubmit.bind(this)}
                datalabFacade={datalabFacade}
                podId={user.pod}
              />
            </div>
            <Divider variant="middle" sx={{ borderColor: 'rgba(0, 0, 0, 1)' }} />
            <Container maxWidth="md">
              <Box sx={{ width: '100%' }}>
                <Box sx={{ display: 'flex', flexDirection: 'row' }}>
                  <Box sx={{ flex: '1 1 auto' }} />
                  <Button
                    sx={{ margin: '20px' }}
                    variant="outlined"
                    onClick={() => {
                      this.handleSubmitBasicsForm()
                    }}
                  >
                    Save
                  </Button>
                </Box>
              </Box>
            </Container>
          </LocalizationProvider>
        )
    )
  }
}

NewProjectScreen.propTypes = propTypes

export default wrap(NewProjectScreen)

