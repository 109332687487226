import React from 'react'
import { styled } from '@mui/material/styles'
import {
  Table,
  Box,
  TableBody,
  TableCell,
  TableHead,
  TableRow,
  Typography,
  Button,
  Autocomplete,
  Drawer,
  Tooltip,
  TextField,
  Paper,
  Stack,
  TableContainer,
} from '@mui/material'
import { PropTypes } from 'prop-types'
import { useFeatures } from '../hooks/useFeatures'
import { useSnackbars } from '../hooks/useSnackbars'
import DatalabFacade from '../dataService/DatalabFacade'
import {
  vmTypeOptionsShape, vmSizeOptionsShape, vmVersionOptionsShape, appUserShape,
} from '../propTypeShapes'
import { tableHeadersProps } from './paginatedDataTable/propTypes'
import Row from './paginatedDataTable/Row'

const TableCellHeader = styled(TableCell)({
  fontWeight: 'bold',
  resize: 'horizontal',
  minWidth: '1px',
  maxWidth: '400px',
  overflow: 'hidden',
  whiteSpace: 'nowrap',
  padding: '2px 0px 2px 10px',
  textOverflow: 'ellipsis',
  position: 'sticky',
  top: 0,
  backgroundColor: '#fafafa',
  borderTop: 'none !important',
  borderBottom: 'none !important',
  boxShadow: 'inset 0 -2px 0 #dddddd',
  zIndex: 2,
})

const propTypes = {
  rowKey: PropTypes.string.isRequired,
  rowsPerPage: PropTypes.number,
  // eslint-disable-next-line react/forbid-prop-types
  tableData: PropTypes.arrayOf(PropTypes.object).isRequired,
  datalabFacade: PropTypes.instanceOf(DatalabFacade).isRequired,
  isUpdateVmSize: PropTypes.bool,
  openDrawer: PropTypes.bool.isRequired,
  handleDrawerClose: PropTypes.func.isRequired,
  tableHeaders: tableHeadersProps.isRequired,
  vmTypeOptions: PropTypes.arrayOf(vmTypeOptionsShape).isRequired,
  vmSizeOptions: PropTypes.arrayOf(vmSizeOptionsShape).isRequired,
  vmVersionOptions: PropTypes.arrayOf(vmVersionOptionsShape).isRequired,
  user: appUserShape.isRequired,
}

const defaultProps = {
  rowsPerPage: 25,
  isUpdateVmSize: false,
}

/**
 * Function that displays VM information for those VMs that are about to be bulk updated.
 * @param {object} props
 * @returns
 */
function DrawerDataTable(props) {
  const {
    rowKey,
    rowsPerPage,
    tableData,
    datalabFacade,
    isUpdateVmSize,
    openDrawer,
    handleDrawerClose,
    tableHeaders,
    vmSizeOptions,
    vmTypeOptions,
    vmVersionOptions,
    user,
  } = props

  const [selectedVmSize, setSelectedVmSize] = React.useState(vmSizeOptions[0])
  const [selectedVmType, setSelectedVmType] = React.useState(vmTypeOptions[0])
  const [selectedVmVersion, setSelectedVmVersion] = React.useState(vmVersionOptions.find((e) => e.latest))

  const { setConfirm } = useFeatures()
  const { showSnackbarInProgress } = useSnackbars()

  const emptyDrawerTableRows = rowsPerPage - tableData.length

  const emptyDrawerRowElements = () => [...Array(emptyDrawerTableRows)].map((n, index) => (
    // eslint-disable-next-line react/no-array-index-key
    <TableRow key={`emptyRow-${index}`} className="empty-table-row">
      {/* eslint-disable-next-line react/no-array-index-key */}
      <TableCell style={{ border: 'none' }} key={`emptyCell-${index}`} colSpan={tableHeaders.length} />
    </TableRow>
  ))

  function handleDrawerUpdate() {
    const vmNameList = tableData.map((vm) => vm.VMname)
    const maxDisplaySize = 3
    const maxDisplayItems = vmNameList.length > 3 ? vmNameList.slice(0, maxDisplaySize) : vmNameList

    const displayMessage = vmNameList.length > maxDisplaySize
      ? `Update ${isUpdateVmSize ? 'VM Size' : 'VM Type/Version'} for the following VMs: \n\n[ ${maxDisplayItems.join(', ')} ] and ${vmNameList.length - maxDisplaySize} other(s)?` : `Update ${isUpdateVmSize ? 'VM Size' : 'VM Type/Version'} for the following VMs? \n\n[ ${maxDisplayItems.join(', ')} ]`

    setConfirm({
      message: displayMessage,
      callback: async () => {
        // Close the drawer immediately upon submission, so users do not click the button multiple times
        handleDrawerClose()
        if (isUpdateVmSize) {
          showSnackbarInProgress(
            `Update multiple VM's size to ${selectedVmSize.type} in progress...`,
            false
          )
          await Promise.all(vmNameList.map(
            async (vmName) => datalabFacade.resizeVm(
              `projectrg-${vmName.split('-')[0]}`,
              vmName,
              selectedVmSize.type
            )
          ))
        } else {
          showSnackbarInProgress(
            `Update multiple VM's type/version to ${selectedVmType.type} (${selectedVmVersion.version}) in progress...`,
            false
          )
          await Promise.all(vmNameList.map(
            async (vmName) => datalabFacade.changeVmType(
              vmName,
              selectedVmType.type,
              selectedVmVersion.version,
              user.pod
            )
          ))
        }
      },
    })
  }

  return (

    <Drawer
      anchor="right" // Determines where on the screen the drawer will appear
      open={openDrawer}
      onClose={() => {
        handleDrawerClose()
      }}
    >
      <Box
        sx={{ pl: 2, pr: 2, width: 'fit-content' }}
        role="presentation"
        overflow="hidden"
      >
        {isUpdateVmSize
          ? (
            <Typography variant="h6" component="h3">
              Update VM Size for the following VMs:
            </Typography>
          )
          : (
            <Typography variant="h6" component="h3">
              Update VM Type/Version for the following VMs:
            </Typography>
          )}
        <Stack direction="row" spacing={2} justifyContent="flex-start" sx={{ pt: 2, pb: 2 }}>
          {isUpdateVmSize
                        && (
                        <Autocomplete
                          id="drawer-autocomplete-vmSizeOptions"
                          value={selectedVmSize}
                          options={vmSizeOptions.filter((vmSize) => vmSize.compatibilitySet === 1)}
                          getOptionLabel={(option) => option.displayName}
                          style={{ width: 300 }}
                          onChange={(event, value) => {
                            setSelectedVmSize(value)
                          }}
                          renderInput={(params) => (
                            // eslint-disable-next-line react/jsx-props-no-spreading
                            <TextField {...params} label="Size" variant="outlined" fullWidth />
                          )}
                        />
                        )}
          {!isUpdateVmSize
                        && (
                        <>
                          <Autocomplete
                            id="drawer-autocomplete-vmTypeOptions"
                            options={vmTypeOptions}
                            value={selectedVmType}
                            getOptionLabel={(option) => option.displayName}
                            style={{ width: 300 }}
                            onChange={(event, value) => {
                              setSelectedVmType(value)
                            }}
                            renderInput={(params) => (
                              // eslint-disable-next-line react/jsx-props-no-spreading
                              <TextField {...params} label="Type" variant="outlined" fullWidth />
                            )}
                          />
                          <Autocomplete
                            id="drawer-autocomplete-vmSizeVersions"
                            options={vmVersionOptions}
                            value={selectedVmVersion}
                            getOptionLabel={
                              (option) => `${option.version}${option.latest ? ' (latest)' : ''}`
                            }
                            style={{ width: 300 }}
                            onChange={(event, value) => {
                              setSelectedVmVersion(value)
                            }}
                            renderInput={(params) => (
                              // eslint-disable-next-line react/jsx-props-no-spreading
                              <TextField {...params} label="Version" variant="outlined" fullWidth />
                            )}
                          />
                        </>
                        )}
        </Stack>
        <Paper elevation={3} sx={{ height: '80%', pl: 2, pr: 2 }}>
          <TableContainer>
            <Table
              aria-labelledby="tableTitle"
              size="small"
            >
              <TableHead>
                <TableRow>
                  {tableHeaders.map((header) => (

                    <TableCellHeader
                      key={header.id}
                      align={header.numeric ? 'right' : 'left'}
                      width="auto"
                    >
                      <div>{header.label}</div>
                    </TableCellHeader>
                  ))}
                </TableRow>
              </TableHead>
              <TableBody display="block" overflow="auto">
                {/* //Fill in row data */}
                {tableData
                  .map((row, index) => (
                    <Row
                      row={row}
                      rowKey={rowKey}
                      key={row[rowKey]}
                      index={index}
                      tableHeaders={tableHeaders}
                    />
                  ))}
                {emptyDrawerTableRows > 0 && (
                  emptyDrawerRowElements().map((element) => element)
                )}
              </TableBody>
            </Table>
          </TableContainer>
        </Paper>
        <Stack direction="row" justifyContent="flex-end" sx={{ pl: 2 }}>
          <Tooltip title="Cancel">
            <Button
              aria-label="drawer cancel"
              variant="text"
              color="primary"
              size="small"
              onClick={() => handleDrawerClose()}
              sx={{ display: 'inline-block', height: '60px' }}
            >
              Cancel
            </Button>
          </Tooltip>
          <Tooltip title="Bulk Update">
            <span>
              <Button
                aria-label="drawer bulk update"
                variant="text"
                color="primary"
                sx={{ display: 'inline-block', height: '60px' }}
                size="small"
                onClick={() => handleDrawerUpdate()}
              >
                Update
              </Button>
            </span>
          </Tooltip>
        </Stack>
      </Box>
    </Drawer>

  )
}

DrawerDataTable.propTypes = propTypes
DrawerDataTable.defaultProps = defaultProps

export default DrawerDataTable
