"use strict";
Object.defineProperty(exports, "__esModule", { value: true });
exports.projectProductLinksDtoSchema = void 0;
const zod_1 = require("zod");
/** Generated database fields that can be queried via the api */
const projectProductLinksGeneratedFields = zod_1.z.object({
    productName: zod_1.z.string(),
    projectId: zod_1.z.string(),
    _id: zod_1.z.string(),
});
/** All database fields that are exposed via API */
const projectProductLinksDtoSchema = projectProductLinksGeneratedFields;
exports.projectProductLinksDtoSchema = projectProductLinksDtoSchema;
