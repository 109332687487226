import React from 'react'
import flow from 'lodash.flow'
import { Tooltip, Button } from '@mui/material'
import Skeleton from '@mui/material/Skeleton'
import { useNavigate } from 'react-router-dom'
import PropTypes from 'prop-types'
import asBaseScreen from '../../screenWrappers/BaseScreen'
import { withSnackbarsFeature, withConfirmFeature } from '../../screenWrappers/Features'
import PaginatedDataTable from '../../components/PaginatedDataTable'
import { Role } from '../../enums/Role'
import { withAllTags } from '../../screenWrappers/DataProviders'
import { withRouter } from '../../screenWrappers/withRouter'
import { useSnackbars } from '../../hooks/useSnackbars'
import { useFeatures } from '../../hooks/useFeatures'
import { appUserShape, tagShape } from '../../propTypeShapes'
import DatalabFacade from '../../dataService/DatalabFacade'
import RequiredRoleBoundary from '../../components/RequiredRoleBoundary'

// Screen requires the following data providers and features injected
const wrap = flow([
  withRouter,
  withConfirmFeature,
  withSnackbarsFeature,
  asBaseScreen,
  withAllTags,
])

const propTypes = {
  user: appUserShape.isRequired,
  datalabFacade: PropTypes.instanceOf(DatalabFacade).isRequired,
  tags: PropTypes.arrayOf(tagShape),
}

const defaultProps = {
  tags: null,
}

/**
 * Displays a list of registered orgaisations
 */
function OrganisationsScreen({ user, datalabFacade, tags }) {
  const [loading, setLoading] = React.useState(true)
  const navigate = useNavigate()
  const { showSnackbarSuccess } = useSnackbars()
  const { setConfirm } = useFeatures()

  React.useEffect(() => {
    if (tags) {
      setLoading(false)
    }
  }, [tags])

  const handleNewBtnClick = () => {
    navigate('newOrganisation')
  }

  const handleEditBtnClick = (organisationId) => {
    navigate(`${organisationId}/updateOrganisation`)
  }

  const handleDeleteOrganisation = (organisationId) => {
    setConfirm({
      message:
      'Are you sure you want to delete this organisation?',
      callback: async () => {
        await datalabFacade.deleteOrganisation(organisationId)
        showSnackbarSuccess('Delete Organisation succeeded')
        navigate('/organisations')
      },
    })
  }

  return (
    loading
      ? (
        <>

          {[...Array(15)].map((n, index) => (
            <Skeleton
              // eslint-disable-next-line react/no-array-index-key
              key={index}
              height={50}
              style={{ margin: '20px' }}
            />
          ))}
        </>
      )

      : (
        <RequiredRoleBoundary
          allowedRoles={[Role.ADMIN, Role.USER_ADMIN]}
          functionProps={[
            'onClick',
            'deletable',
            'updatable',
          ]}
        >
          <div className="action-button-container">
            <Tooltip title="Create a new Organisation">
              <Button
                variant="contained"
                color="primary"
                onClick={handleNewBtnClick}
              >
                New Organisation&nbsp;&nbsp;
                <i className="fas fa-plus-circle" />
              </Button>
            </Tooltip>
          </div>

          <PaginatedDataTable
            tableCaption="List of organisations"
            deletable={handleDeleteOrganisation}
            updatable={handleEditBtnClick}
            datalabFacade={datalabFacade}
            podId={user.pod}
            collectionName="organisations"
            headers={[
              { id: 'organisationId', type: 'string', label: 'ID' },
              { id: 'organisationName', type: 'string', label: 'Organisation Name' },
              { id: 'ABN', type: 'string', label: 'ABN' },
              { id: 'tags', type: 'tag-array', label: 'Tags' },
            ]}
            defaultSort={{ property: 'organisationName', direction: 'asc' }}
            rowKey="organisationId"
            csvExportFileName="datalab_organisations"
            tags={tags}
          />
        </RequiredRoleBoundary>
      )
  )
}

OrganisationsScreen.propTypes = propTypes
OrganisationsScreen.defaultProps = defaultProps

export default wrap(OrganisationsScreen)
