import React from 'react'
import LinkProductsProjectsScreen from './LinkProductsProjectsScreen'
import type DatalabFacade from '../../dataService/DatalabFacade'
import type { SeadAccountInfo } from '../../util/AuthenticationHandler'
import type { CommonProps } from '../../../types/CommonProps'

const submitApi = async (
  datalabFacade: DatalabFacade,
  productName: string,
  podId: string,
  { addedLinks, removedLinks }: {addedLinks: string[], removedLinks: string[]}
) => datalabFacade.updateProductLinks({
  podId,
  productName,
  projectIds: {
    add: addedLinks,
    remove: removedLinks,
  },
})

const loadData = async (
  datalabFacade: DatalabFacade,
  user: SeadAccountInfo,
  targetId: string
): Promise<{ existingLinks: string[], counterparts: string[]}> => {
  const [projectLinks, projects] = await Promise.all([
    datalabFacade.list({
      entity: 'projectProductLinks',
      requestBody: {
        podId: user.pod,
        fields: ['projectId'],
        filters: [{
          property: 'productName', condition: '$eq', value: targetId,
        }],
      },
    }),
    datalabFacade.list({
      entity: 'projects',
      requestBody: {
        podId: user.pod,
        fields: ['uuid'],
        filters: [{ property: 'status', condition: '$eq', value: 'OPEN' }],
      },
    }),
  ])

  return {
    existingLinks: projectLinks.data.map((existingLink) => existingLink.projectId),
    counterparts: projects.data.map((counterpart) => counterpart.uuid),
  }
}

/**
 * Allows selecting projects to be linked with a product
 * @param props - Component props
 * @returns a LinkProductsScreen component
 */
export default function LinkProductsScreen(props: Readonly<CommonProps>) {
  return (
    <LinkProductsProjectsScreen
      target="product"
      targetPrimaryKey="name"
      counterpartCollectionName="projects"
      selectedLinksMessage="Analysts in projects listed here will be granted read-only access to this products data"
      submitApi={submitApi}
      loadData={loadData}
      {...props}
    />
  )
}

