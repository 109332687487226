import React from 'react';
import flow from 'lodash.flow';
import {
    Button,
    Typography,
    TextField,
    Container
} from "@mui/material";
import Autocomplete from '@mui/material/Autocomplete';
import asBaseScreen from '../../screenWrappers/BaseScreen';
import { withUser } from '../../screenWrappers/DataProviders';
import { withSnackbarsFeature, withConfirmFeature } from '../../screenWrappers/Features';
import SkeletonForm from '../../components/SkeletonForm';

//Screen requires the following data providers and features injected
const wrap = flow([
    withUser,
    withConfirmFeature,
    withSnackbarsFeature,
    asBaseScreen,
])

/**
* Page allows removing a user from a DataLab Project
*/
class ChangeActiveVmScreen extends React.Component {
    constructor(props) {
        super(props);
        this.datalabFacade = props.datalabFacade;
        this.state = {
            loading: true,
            vms: undefined,
            projects: [],
        };
    }

    async componentDidUpdate(prevProps) {
        if (this.state.loading && this.props.userData) {
            let projects = await this.datalabFacade.getUserProjects(this.props.userData.userName);

            this.setState({
                loading: false,
                vms: this.props.userData.VMs,
                projects: projects
            })
        }
    }

    getProjectNameByID(projectID) {
        let project = this.state.projects.find(project => project.uuid === projectID);

        if (project) {
            return project.projectName;
        } else {
            return "N/A";
        }
    };

    handleVmChange(selectedOption) {
        this.setState({ selectedVm: selectedOption });
    };

    handleSubmit() {
        this.props.askForConfirmationListener(
            'Are you sure you want to activate this VM for this user? The user will be disconnected from all existing sessions',
            async () => {
                await this.datalabFacade.activateVm(this.state.selectedVm.VM, this.props.user.pod);
                this.props.showSnackbarInProgress(`Activate Virtual Machine (${this.state.selectedVm.VM}) in progress...`);
            },
            {
                redirect: `/users/${this.props.userData.userName}`
            });
    }

    render() {
        return (
            this.state.loading ? <SkeletonForm /> :
            <Container maxWidth="sm">
                <Typography variant="body1" component="h2">
                    Select a VM to activate for {this.props.userData.userName}
                </Typography>
                <Autocomplete
                    id="autocomplete-vms"
                    options={this.state.vms}
                    getOptionLabel={option => `${option.VM} (Project name: ${this.getProjectNameByID(option.project)})`}
                    style={{ width: 600 }}
                    onChange={(event, values) => this.setState({ selectedVm: values })}
                    renderInput={params => (
                        <TextField {...params} label="Virtual Machine" variant="outlined" fullWidth />
                    )}
                />
                <div className="action-button-container">
                    <Button variant="contained" color='primary' disabled={!this.state.selectedVm} onClick={this.handleSubmit.bind(this)}>
                        Activate VM
                    </Button>
                </div>
            </Container>
        );
    }

}

export default wrap(ChangeActiveVmScreen)
