import React from 'react';
import Skeleton from '@mui/material/Skeleton';

export default function SkeletonForm(props) {
  return (
    <React.Fragment>
      <Skeleton height={55} width={150} style={{ display: 'inline-block', margin: '0px 5px 0px 5px' }} />
      <Skeleton height={55} width={150} style={{ display: 'inline-block', margin: '0px 5px 0px 5px' }} />
      <Skeleton height={55} width={150} style={{ display: 'inline-block', margin: '0px 5px 0px 5px' }} />
    </React.Fragment>
  );
}