class DomainError extends Error {
    constructor(message) {
      super(message);
      this.name = this.constructor.name;
      Error.captureStackTrace(this, this.constructor);
    }
  }
  
  //Error that a user can possibly fix themselves
  //Rather than showing a default "error occured" the ErrorBoundary will show this message to the user
  //if it detects this error type
  export class UserFixableError extends DomainError {}
  
  // Wrap errors from other frameworks.
  export class InternalError extends DomainError {
    constructor(error) {
      super(error.message);
      this.data = { error };
    }
  }
  