
import React from 'react'
import flow from 'lodash.flow'
import {
  Button,
  Typography,
  TextField,
  Container,
} from '@mui/material'
import Autocomplete from '@mui/material/Autocomplete'
import PropTypes from 'prop-types'
import asBaseScreen from '../../screenWrappers/BaseScreen'
import { withProject } from '../../screenWrappers/DataProviders'
import { withSnackbarsFeature, withConfirmFeature } from '../../screenWrappers/Features'
import SkeletonForm from '../../components/SkeletonForm'
import { useSnackbars } from '../../hooks/useSnackbars'
import { useFeatures } from '../../hooks/useFeatures'
import {
  appUserShape, projectShape,
} from '../../propTypeShapes'
import DatalabFacade from '../../dataService/DatalabFacade'

// Screen requires the following data providers and features injected
const wrap = flow([
  withProject,
  withConfirmFeature,
  withSnackbarsFeature,
  asBaseScreen,
])

const propTypes = {
  user: appUserShape.isRequired,
  datalabFacade: PropTypes.instanceOf(DatalabFacade).isRequired,
  project: projectShape,
}

const defaultProps = {
  project: null,
}

/**
 * Page allows removing users from a Datalab Project
 */
function RemoveUserScreen({
  user, datalabFacade, project,
}) {
  const [loading, setLoading] = React.useState(true)
  const [selectableUsers, setSelectableUsers] = React.useState([])
  const [selectedUsers, setSelectedUsers] = React.useState([])

  const { showSnackbarInProgress } = useSnackbars()
  const { setConfirm } = useFeatures()

  React.useEffect(() => {
    if (loading && project) {
      setLoading(false)
      setSelectableUsers(project.users)
    }
  }, [project])

  const handleSubmit = () => {
    if (selectedUsers.length === 1) {
      setConfirm({
        message:
        'Are you sure you want to remove this user?',
        callback: async () => {
          await datalabFacade.unassignUserFromProject(selectedUsers[0], project.uuid, user.pod)
          showSnackbarInProgress(
            `Remove User(${selectedUsers[0]} -> ${project.uuid}) in progress...`
          )
        },
        additionalOptions: {
          redirect: `/projects/${project.uuid}`,
        },
      })
    } else {
      setConfirm({
        message:
        'Are you sure you want to remove these users?',
        callback: async () => {
          await datalabFacade.unassignUsersFromProject(selectedUsers, project.uuid, user.pod)
          showSnackbarInProgress(
            `Remove Users (${selectedUsers.length} Users -> ${project.uuid}) in progress...`
          )
        },
        additionalOptions: {
          redirect: `/projects/${project.uuid}`,
        },
      })
    }
  }

  return (
    loading ? <SkeletonForm />
      : (
        <div className="details-grid">
          <Container maxWidth="sm">
            <Typography variant="body1" component="h2">
              Select the users to remove from
              {' '}
              {project.projectName}
              {' '}
              [
              {project.uuid}
              ]
            </Typography>
            <Autocomplete
              id="autocomplete-users"
              options={selectableUsers}
              getOptionLabel={(option) => option}
              variant="outlined"
              multiple
              filterSelectedOptions
              onChange={(event, values) => setSelectedUsers(values)}
              renderInput={(params) => (
                // eslint-disable-next-line react/jsx-props-no-spreading
                <TextField {...params} label="Users" variant="outlined" fullWidth />
              )}
            />

            <div className="action-button-container">

              <Button
                type="submit"
                variant="contained"
                color="primary"
                disabled={selectedUsers.length < 1}
                onClick={handleSubmit}
              >
                Remove Users
              </Button>
            </div>
          </Container>
        </div>
      )
  )
}

RemoveUserScreen.propTypes = propTypes
RemoveUserScreen.defaultProps = defaultProps

export default wrap(RemoveUserScreen)
