import React from 'react';
import lodash from 'lodash';
import flow from 'lodash.flow';
import asBaseScreen from '../../screenWrappers/BaseScreen';
import { Formik } from 'formik';
import * as Yup from 'yup';
import { withAllTags } from '../../screenWrappers/DataProviders';
import { withConfirmFeature, withSnackbarsFeature } from '../../screenWrappers/Features';
import SkeletonForm from '../../components/SkeletonForm';
import TagInputField from '../../components/TagInputField';
import { 
    Container,
    FormHelperText,
    Grid,
    InputLabel,
    TextField,
    Button
} from '@mui/material';

const wrap = flow([
    withAllTags,
    withConfirmFeature,
    withSnackbarsFeature,
    asBaseScreen
])

export class NewTagScreen extends React.Component {
    constructor(props) {
        super(props);
        this.datalabFacade = props.datalabFacade
        this.state = {
            loading: true,
            existingTagKeys: undefined,
            initialTags: undefined,
            activeTagCount: 0,
        };
    };

    componentDidUpdate(prevProps) {
        if (this.state.loading && this.props.tags && (this.props.updating ? this.props.tag : true)) {
            const existingTagKeys = this.props.tags.map(t => {return t.tagKey});
            this.setState({
                loading: false,
                existingTagKeys: existingTagKeys,
                initialTags: this.props.updating ? this.props.tag.tagValues : [],
                activeTagCount: this.props.updating ? this.props.tag.tagValues.length : 0,
            })
        }
    }

    handleSubmit = (values) => {
        this.props.updating ? 
            this.props.askForConfirmationListener(`Are you sure you want to update tag values for tag ${values.tagKey}?`, async () => {
                values.podId = this.props.user.pod;
                this.props.showSnackbarInProgress(`Update tag ${values.tagKey} in progress...`)
                    await this.props.datalabFacade.updateTag(values);
                    this.props.showSnackbarSuccess(`Update tag ${values.tagKey} succeeded`);
                },
                {redirect: `/tags`}
            )
        :
            this.props.askForConfirmationListener(`Are you sure you want to create tag ${values.tagKey}?`, async () => {
                    values.podId = this.props.user.pod;
                    this.props.showSnackbarInProgress(`Create tag ${values.tagKey} in progress...`)
                    await this.props.datalabFacade.createTag(values);
                    this.props.showSnackbarSuccess(`Create tag ${values.tagKey} succeeded`);
                },
                {redirect: `/tags`}
            )
    }

    render() {
        return (
            this.state.loading ? 
                <SkeletonForm /> 
            :
                <div className="details-grid">
                    <Container maxWidth="md">
                        <Formik
                            validateOnChange={true}
                            validateOnBlur={true}
                            initialValues={{
                                tagKey: this.props.updating ? this.props.tag.tagKey : "",
                                tagValues: this.props.updating ? this.props.tag.tagValues : []
                            }}
                            onSubmit={(values => {
                                this.handleSubmit(values);
                            })}
                            validationSchema={Yup.object()
                                .shape({
                                    tagKey: this.props.updating ? 
                                        Yup.string()
                                        : 
                                        Yup.string().notOneOf(this.state.existingTagKeys),
                                    tagValues: Yup.array().of(Yup.string())
                                })
                            }
                        >
                            {(props) => {
                                const {
                                    values,
                                    touched,
                                    errors,
                                    handleChange,
                                    handleBlur,
                                    handleSubmit
                                } = props;

                                return (
                                    <form onSubmit={handleSubmit}>
                                        <div>
                                            <p className="mandatory-info">{this.props.updating ? 'At least one value per tag is required.' : 'Tag name and at least one value per tag is required.'}</p>
                                            <Grid container spacing={2} alignItems="center" direction="column">
                                                <Grid container className="formRow" spacing={1} alignItems="start" justify="center" direction="row">
                                                    <Grid item xs={2}>
                                                        <InputLabel htmlFor="tagKey">Tag Name</InputLabel>
                                                    </Grid>
                                                    <Grid item xs>
                                                        <TextField id="tagKey"
                                                            variant="outlined"
                                                            fullWidth
                                                            aria-describedby="helperTagName"
                                                            onChange={handleChange}
                                                            onBlur={handleBlur}
                                                            value={values.tagKey}
                                                            placeholder="Tag Name"
                                                            disabled={this.props.updating}
                                                        />
                                                        <FormHelperText id="helperTagName" error={errors.tagKey && touched.tagKey}>
                                                            {(errors.tagKey && touched.tagKey) ? errors.tagKey : "The name of the tag."}
                                                        </FormHelperText>
                                                    </Grid>
                                                </Grid>
                                                <Grid container className="formRow" spacing={1} alignItems="start" justify="center" direction="row">
                                                    <Grid item xs={2}>
                                                        <InputLabel htmlFor="tagValues">Tag Values</InputLabel>
                                                    </Grid>
                                                    <Grid item xs={10}>
                                                        <TagInputField 
                                                            selectedTags={tags => {
                                                                values.tagValues = tags;

                                                                if (this.state.activeTagCount !== values.tagValues.length) {
                                                                    this.setState( { activeTagCount: values.tagValues.length } );
                                                                }
                                                            }}
                                                            initialTags={this.state.initialTags ? this.state.initialTags : values.tagValues}
                                                            tags={this.state.initialTags ? this.state.initialTags : values.tagValues}
                                                            variant="outlined"
                                                            id="tagValues"
                                                            name="tagValues"
                                                            placeholder="Enter tag value. Click 'Add' to add the value to the group"
                                                            fullWidth
                                                        />
                                                        <FormHelperText id="helperTagValues" error={errors.tagValues && touched.tagValues}>
                                                        {(errors.tagValues && touched.tagValues) ? errors.tagValues : "Values for the tag."}
                                                        </FormHelperText>
                                                    </Grid>
                                                </Grid>
                                            </Grid>
                                            
                                            <div className="action-button-container">
                                                <Button type="submit" variant="contained" color='primary' disabled={values.tagKey === "" || this.state.activeTagCount < 1 || lodash.isEqual(this.state.initialTags, values.tagValues) || values.tagKey.length < 2 || values.tagKey.length > 32}>
                                                    Submit
                                                </Button>
                                            </div>
                                        </div>
                                    </form>
                                )
                            }}
                        </Formik>
                    </Container>
                </div>
        )
    }
}

export default wrap(NewTagScreen);